import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    width: "320px",
    height: "85px",

    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "30px",
    [theme.breakpoints.only("md")]: {
      justifyContent: "space-around",
      width: "250px",
    },
    // [theme.breakpoints.only("sm")]: {
    //   width: "450px",
    //   justifyContent: "space-between",
    // },
  },
  title: {
    fontFamily: "Poppins !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#3A3A3A !important",
    [theme.breakpoints.only("md")]: {
      fontSize: "50px !important",
      marginRight: "10px !important",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "46px !important",
      marginRight: "5px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "46px !important",
      marginRight: "20px !important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "50px !important",
    },
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "600 !important",
    color: "#3A3A3A !important",
    inlineSize: "160px",
    // [theme.breakpoints.only("md")]: {
    //   inlineSize: "none",
    //   width: "auto",
    //   fontSize: "14px !important",
    // },
    [theme.breakpoints.only("sm")]: {
      fontSize: "18px !important",
      // inlineSize: "500px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "18px !important",
      // inlineSize: "500px",
    },
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
}));

const GrowthCompo = ({ title, desc, sign }) => {
  const [counterOn, setCounterOn] = useState(false);
  const classes = useStyles();
  return (
    <>
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <div className={classes.main}>
          <div className={classes.left}>
            {counterOn && (
              <CountUp
                className={classes.title}
                start={0}
                end={parseInt(title)}
                duration={2}
                delay={0}
              />
            )}
            <Typography className={classes.title}>{sign}</Typography>
          </div>
          <Typography className={classes.desc}>{desc}</Typography>
        </div>
      </ScrollTrigger>
    </>
  );
};

export default GrowthCompo;
