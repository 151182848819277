import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Left from "./Left";
import image1 from "../../../images/problemsolvingbro.svg";
import image2 from "../../../images/Dataanalysis2.svg";
import image3 from "../../../images/Problemsolvingamico.svg";
import image4 from "../../../images/Leader-bro 1.svg";
import icon from "../../../images/check_circle.svg";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Poppins !important",
    fontSize: "48px !important",
    fontWeight: "700 !important",
    color: "#3A3A3A !important",
    textAlign: "center !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "44px !important",
    },
  },
}));
const Challenge = ({ maintitle, path }) => {
  const classes = useStyles();
  const textAnimate = {
    offscreen: { scale: 0, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  return (
    <>
      {/* <div
        class="clutch-widget"
        data-url="https://widget.clutch.co"
        data-widget-type="14"
        data-height="50"
        data-nofollow="true"
        data-expandifr="true"
        data-scale="100"
        data-clutchcompany-id="1902127"
      ></div> */}
      <div style={{ marginTop: "50px" }}>
        <Typography className={classes.title}>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
          >
            <motion.p variants={textAnimate}>{maintitle}</motion.p>
          </motion.div>
        </Typography>
        {path === "hopkins" ? (
          <Left
            image={image1}
            heading="There digital marketing was not going well. After investing more than $5K a month for a while on Facebook and Google ads were not driving them conversions of prospective people that can have IRS issue!"
            item1="Google Ads keywords were not targeted properly with broad search terms and keywords"
            icon={icon}
            item2="Poor bid optimization"
            icon2={icon}
            item3="Website was not updated and had poor support"
            icon3={icon}
            item4="Ad were running with no optimization been made to even Google or Facebook ads"
            icon4={icon}
          />
        ) : (
          ""
        )}
        {path === "swat" ? (
          <Left
            image={image2}
            heading="US Tax Resolution saw 20 conversions just in the first month of campaign launch. The campaigns were all locally targeted. Lead flow after 7 months have increased to 30X when launched and all because of right ad placement and digital marketing expert support."
            item1="CPL and CPC dropped by 60%. It was unusual for anyone who knows that how tough it to compete and sustain on Tax and IRS related Keywords and Facebook Ads."
            icon={icon}
            item2="New landing page and implementing CTAs on the heat points of the webpages on the website helped increase in quality conversions."
            icon2={icon}
            item3="Ad copy and images have worked wonders for the new brand in public."
            icon3={icon}
            item4=""
            icon4={""}
          />
        ) : (
          ""
        )}
        {path === "ielts" ? (
          <Left
            image={image3}
            heading="There digital marketing was not going well. After investing more than $5K a month for a while on Facebook and Google ads were not driving them conversions of prospective people that can have IRS issue!"
            item1="Poor Ads image style and vague messaging"
            icon={icon}
            item2="Google Ads keywords were not targeted properly with broad search terms and keywords"
            icon2={icon}
            item3="Poor bid optimization"
            icon3={icon}
            item4="Ad were running with no optimization been made to even Google or Facebook ads"
            icon4={icon}
            item5="Ad were running with no optimization been made to even Google or Facebook ads"
            icon5={icon}
          />
        ) : (
          ""
        )}
        {path === "johnwood" ? (
          <Left
            image={image4}
            item1="Hopkins saw a whopping 30x growth in qualified leads who were making direct calls or coming in through inquiry form from PPC ads."
            icon={icon}
            item2="CPL and CPC dropped by 60%. It was unusual for anyone who knows that how tough it to compete and sustain on Tax and IRS related Keywords and Facebook Ads."
            icon2={icon}
            item3="New landing page and implementing CTAs on the heat points of the webpages on the website helped increase in quality conversions"
            icon3={icon}
            item4="Migrating website on a better server and WP helped gain better website performance"
            icon4={icon}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Challenge;
