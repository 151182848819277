import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import image from "../../images/cardcorner.svg";
import logo from "../../images/clutch.svg";
import profilepic from "../../images/profilepic2.svg";
import vector from "../../images/Vector.svg";
import joehopkins from "../../images/testimonial/joe_hopkins.png"
import jennifer from '../../images/testimonial/jennifer.jfif'
import amit from '../../images/testimonial/amit.png'
import walker from "../../images/testimonial/walker.jfif"
import { transform } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "450px",
    height: "280px",
    borderRadius: "11px !important",
    padding: "0px !important",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.16) !important",
    margin: " 10px",
    [theme.breakpoints.only('xs')]: {
      width: "350px",
      height: "320px",
    }
  },
  cardBodyBlur: {
    width: "450px",
    height: "260px",
    borderRadius: "11px !important",
    padding: "0px !important",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.16) !important",
    margin: "10px",
    filter: "blur(2px)",
    transform: "scale(0.8)",
    [theme.breakpoints.only('xs')]: {
      width: "350px",
    }
  },
  image: {
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "1",

  },
  profilediv: {
    zIndex: "55",
    display: "flex",
    marginTop: "60px",
    marginLeft: "35px",
    [theme.breakpoints.only('xs')]: {
      marginTop: "30px",
    }
  },
  imagemain: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "top",
    width: "100%",
    height: "250px",
    position: "absolute",


  },
  profilepic: {
    marginRight: "10px",
    width: '50px',
    borderRadius: '50%'
  },
  title: {
    fontSize: "18px !important",
    color: "#3a3a3a !important",
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
  },
  title2: {
    fontSize: "14px !important",
    color: "#3a3a3a !important",
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
  },
  typo: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "17px",
  },
  desc: {
    inlineSize: "380px",
    fontSize: "14px !important",
    color: "#767676 !important",
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    padding: "14px !important",
  },
  logo: {
    position: "absolute",
    right: "45px",
    top: "30px",
  },
  quote: {
    position: "absolute",
    left: "0",
    top: "0",
    [theme.breakpoints.only('xs')]: {
      top: "0",
      left:'10px'
    }
  },
}));
const FeedbackCard = ({ blur, title, title1, title2, desc, profilepic }) => {
  const classes = useStyles();
  const openInNewTab = (url) => {
    // 👇️ setting target to _blank with window.open
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <Card className={blur ? classes.cardBodyBlur : classes.cardBody}>
        <CardActionArea>
          <CardContent sx={{ padding: 0 }}>
            <div className={classes.imagemain}>
              <img src={logo} className={classes.logo} onClick={() => openInNewTab("https://clutch.co/profile/adeptd#reviews")} alt="logo" />

              <div className={classes.profilediv}>
                <img
                  src={profilepic}
                  className={classes.profilepic}
                  alt="profile"
                />
                <div>
                  <Typography className={classes.title}>{title}</Typography>
                  <Typography className={classes.title2}>
                    {title1}
                    <br />
                    {title2}
                  </Typography>
                </div>
              </div>
              <div className={classes.typo}>
                <div style={{ position: 'relative' }}>
                  <img src={vector} className={classes.quote} alt="quote" />
                </div>
                <Typography className={classes.desc}>{desc}</Typography>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default FeedbackCard;

// {
//   cardItems.map((items, index) => (
//     items.item1.map((data, index) => (
//       <div style={{ width: '100%', display: 'flex' }}>
//         <Card className={classes.cardBodyBlur}>
//           <CardActionArea>
//             <CardContent sx={{ padding: 0 }}>
//               <div className={classes.imagemain}>
//                 <img src={logo} className={classes.logo} alt="logo" />
//                 <img src={vector} className={classes.quote} alt="quote" />
//                 <div className={classes.profilediv}>
//                   <img
//                     src={data.left.profilepic}
//                     className={classes.profilepic}
//                     alt="profile"
//                   />
//                   <div>
//                     <Typography className={classes.title}>{data.left.title}</Typography>
//                     <Typography className={classes.title2}>
//                       {data.left.title1}
//                       <br />
//                       {data.left.title2}
//                     </Typography>
//                   </div>
//                 </div>
//                 <div className={classes.typo}>
//                   <Typography className={classes.desc}>{data.left.desc}</Typography>
//                 </div>
//               </div>
//             </CardContent>
//           </CardActionArea>
//         </Card>

//         <Card className={classes.cardBody}>
//           <CardActionArea>
//             <CardContent sx={{ padding: 0 }}>
//               <div className={classes.imagemain}>
//                 <img src={logo} className={classes.logo} alt="logo" />
//                 <img src={vector} className={classes.quote} alt="quote" />
//                 <div className={classes.profilediv}>
//                   <img
//                     src={data.center.profilepic}
//                     className={classes.profilepic}
//                     alt="profile"
//                   />
//                   <div>
//                     <Typography className={classes.title}>{data.center.title}</Typography>
//                     <Typography className={classes.title2}>
//                       {data.center.title1}
//                       <br />
//                       {data.center.title2}
//                     </Typography>
//                   </div>
//                 </div>
//                 <div className={classes.typo}>
//                   <Typography className={classes.desc}>{data.center.desc}</Typography>
//                 </div>
//               </div>
//             </CardContent>
//           </CardActionArea>
//         </Card>
//         <Card className={classes.cardBodyBlur}>
//           <CardActionArea>
//             <CardContent sx={{ padding: 0 }}>
//               <div className={classes.imagemain}>
//                 <img src={logo} className={classes.logo} alt="logo" />
//                 <img src={vector} className={classes.quote} alt="quote" />
//                 <div className={classes.profilediv}>
//                   <img
//                     src={data.right.profilepic}
//                     className={classes.profilepic}
//                     alt="profile"
//                   />
//                   <div>
//                     <Typography className={classes.title}>{data.right.title}</Typography>
//                     <Typography className={classes.title2}>
//                       {data.right.title1}
//                       <br />
//                       {data.right.title2}
//                     </Typography>
//                   </div>
//                 </div>
//                 <div className={classes.typo}>
//                   <Typography className={classes.desc}>{data.right.desc}</Typography>
//                 </div>
//               </div>
//             </CardContent>
//           </CardActionArea>
//         </Card>
//       </div>
//     ))
//   ))
// }