import React from "react";
import { makeStyles } from "@mui/styles";
import Heading from "./Heading";
import logo1 from "../../images/Adept Client Logo - Black and White/Property 1=image 1 BW.png";
import logo2 from "../../images/Adept Client Logo - Black and White/Property 1=image 6 BW.png";
import logo3 from "../../images/Adept Client Logo - Black and White/Property 1=image 9 BW.png";
import logo4 from "../../images/Adept Client Logo - Black and White/Property 1=image 10 BW.png";
import logo5 from "../../images/Adept Client Logo - Black and White/Property 1=image 11 BW.png";
import logo6 from "../../images/Adept Client Logo - Black and White/Property 1=image 12 BW.png";
import logo7 from "../../images/Adept Client Logo - Black and White/Property 1=image 13 BW.png";
import logo8 from "../../images/Adept Client Logo - Black and White/Property 1=image 14 BW.png";
import logo9 from "../../images/Adept Client Logo - Black and White/Property 1=image 15 BW.png";
import logo10 from "../../images/Adept Client Logo - Black and White/Property 1=image 17 BW.png";
import logo11 from "../../images/Adept Client Logo - Black and White/Property 1=image 18 BW.png";
import logo12 from "../../images/Adept Client Logo - Black and White/Property 1=Mask group 1 BW.png";
import logo13 from "../../images/Adept Client Logo - Black and White/Property 1=Rectangle BW.png";
import logo14 from "../../images/Adept Client Logo - Black and White/Property 1=Rectangle-1 BW.png";
import logo15 from "../../images/Adept Client Logo - Black and White/Property 1=Rectangle-2 BW.png";
import logo16 from "../../images/Adept Client Logo - Black and White/Property 1=Rectangle-3 BW.png";
import logo17 from "../../images/Adept Client Logo - Black and White/Property 1=Rectangle-4 BW.png";
import logo18 from "../../images/Adept Client Logo - Black and White/Property 1=Rectangle-5 BW.png";
import logo19 from "../../images/Adept Client Logo - Black and White/Property 1=Rectangle-6 BW.png";
import logo20 from "../../images/Adept Client Logo - Black and White/Property 1=Rectangle-7 BW.png";
import logo21 from "../../images/Adept Client Logo - Black and White/Property 1=TAUTOLOGY LLC 1 BW.png";
import logoCo1 from "../../images/Adept Client Logo - Color/Property 1=image 1 color.png";
import logoCo2 from "../../images/Adept Client Logo - Color/Property 1=image 6 color.png";
import logoCo3 from "../../images/Adept Client Logo - Color/Property 1=image 9 color.png";
import logoCo4 from "../../images/Adept Client Logo - Color/Property 1=image 10 color.png";
import logoCo5 from "../../images/Adept Client Logo - Color/Property 1=image 11 color.png";
import logoCo6 from "../../images/Adept Client Logo - Color/Property 1=image 12 color.png";
import logoCo7 from "../../images/Adept Client Logo - Color/Property 1=image 13 color.png";
import logoCo8 from "../../images/Adept Client Logo - Color/Property 1=image 14 color.png";
import logoCo9 from "../../images/Adept Client Logo - Color/Property 1=image 15 color.png";
import logoCo10 from "../../images/Adept Client Logo - Color/Property 1=image 17 color.png";
import logoCo11 from "../../images/Adept Client Logo - Color/Property 1=image 18 color.png";
import logoCo12 from "../../images/Adept Client Logo - Color/Property 1=Mask group 1 color.png";
import logoCo13 from "../../images/Adept Client Logo - Color/Property 1=Rectangle color.png";
import logoCo14 from "../../images/Adept Client Logo - Color/Property 1=Rectangle-1 color.png";
import logoCo15 from "../../images/Adept Client Logo - Color/Property 1=Rectangle-2 color.png";
import logoCo16 from "../../images/Adept Client Logo - Color/Property 1=Rectangle-3 color.png";
import logoCo17 from "../../images/Adept Client Logo - Color/Property 1=Rectangle-4 color.png";
import logoCo18 from "../../images/Adept Client Logo - Color/Property 1=Rectangle-5 color.png";
import logoCo19 from "../../images/Adept Client Logo - Color/Property 1=Rectangle-6 color.png";
import logoCo20 from "../../images/Adept Client Logo - Color/Property 1=Rectangle-7 color.png";
import logoCo21 from "../../images/Adept Client Logo - Color/Property 1=TAUTOLOGY LLC 1 color.png";
import { motion, Variants } from "framer-motion";
import HoverImage from "react-hover-image/build";
import Marquee from "react-fast-marquee";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.only("xs")]: {},
  },
  logodiv: {},
  div1: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "25px",
  },
  div2: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "25px",
  },
  div3: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "25px",
  },
  logo: {
    width: "145px",
  },
}));

const Clients = () => {
  const classes = useStyles();
  const text = {
    offscreen: { scale: 0.5, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2.5 },
    },
  };
  const logo = {
    offscreen: { opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2 },
    },
  };
  return (
    <>
      <motion.div
        className={classes.main}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.5 }}
      >
        {/* <motion.p variants={text}> */}
        <Heading
          firstTitle=""
          secondTitle="Our Clients"
          thirdTitle="Just not service providers, we are Marketing Partners"
        />
        {/* </motion.p> */}
      </motion.div>
      <div className="container1">
        <Marquee
          speed={150}
          gradient={false}
          direction="left"
          pauseOnHover={true}
        >
          <div
            style={{
              display: "flex",
              width: "auto",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: "100px",
              marginTop: "50px",
            }}
          >
            <HoverImage src={logo1} hoverSrc={logoCo1} className="fab" />
            <HoverImage src={logo2} hoverSrc={logoCo2} className="fab" />
            <HoverImage src={logo3} hoverSrc={logoCo3} className="fab" />
            <HoverImage src={logo4} hoverSrc={logoCo4} className="fab" />
            <HoverImage src={logo5} hoverSrc={logoCo5} className="fab" />
            {/* <HoverImage src={logo6} hoverSrc={logoCo6} className="fab" /> */}
            <HoverImage src={logo7} hoverSrc={logoCo7} className="fab" />
          </div>
        </Marquee>

        <Marquee
          speed={150}
          gradient={false}
          direction="right"
          pauseOnHover={true}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: "100px",
            }}
          >
            <HoverImage src={logo8} hoverSrc={logoCo8} className="fab2" />
            <HoverImage src={logo9} hoverSrc={logoCo9} className="fab2" />
            <HoverImage src={logo10} hoverSrc={logoCo10} className="fab2" />
            <HoverImage src={logo11} hoverSrc={logoCo11} className="fab2" />
            <HoverImage src={logo13} hoverSrc={logoCo13} className="fab2" />
            <HoverImage src={logo14} hoverSrc={logoCo14} className="fab2" />
            <HoverImage src={logo12} hoverSrc={logoCo12} className="fab2" />
          </div>
        </Marquee>
        <Marquee
          speed={150}
          gradient={false}
          direction="left"
          pauseOnHover={true}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: "100px",
            }}
          >
            <HoverImage src={logo15} hoverSrc={logoCo15} className="fab" />
            <HoverImage src={logo16} hoverSrc={logoCo16} className="fab" />
            <HoverImage src={logo17} hoverSrc={logoCo17} className="fab" />
            <HoverImage src={logo18} hoverSrc={logoCo18} className="fab" />
            <HoverImage src={logo19} hoverSrc={logoCo19} className="fab" />
            <HoverImage src={logo20} hoverSrc={logoCo20} className="fab" />
            <HoverImage src={logo21} hoverSrc={logoCo21} className="fab" />
          </div>
        </Marquee>
      </div>
    </>
  );
};

export default Clients;
