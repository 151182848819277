import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.only("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: "40px",
    },
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: "40px",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "40px",
    },
  },
  left: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.only("sm")]: {
      width: "95%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "95%",
    },
  },
  right: {
    width: "40%",
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  heading: {
    fontFamily: "Poppins !important",
    fontSize: "20px !important",
    fontWeight: "500 !important",
    color: "#3A3A3A !important",
    [theme.breakpoints.only("sm")]: {
      textAlign: "center !important",
    },
    [theme.breakpoints.only("xs")]: {
      textAlign: "center !important",
      fontSize: "16px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "16px !important",
    },
  },
  img: {
    width: "520px",
    [theme.breakpoints.only("md")]: {
      width: "369px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  list: {
    display: "flex",
    marginBottom: "10px",
  },
  bottom: {
    marginTop: "30px",
  },
  listitem: {
    fontFamily: "Poppins !important",
    fontSize: "20px !important",
    fontWeight: "400 !important",
    color: "#3A3A3A !important",
    [theme.breakpoints.only("md")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px !important",
    },
  },
  icn: {
    marginRight: "10px",
  },
}));

const Left = ({
  image,
  item1,
  item2,
  item3,
  item4,
  item5,
  icon,
  icon2,
  icon3,
  icon4,
  icon5,
  heading,
}) => {
  const classes = useStyles();
  const textAnimate = {
    offscreen: { x: -200, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const img = {
    offscreen: {  opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  return (
    <>
      <div className={classes.main}>
        <div className={classes.left}>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
          >
            <motion.p variants={textAnimate}>
              <div className={classes.top}>
                <Typography className={classes.heading}>{heading}</Typography>
              </div>
              <div className={classes.bottom}>
                <div className={classes.list}>
                  <div>
                    <img src={icon} className={classes.icn} />
                  </div>
                  <Typography className={classes.listitem}>{item1}</Typography>
                </div>
                <div className={classes.list}>
                  <div>
                    <img src={icon2} className={classes.icn} />
                  </div>
                  <Typography className={classes.listitem}>{item2}</Typography>
                </div>
                <div className={classes.list}>
                  <div>
                    <img src={icon3} className={classes.icn} />
                  </div>
                  <Typography className={classes.listitem}>{item3}</Typography>
                </div>
                <div className={classes.list}>
                  <div>
                    <img src={icon4} className={classes.icn} />
                  </div>
                  <Typography className={classes.listitem}>{item4}</Typography>
                </div>
                <div className={classes.list}>
                  <div>
                    <img src={icon5} className={classes.icn} />
                  </div>
                  <Typography className={classes.listitem}>{item5}</Typography>
                </div>
              </div>
            </motion.p>
          </motion.div>
        </div>
        <div className={classes.right}>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
          >
            <motion.p variants={img}>
              <img src={image} className={classes.img} />
            </motion.p>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Left;
