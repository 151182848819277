import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import img from "../../images/visioncardimg1.svg";
import img2 from "../../images/visioncardimg1border.svg";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "350px !important",
    height: "270px !important",
    borderRadius: "20px !important",
    boxShadow: "none !important",
    padding: "20px 10px !important",
  },
  card2: {
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "space-between !important",
  },
  title: {
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
    fontSize: "22px !important",
    marginTop: "40px !important",
  },
}));
const VisionCard = ({ title, img, img2 }) => {
  const classes = useStyles();
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(!isHover);
  };
  return (
    <>
      <Card
        className={`${classes.card} cardbody`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsHover(false)}
      >
        <CardContent>
          <img src={isHover ? img : img2} />
          <Typography className={classes.title}>{title}</Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default VisionCard;
