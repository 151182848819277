import React from "react";
import Marquee from "react-fast-marquee";
import GrowCard from "../home/GrowCard";
import joehopkins from "../../images/testimonial/joe_hopkins.png";
import jennifer from "../../images/testimonial/jennifer.jfif";
import amit from "../../images/testimonial/amit.png";
import walker from "../../images/testimonial/walker.jfif";
const Testimonials = () => {
  return (
    <div>
      <Marquee
        gradient={false}
        speed={150}
        pauseOnHover={true}
        style={{ height: "auto" }}
      >
        <GrowCard
          profile={joehopkins}
          owner="Joe Hopkins"
          title="Hopkins CPA Firm PC, Texas"
          desc="Extremely reasonable, bang for the buck you would be challenged to find a better service for a better price anywhere in the world. Adeptd works 24/7 to give timely feedback. Excellent quality of work & in depth client satisfaction goals"
        />
        <GrowCard
          profile={jennifer}
          owner="Jennifer Ginther"
          title="Fairwinds Bookkeeping & Tax Sol."
          desc="I was very pleased with how it turned out as well as how easy it was to get taken care of. We love that we can get all the help we need with landing pages, webpages and marketing all in one place. It was easy and pricing was excellent."
        />
        <GrowCard
          profile={amit}
          owner="Amit Chandel"
          title="Focus CPA Group Inc, California"
          desc="We would not have achieved what we are getting without their team."
        />
        <GrowCard
          profile={walker}
          owner="Darren D Walker"
          title="Darren D. Walker Marketing Inc. Canada"
          desc="They are responsive and incredibly proactive."
        />
      </Marquee>
    </div>
  );
};

export default Testimonials;
