import { useLottie } from 'lottie-react';
import React from 'react'
import notFound from '../../images/json/not_found.json'
const NotFound = () => {
    const options = {
        animationData: notFound,
        loop: true
    };
    const { View } = useLottie(options);

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '70%' }}>{View}</div></div>
    )
}

export default NotFound