import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import image from "../../images/contactImg.jpg";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "center",
    marginTop: "120px",
  },
  child: {
    width: "93%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.only("sm")]: {
      flexDirection: "column",
    },
  },
  headingdiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  left: {
    width: "48%",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontFamily: "Nothing you could do !important",
    fontSize: "50px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
    },
  },
  title2: {
    fontFamily: "Poppins !important",
    fontSize: "50px !important",
    color: "#3A3A3A !important",
    fontWeight: "700 !important",
    marginTop: "-45px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
    },
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "20px !important",
    color: "#3A3A3A !important",
    fontWeight: "400 !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "18px !important",
    },
  },
  greenHeadingDiv: {
    width: "35%",
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      width: "70%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "70%",
    },
  },
  centerHeadingDiv: {
    width: "100%",
    textAlign: "center",
  },
  descHeadingDiv: {
    width: "100%",
    marginTop: "20px",
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
  formMaindiv: {
    marginTop: "30px",
    width: "100%",
  },
  form1: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  fName: {
    display: "flex",
    flexDirection: "column",
    width: "44%",
  },
  email: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  fieldtitle: {
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    color: "#3A3A3A !important",
    fontWeight: "400 !important",
    marginBottom: "10px !important",
  },
  field: {
    borderRadius: "50px",
    border: "1.5px solid #159346",
    backgroundColor: "#E2E2E2",
    height: "40px",
    padding: "10px",
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    color: "black !important",
    boxShadow: "0px 6px 10px 0px #00000017 inset",
  },
  field2: {
    borderRadius: "35px",
    padding: "15px",
    border: "1.5px solid #159346",
    backgroundColor: "#E2E2E2",
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    color: "black !important",
    boxShadow: "0px 6px 10px 0px #00000017 inset",
    resize:'none',
    overflow: "hidden"
  },
  btn: {
    textTransform: "capitalize !important",
    backgroundColor: "#159346 !important",
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    color: "#FDFDFD !important",
    borderRadius: "50px !important",
    width: "180px !important",
    height: "45px !important",
    marginTop: "15px !important",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    [theme.breakpoints.only("xs")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "30px",
    },
  },
  aeroBody2: {
    display: "flex",
    marginTop: "-33px",
    marginBottom: "0px",
    width: "77%",
  },
  firstAero: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    marginLeft: "180px !important",
    transform: "rotate(322deg)",
  },
}));
const ContactUs = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  return (
    <>
      <div className={classes.main}>
        <div className={classes.child}>
          <div className={classes.left}>
            <div className={classes.headingdiv}>
              {/* <div className={classes.greenHeadingDiv}>
                <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
                  {visible ? (
                    <Typography className={classes.title}>
                      <TypeAnimation
                        repeat={Infinity}
                        sequence={["Your", 1000]}
                        wrapper="div"
                        cursor={false}
                      />
                    </Typography>
                  ) : (
                    <Typography className={classes.title}>Your</Typography>
                  )}
                </ScrollTrigger>
              </div> */}
              {/* <div className={classes.aeroBody2}>
                <Typography className={`${classes.firstAero} floatingstraight`}>
                  ^
                </Typography>
              </div> */}
              <div className={classes.centerHeadingDiv}>
                <Typography className={classes.title2}>
                  Get the marketing and technology team who know your business.
                </Typography>
              </div>
              <div className={classes.descHeadingDiv}>
                <Typography className={classes.desc}>
                  Have an inquiry or some feedback for us? Fill out the form
                  <br />
                  below to contact our team.
                </Typography>
              </div>
            </div>
            <div className={classes.formMaindiv}>
              <div className={classes.form1}>
                <div className={classes.fName}>
                  <Typography className={classes.fieldtitle}>
                    First Name
                  </Typography>
                  <input type="text" className={classes.field} />
                </div>
                <div className={classes.fName}>
                  <Typography className={classes.fieldtitle}>
                    Last Name
                  </Typography>
                  <input type="text" className={classes.field} />
                </div>
              </div>
              <div className={classes.form1}>
                <div className={classes.email}>
                  <Typography className={classes.fieldtitle}>
                    Email Address
                  </Typography>
                  <input type="text" className={classes.field} />
                </div>
              </div>
              <div className={classes.form1}>
                <div className={classes.email}>
                  <Typography className={classes.fieldtitle}>
                    Your Message
                  </Typography>
                  <textarea rows="3" cols="0" className={classes.field2} />
                </div>
              </div>
              <Button className={classes.btn} variant="contained">
                Submit
              </Button>
            </div>
          </div>
          <div className={classes.left}>
            <img src={image} className={classes.img} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
