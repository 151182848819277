import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardActionArea, CardContent, createTheme, Tooltip, useMediaQuery } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "390px !important",
    height: "250px !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    cursor: "pointer !important",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15) !important",
    borderRadius: "8px !important",
    [theme.breakpoints.only("sm")]: {
      width: "280px!important",
      height: "215px!important",
    },
    [theme.breakpoints.only("xs")]: {
      width: "85% !important",
      height: "215px!important",
    },
    [theme.breakpoints.only("md")]: {
      width: "270px!important",
      height: "225px!important",
    },
  },
  cardHover: {
    width: "300px !important",
    height: "250px !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    cursor: "pointer !important",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "320px",
  },
  image: {
    width: "100px",
    [theme.breakpoints.only("md")]: {
      width: "85px",
    },
  },
  span: {
    fontFamily: 'Poppins',
    color: '#179446 ',
    fontWeight: '400',
    marginTop: '30px'
  }
}));
const ServiceCard = ({ id, title, image }) => {
  const classes = useStyles();
  const [hoverId, setHoverId] = useState(0);
  const [hover, setHover] = useState(false);
  const handleMouseEnter = (enterId) => {
    if (id === enterId) {
      setHover(true);
      setHoverId(id);
      console.log(id);
    }
  };
  const handleMouseExit = (exitId) => {
    if (id === exitId) {
      setHover(false);
      setHoverId(id);
    }
  };

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

  return (
    <>
      {
        isMobile ?
          <div className={classes.main}>
            <Card
              className={classes.card}
              onMouseEnter={() => handleMouseEnter(id)}
              onMouseLeave={() => handleMouseExit(id)}
            >
              <CardContent>
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'space-between', alignItems: 'center' }}>
                  <img src={image} className={classes.image} />

                  <span className={classes.span}>{title}</span>
                </div>

              </CardContent>
            </Card>

          </div> :
          <div className={classes.main}>
            <Card
              className={classes.card}
              onMouseEnter={() => handleMouseEnter(id)}
              onMouseLeave={() => handleMouseExit(id)}
            >
              <CardContent>
                <img src={image} className={classes.image} />
              </CardContent>
            </Card>
            {hover && hoverId === id ? (
              <span class="tooltiptext">{title}</span>
            ) : (
              <div />
            )}
          </div>
      }

    </>
  );
};

export default ServiceCard;
