import React from "react";
import { makeStyles } from "@mui/styles";
import { createTheme, Grid, Typography, useMediaQuery } from "@mui/material";
import ServiceCard from "./ServiceCard";
import image1 from "../../../images/dataAnalysis.svg";
import image2 from "../../../images/webdesign.svg";
import image3 from "../../../images/webpage.svg";
import image4 from "../../../images/changeicon.svg";
import image7 from "../../../images/changeicon1.svg";
import image5 from "../../../images/homeoffice.svg";
import image6 from "../../../images/branding.svg";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontFamily: "Poppins !important",
    fontSize: "48px !important",
    fontWeight: "700!important",
    color: "#3A3A3A !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "44px !important",
      marginTop: "40px !important",
    },
  },
  cardBody: {
    width: "92% ",
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
  },
  firstRow: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
const Service = ({ path }) => {
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const classes = useStyles();
  const textAnimate = {
    offscreen: { scale: 0, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  return (
    <>{
      isMobile ?
        <div className={classes.main}>
          <Typography className={classes.heading}>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={textAnimate}>Services used</motion.p>
            </motion.div>
          </Typography>
          <Grid container style={{ marginTop: "50px" }}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard
                    id={6}
                    image={image1}
                    title="Performance Marketing (PPC and Social Media Ads)"
                  />
                </motion.p>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard id={5} image={image2} title="Landing Page" />
                </motion.p>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard
                    id={4}
                    image={image3}
                    title="Website Design and Development"
                  />
                </motion.p>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard
                    id={1}
                    image={path === "swat" ? image7 : image4}
                    title="Marketing Automation Setup (Zoho)"
                  />
                </motion.p>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard
                    id={2}
                    image={image5}
                    title="Website Design and Development"
                  />
                </motion.p>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard id={3} image={image6} title="Landing Page" />
                </motion.p>
              </motion.div>
            </Grid>
          </Grid>
        </div> :
        <div className={classes.main}>
          <Typography className={classes.heading}>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={textAnimate}>Services used</motion.p>
            </motion.div>
          </Typography>
          <Grid container style={{ marginTop: "50px" }}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard
                    id={6}
                    image={image1}
                    title="Performance Marketing (PPC and Social Media Ads)"
                  />
                </motion.p>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard id={5} image={image2} title="Landing Page" />
                </motion.p>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard
                    id={4}
                    image={image3}
                    title="Website Design and Development"
                  />
                </motion.p>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard
                    id={1}
                    image={path === "swat" ? image7 : image4}
                    title="Marketing Automation Setup (Zoho)"
                  />
                </motion.p>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard
                    id={2}
                    image={image5}
                    title="Website Design and Development"
                  />
                </motion.p>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <ServiceCard id={3} image={image6} title="Landing Page" />
                </motion.p>
              </motion.div>
            </Grid>
          </Grid>
        </div>
    }

    </>
  );
};

export default Service;
