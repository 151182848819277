import { Grid, TextField, ThemeProvider, createTheme, Select, MenuItem, Box, Chip, OutlinedInput, FormControl, FormLabel, FormControlLabel, Checkbox, Button, Typography, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import AdaptedLogo from '../../../images/footerLogo.svg'
import TaxMob from '../../../images/taxmob.png'
import React, { useState } from 'react'
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
        flexDirection: 'column'
    },
    logo: {
        width: '150px',
        margin: '10px',
        marginBottom: '20px'
    },
    v1: {
        borderLeft: "4px solid #159346",
        height: "50px",
        position: "relative",
        
        marginLeft: "-3px",
        top: "10px",
    },
    logoContainer:{
        display:'flex',
        
    },
    inputbox: {
        border: "2px solid #159346",
        boxShadow: "inset 0px 6px 10px rgba(0, 0, 0, 0.09)",
        borderRadius: "59px",
        padding: "10px",
        width: "100% !important",
        height: "60px",
        color: "#3a3a3a !important",
        fontFamily: "Poppins !important",
        paddingLeft: "25px",
        [theme.breakpoints.up('sm')]:{
            display:'flex !important'
        }
    },
    textArea: {
        border: "2px solid #159346",

        width: "100% !important",
        marginTop: '10px',
        color: "#3a3a3a !important",
        fontFamily: "Poppins !important",

        "&.MuiTextField-root": {
            width: "100% !important"
        }
    },

    selectbox: {
        border: "2px solid #159346",
        boxShadow: "inset 0px 6px 10px rgba(0, 0, 0, 0.09)",
        borderRadius: "59px",
        padding: "10px",
        width: "50% !important",
        height: "60px",
        color: "#3a3a3a !important",
        fontFamily: "Poppins !important",
        paddingLeft: "25px",
      
    },
    label: {
        color: "#3a3a3a !important",
        fontFamily: "Poppins !important",
        marginTop: '10px',
        fontWeight: '600',
        marginLeft: '30px'
    },
    button: {
        fontFamily: "Poppins !important",
        fontWeight: '400 !important',
        textTransform: 'none !important',
        color: 'white !important',
        background: '#179446 !important',
        borderRadius: '20px !important',
        paddingLeft: '50px !important',
        paddingRight: '50px !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center '

    },
    typo: {
        fontSize: '30px !important',
        fontFamily: 'Poppins !important',
        fontWeight: '700 !important',
        color: "#3A3A3A !important",
        marginBottom: '10px !important',
        [theme.breakpoints.up('xs')]:{
            fontSize:"20px !important",
            padding:'10px !important',
            textAlign:'center !important'
        }
    },
    typoSub: {
        fontSize: '30px !important',
        fontFamily: 'Nothing you could do !important',
        fontWeight: '700 !important',
        color: "#179446 !important",
        marginBottom: '10px !important',
        [theme.breakpoints.up('xs')]:{
            fontSize:"20px !important",
            padding:'10px !important',
            textAlign:'center !important'
        }
    },
    helperText:{
        fontFamily:'Poppins !important',
        fontWeight:'500 !important',
        color:'#3A3A3A !important',
        fontSize:'12px',
        marginTop:'5px !important',
        marginLeft:'10px !important',
        [theme.breakpoints.only('xs')]:{
            textAlign:'center !important',
            marginTop:'10px !important',
            padding:'10px'
        }
    },
    span:{
        fontFamily:'Poppins !important',
        fontWeight:'700 !important',
        color:'#179446 !important',
        fontSize:'12px',
        marginTop:'5px !important',
    },
    mainGrid:{
        [theme.breakpoints.only('xs')]:{
            width:"100% !important",
            alignItems:'center',
            justifyContent:'center',
            textAlign:'center'
        }
    },
    timezoneBox:{
        width: '46%',
         border: "2px solid #159346",
        height: "60px",
        color: "#3a3a3a !important",
        fontFamily: "Poppins !important",
        boxShadow: "inset 0px 6px 10px rgba(0, 0, 0, 0.09)",
        borderRadius: "59px",
        padding: "10px",
        "&.MuiSelect-select:focus": {
            border: "none !imporatant",
        },
        [theme.breakpoints.only('xs')]:{
            width:"73% !important",
            alignItems:'center',
            justifyContent:'center',
            textAlign:'center',
            borderRadius: "59px",
        }
    },
    serviceBox:{
        width: '100%',
         border: "2px solid #159346",
        height: "60px",
        color: "#3a3a3a !important",
        fontFamily: "Poppins !important",
        boxShadow: "inset 0px 6px 10px rgba(0, 0, 0, 0.09)",
        borderRadius: "59px",
        padding: "10px",
        "&.MuiSelect-select:focus": {
            border: "none !imporatant",
        },
        [theme.breakpoints.only('xs')]:{
            minWidth:"100% !important",
            borderRadius: "59px",
        }
    },
    formControl:{
         width: '600px',
         [theme.breakpoints.only('xs')]:{
            width:"300px",
            borderRadius: "59px",
        }
    },
    checkmark:{
        display: 'flex', justifyContent: 'center', marginTop: '10px', fontFamily: 'Poppins !important', fontWeight: '600 !important',
        [theme.breakpoints.only('xs')]:{
           
           fontSize:'8px !important'
        } 

    }
}))

const Events = () => {
    const classes = useStyles()
    const theme = createTheme();
    const [services, setServices] = useState([])
    const [values, setValues] = useState({
        fullName: "",
        email: "",
        mobile: "",
        timezone: "",
        desc: ""
    })
    const service = [
        " Web Design and Development",
        "Performance Marketing - Social Media & Search Ads",
        "Search Engine Optimization (SEO)",
        "Youtube SEO, Email Marketing",
        "LinkedIn Marketing, CRM Setup & Maintenance",
        "Designing",
        " Digital Growth Consulting",
        " Other help!"
    ]
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(preValue => ({
            ...preValue,
            [name]: value
        }))
    }

    const handleChipsChange = (e) => {
        const {
            target: { value },
        } = e
        setServices(typeof value === "string" ? value.split(',') : value)
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 45,
            },
        },
    };


    function getStyles(name, services, theme) {
        return {
            fontWeight:
                services.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const header = {
        'Accept': "application/json, text/plain, */*",
        'Content-Type': "application/json"
    }
    const handleSubmit = () => {
        if (values.fullName.length === 0) {
            alert("Please Enter FullName")
        }
        else if (values.email.length === 0) {
            alert("Please Enter Email")
        } else if (values.mobile.length === 0) {
            alert("Please Enter Mobile Number")
        } else if (values.timezone.length === 0) {
            alert("Please Select Timezone")

        }
        else if (services.length === 0) {
            alert("Please Select Service")
        } else if (values.desc.length === 0) {
            alert("Please Enter Description")
        }
        else {
            axios.post('https://adeptd.com/adeptd/api.php',

                {
                    fullname: values.fullName,
                    email: values.email,
                    mobile: values.mobile,
                    timezone: values.timezone,
                    services: services,
                    description: values.desc,
                    agree_status : 1
                }, { header: header })
                .then(function (response) {
                    Swal.fire({
                        icon: 'success',
                        text: response?.data?.message,
                      })
                      setValues({
                        fullName:"",
                        email:"",
                        mobile:"",
                        timezone:"",
                        desc:""
                        
                      })
                      setServices([])
                })
                .catch(function (error) {
                    Swal.fire({
                        icon: 'error',
                        title:"Oops!",
                        text: error?.message,
                      }) 
                });
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <div className={classes.mainContainer}>
                <div className={classes.logoContainer}>
                    <img src={AdaptedLogo} alt="logo" className={classes.logo} />
                    <div className={classes.v1}/>
                    <img src={TaxMob} alt="logo" className={classes.logo} />
                    
                </div>
                <Divider />
                <Typography className={classes.typo}>We are glad that you have taken a step forward to gain your digital success!</Typography>
                <Typography className={classes.typoSub}>We need to know more about you before we connect!</Typography>
                <Grid className={classes.mainGrid} >



                    <Grid xs="12">
                        <TextField
                            variant="standard"
                            name="fullName"
                            value={values.fullName}
                            onChange={handleChange}
                            sx={{ margin: '10px' }}
                            InputProps={{
                                className: classes.inputbox,
                                disableUnderline: true
                            }}
                            placeholder="Enter Full Name"
                            disableUnderline={true}
                        />
                        <TextField
                            variant="standard"
                            name="email"
                            sx={{ margin: '10px' }}
                            value={values.email}
                            onChange={handleChange}
                            InputProps={{
                                className: classes.inputbox,
                                disableUnderline: true
                            }}
                            placeholder="Enter Email"
                            disableUnderline={true}
                        />
                    </Grid>
                    <Grid xs="12">

                        <TextField
                            variant="standard"
                            name="mobile"
                            sx={{ margin: '10px' }}
                            value={values.mobile}
                            onChange={handleChange}
                            InputProps={{
                                className: classes.inputbox,
                                disableUnderline: true
                            }}

                            placeholder="Enter Phone Number"
                            disableUnderline={true}
                            type="tel"
                        />

                        <Select
                            variant="outlined"
                            name="timezone"
                            sx={{
                               borderRadius:'59px',
                               margin:'10px'
                            }}

                            value={values.timezone}
                            className={classes.timezoneBox}

                            onChange={handleChange}
                            displayEmpty
                            inputProps={{
                                style: { paddingLeft: "25px" }
                            }}
                            placeholder="Enter Full Name"
                        ><MenuItem disabled value="">
                                <em>Select Timezone</em>
                            </MenuItem>
                            <MenuItem value={"PST"}>PST</MenuItem>
                            <MenuItem value={"EST"}>EST</MenuItem>
                            <MenuItem value={"MST"}>MST</MenuItem>
                            <MenuItem value={"CST"}>CST</MenuItem>
                        </Select>
                        <Grid xs="12">

                            <FormControl className={classes.formControl} sx={{ marginLeft: '10px', marginTop: '10px',}}>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    variant='outlined'
                                    value={services}
                                    onChange={handleChipsChange}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em>Services you are looking for? </em>;
                                        }

                                        return selected.join(', ');
                                    }}
                                    MenuProps={MenuProps}
                                    className={classes.serviceBox}
                                    sx={{borderRadius:"59px"}}
                                >
                                    <MenuItem disabled value="">
                                        <em>Services you are looking for? </em>
                                    </MenuItem>
                                    {service.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles(name, services, theme)}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs="12">
                            {/* <label className={classes.label}>hello</label> */}
                            <TextField
                                multiline
                                variant="standard"
                                name="desc"
                                value={values.desc}
                                onChange={handleChange}
                                sx={{
                                    marginTop: '20px', width: '100%',
                                    border: "2px solid #159346",

                                    color: "#3a3a3a !important",
                                    fontFamily: "Poppins !important",
                                    boxShadow: "inset 0px 6px 10px rgba(0, 0, 0, 0.09)",
                                    borderRadius: "40px",
                                    paddingLeft: "30px",
                                    "&.MuiSelect-select:focus": {
                                        border: "none !imporatant",
                                    },

                                }}
                                maxRows={12}
                                minRows={6}
                                inputProps={{
                                    className: classes.textArea,
                                    disableUnderline: true
                                }}
                                placeholder="You can share your concerns too with us!"
                                disableUnderline={true}
                            
                            />
                        <p className={classes.helperText}>Mention code <span className={classes.span}>TaxMob2023</span> in the description field on the form to avail exclusive offers!</p>
                        </Grid>
                        <Grid xs={12}>
                            <FormControlLabel className={classes.checkmark} control={<Checkbox />} label="I agree to receive communications from Adeptd" />

                        </Grid>
                        <Grid xs={12} >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                                <Button className={classes.button} onClick={handleSubmit} type="submit" sx={{ background: 'red' }}>Submit</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </ThemeProvider>
    )
}

export default Events