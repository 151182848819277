import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Bannerheading from "../common/Bannerheading";
import {
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import BannerLogo from "../common/BannerLogo";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import { motion, Variants } from "framer-motion";
import AnimatedText from "react-animated-text-content";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "70px",
    // marginTop: "100px",
  },
  firstheadingdiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  firstheading: {
    fontFamily: "Nothing you could do !important",
    fontSize: "52px !important",
    color: "#179446 !important",
    fontWeight: "700 !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px !important",
    },
  },
  subdiv: {
    width: "69%",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "80%",
    },
  },
  centerheading: {
    fontFamily: "Poppins !important",
    fontSize: "52px !important",
    color: "#3A3A3A !important",
    fontWeight: "700 !important",
    marginTop: "-55px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px !important",
    },
  },
  centerheadingdiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    color: "#3A3A3A !important",
    fontWeight: "500 !important",
    inlineSize: "850px",
    marginTop: "30px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "18px !important",
      inlineSize: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "22px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "22px !important",
    },
  },
  secondheading: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    marginTop: "25px",
  },
  titlediv: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  title: {
    fontFamily: "Nothing you could do !important",
    fontSize: "50px !important",
    color: "#179446 !important",
    marginBottom: "-10px !important",
    fontWeight: "700 !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px !important",
    },
  },
  title2: {
    fontFamily: "Poppins !important",
    fontSize: "50px !important",
    color: "#3A3A3A !important",
    fontWeight: "700 !important",
    marginTop: "-50px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "31px !important",
      textAlign: "center",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
      textAlign: "center",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px !important",
      textAlign: "center",
    },
  },
  search: {
    border: "2px solid #159346",
    boxShadow: "inset 0px 6px 10px rgba(0, 0, 0, 0.09)",
    borderRadius: "59px",
    padding: "10px",
    width: "800px !important",
    height: "70px",
    color: "#3a3a3a !important",
    fontFamily: "Poppins !important",
    paddingLeft: "25px",
    [theme.breakpoints.only("xs")]: {
      width: "100% !important",
      height: "60px",
      fontSize: "12px !important",
    },
    [theme.breakpoints.only("sm")]: {
      width: "590px !important",
      height: "60px",
      fontSize: "12px !important",
    },
  },
  searchdiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "90px",
  },
  btn: {
    textTransform: "capitalize !important",
    backgroundColor: "#159346 !important",
    borderRadius: "50px !important",
    color: "white !important",
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    padding: "10px 25px !important",
    [theme.breakpoints.only("xs")]: {
      padding: "10px 20px !important",
      fontSize: "10px !important",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "10px 22px !important",
      fontSize: "12px !important",
    },
  },
  logodiv: {
    marginTop: "30px",
  },
  aeroBody: {
    display: "flex",
    marginTop: "-26px",
    marginBottom: "0px",
    justifyContent: "space-around",
    width: "28%",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.only("sm")]: {
      width: "10%",
      
    },
  },
  aeroBody2: {
    display: "flex",
    marginTop: "-26px",
    marginBottom: "0px",
    width: "109%",
    [theme.breakpoints.only("xs")]: {
      width: "115%",
      
    },
    [theme.breakpoints.only("sm")]: {
      width: "115%",
      
    },
  },
  firstAero: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    marginLeft: "180px !important",
    transform: "rotate(322deg)",
    [theme.breakpoints.only("xs")]: {
      marginLeft: "40px !important",
    },
  },
  secAero: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    marginLeft: "180px !important",
    transform: "rotate(322deg)",
    [theme.breakpoints.only("xs")]: {
      marginLeft: "120px !important",
    },
  },
}));

const Banner = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const text = {
    offscreen: { scale: 0.5, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2.5 },
    },
  };
  const textAnimate2 = {
    offscreen: { x: -100, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const logo = {
    offscreen: { opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const mail = {
    offscreen: { y: 50, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  return (
    <>
      <div className={classes.main}>
        <Container maxWidth="lg">
          <div>
            <div className={classes.firstheadingdiv}>
              <div className={classes.subdiv}>
                <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
                  {visible ? (
                    <Typography className={classes.firstheading}>
                      <TypeAnimation
                        repeat={Infinity}
                        sequence={["We Know", 1000]}
                        wrapper="div"
                        cursor={false}
                      />
                    </Typography>
                  ) : (
                    <Typography className={classes.firstheading}>
                      We Know
                    </Typography>
                  )}
                </ScrollTrigger>
              </div>
            </div>
            <div className={classes.aeroBody}>
              <Typography className={`${classes.firstAero} floating`}>
                ^
              </Typography>
            </div>
            <div className={classes.centerheadingdiv}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={text}>
                  <Typography className={classes.centerheading}>
                    What you are Seeking !
                  </Typography>
                </motion.p>
              </motion.div>
            </div>
            <div className={classes.centerheadingdiv}>
              {/* <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate2}>
                  <Typography className={classes.desc}> */}
              <AnimatedText
                type="chars" // animate words or chars
                animation={{
                  // x: "-100px",
                  // y: "-18px",
                  scale: 1,
                  ease: "ease",
                }}
                animationType="wave"
                interval={0.02}
                duration={0.3}
                tag="p"
                className={classes.desc}
                includeWhiteSpaces
                threshold={0.1}
                rootMargin="20%"
              >
                Welcome your best-in-class accounting firm digital marketing expert who
                knows the Formula
              </AnimatedText>
              {/* </Typography>
                </motion.p>
              </motion.div> */}
            </div>
          </div>
          <div className={classes.secondheading}>
            <div className={classes.titlediv}>
              <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
                {visible ? (
                  <Typography className={classes.title}>
                    <TypeAnimation
                      repeat={Infinity}
                      sequence={["Law firms", 5000]}
                      wrapper="div"
                      cursor={false}
                    />
                  </Typography>
                ) : (
                  <Typography className={classes.title}>Accounting firms</Typography>
                )}
              </ScrollTrigger>
            </div>
            <div className={classes.aeroBody2}>
              <Typography className={`${classes.secAero} floatingstraight`}>
                ^
              </Typography>
            </div>
            <div className={classes.titlediv2}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={text}>
                  <Typography className={classes.title2}>
                    30+ &nbsp; choose their right marketing partners
                  </Typography>
                </motion.p>
              </motion.div>
            </div>
          </div>
          <div className={classes.logodiv}>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={logo}>
                <BannerLogo />
              </motion.p>
            </motion.div>
          </div>
          <div className={classes.searchdiv}>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={mail}>
                <TextField
                  variant="standard"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    className: classes.search,
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment>
                        {email === "" ? (
                          <NavLink
                            type="submit"
                            style={{ textDecoration: "none" }}
                            to={`/contactus/`}
                          >
                            <Button className={classes.btn}>
                              Reach Out Now
                            </Button>
                          </NavLink>
                        ) : (
                          <NavLink
                            type="submit"
                            style={{ textDecoration: "none" }}
                            to={`/contactus/${email}`}
                          >
                            <Button className={classes.btn}>
                              Reach Out Now
                            </Button>
                          </NavLink>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter You Mail Address"
                  disableUnderline={true}
                />
              </motion.p>
            </motion.div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Banner;
