import React, { useEffect } from "react";
import Banner from "./common/Banner";
import image from "../../images/hopkinsBannerImg.png";
import Service from "./common/Service";
import Challenge from "./common/Challenge";
import Impact from "./common/Impact";
import CardSlider from "./common/CardSlider";
import WorkCard from "./common/WorkCard";
import { ThemeProvider, useTheme } from "@mui/material";
import icon from "../../images/check_circle.svg";
import OurWork from "../common/work/OurWork";
import BackButton from "./common/BackButton";
import Feedback from "../home/Feedback";
import Dream from "../home/Dream";

const Hopkins = () => {
  const theme = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <ThemeProvider theme={theme}>
          <BackButton />
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <Banner
            icon={icon}
            greenheading="Our Clients"
            centerheading="A Texas based CPA and Tax Resolution firm "
            desc="Performance Marketing"
            cardtitle="Objective"
            carddesc="Customer Calls  and Inquiry Form  Submits looking for  Tax resolution  Services"
            firstcarddesc1="Facebook"
            firstcardtitle="Marketing Channel"
            firstcarddesc2="Instagram"
            firstcarddesc3="Google Search Ads"
            secondcardtitle="Achievement"
            secondcarddesc="200+ Calls Received"
            secondcarddesc2="500+ Form submit inquiry"
            thirdcardtitle="Service Provided"
            thirdcarddesc="Paid Marketing Ads"
            image={image}
          />
          <Service />
          <Challenge maintitle="Challenges" path="hopkins" />
          <Impact maintitle="Impact" path="hopkins" />
          <OurWork />
          <Dream />
          <Feedback />
          {/* <CardSlider /> */}
          {/* <WorkCard /> */}
        </ThemeProvider>
      </div>
    </>
  );
};

export default Hopkins;
