import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import image1 from "../../images/DM.svg";
import image2 from "../../images/EC.svg";
import image3 from "../../images/SI.svg";
import image4 from "../../images/SM.svg";
import image5 from "../../images/LAW.svg";
import SectorCard from "./SectorCard";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: "50px",
    marginTop: "50px",
  },
  titleBody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  greenTitleBody: {
    width: "61%",
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "69%",
    },
    [theme.breakpoints.only("md")]: {
      width: "67%",
    },
  },
  centerTitleBody: {
    width: "100%",
    textAlign: "center",
    marginTop: "-55px",
  },
  centerTitle: {
    fontFamily: "Poppins !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "32px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px !important",
    },
  },
  greenTitle: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px !important",
    },
  },
  greenTitle2: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    marginRight: "140px !important",

    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
      marginRight: "48px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
      marginRight: "100px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px !important",
    },
  },
  mainCardBody: {
    width: "100%",
    marginTop: "50px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      alignContent: "space-between",
    },
  },
  cardBody: {
    width: "420px !important",
    height: "455px !important",
    display: "flex !important",
    flexDirection: "column !important",
    borderRadius: "5px !important",
    border: "2px solid #179446",
    boxShadow: "none !important",
    [theme.breakpoints.only("xs")]: {
      // width: "100% !important",
      marginBottom: "20px",
      width: "350px !important",
      height: "220px !important",
    },
    [theme.breakpoints.only("sm")]: {
      // width: "100% !important",
      marginBottom: "20px",
      width: "350px !important",
      height: "220px !important",
    },
    [theme.breakpoints.only("md")]: {
      // width: "100% !important",
      marginBottom: "20px",
    },
  },
  cardBodyTwo: {
    width: "100% !important",
    height: "342px !important",
    [theme.breakpoints.only("xs")]: {
      width: "100% !important",
      height: "186px !important",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100% !important",
      height: "186px !important",
    },
  },
  Cardtitle: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    fontWeight: "600 !important",
    color: "#179446 !important",
  },
  desc: {
    inlineSize: "200px",
    fontFamily: "Poppins !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    marginTop: "20px !important",
    color: "#3A3A3A !important",
    marginBottom: "25px !important",
  },
  image: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    width: "360px",
    [theme.breakpoints.only("xs")]: {
      width: "180px !important",
    },
    [theme.breakpoints.only("sm")]: {
      width: "180px !important",
    },
  },
  Card: {
    [theme.breakpoints.only("md")]: {
      display: "flex",
      width: "100%",
      justifyContent: "space-around",
    },
  },
  CardCenter: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("md")]: {
      display: "flex",
      width: "100%",
      justifyContent: "space-around",
    },
  },
  aeroBody: {
    display: "flex",
    marginTop: "-35px",
    marginBottom: "0px",
    justifyContent: "space-around",
    width: "31%",
    [theme.breakpoints.only("xs")]: {
      width: "64%",
      marginTop: "-25px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "42%",
      marginRight: "36px",
    },
  },
  secondAero: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
  },
  firstAero: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    marginLeft: "-119px !important",
    transform: "rotate(322deg)",
    [theme.breakpoints.only("xs")]: {
      marginLeft: "-60px !important",
    },
    [theme.breakpoints.only("md")]: {
      marginLeft: "-60px !important",
    },
    [theme.breakpoints.only("sm")]: {
      marginLeft: "-45px !important",
    },
  },
  whyusTitle: {
    color: "3A3A3A !important",
    fontFamily: "Poppins !important",
    fontWeight: "600 !important",
    width: "100% !important",
    textAlign: "center !important",
    fontSize: "32px !important",
    marginTop: "20px !important",
  },
}));
const Sectors = ({ path }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const text = {
    offscreen: { scale: 0.5, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2.5 },
    },
  };
  const card1 = {
    offscreen: { scale: 0.5, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 1 },
    },
  };
  const card2 = isMobile
    ? {
        offscreen: { opacity: 1 },
        onscreen: {
          y: 0,
          x: 0,
          scale: 1,
          opacity: 1,
          transition: { type: "spring", bounce: 0, duration: 1 },
        },
      }
    : {
        offscreen: { opacity: 0 },
        onscreen: {
          y: 0,
          x: 0,
          scale: 1,
          opacity: 1,
          transition: { type: "spring", bounce: 0, duration: 1 },
        },
      };
  return (
    <>
      <div>
        <Container maxWidth="lg">
          <div className={classes.main}>
            {path === "whyus" ? (
              <Typography className={classes.whyusTitle}>
                We are shaping tough businesses Globally
              </Typography>
            ) : (
              <div className={classes.titleBody}>
                <div className={classes.greenTitleBody}>
                  <Typography className={classes.greenTitle}>
                    <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
                      {visible ? (
                        <TypeAnimation
                          wrapper="span"
                          speed={45}
                          sequence={["Sector’s", 1000, "Sector’s", 2000]}
                          cursor={false}
                          repeat={Infinity}
                        />
                      ) : (
                        <Typography className={classes.greenTitle}>
                          Sector’s
                        </Typography>
                      )}
                    </ScrollTrigger>
                  </Typography>
                  <Typography className={classes.greenTitle2}>
                    <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
                      {visible ? (
                        <TypeAnimation
                          wrapper="span"
                          speed={45}
                          sequence={["Loved", 1000, "Loved", 2000]}
                          cursor={false}
                          repeat={Infinity}
                        />
                      ) : (
                        <Typography className={classes.greenTitle2}>
                          Loved
                        </Typography>
                      )}
                    </ScrollTrigger>
                  </Typography>
                </div>
                <div className={classes.aeroBody}>
                  <Typography className={`${classes.firstAero} floating`}>
                    ^
                  </Typography>
                  <Typography
                    className={`${classes.secondAero} floating-right`}
                  >
                    ^
                  </Typography>
                </div>
                <div className={classes.centerTitleBody}>
                  <motion.div
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: false, amount: 0.5 }}
                    transition={{ staggerChildren: 0.5 }}
                  >
                    <motion.p variants={text}>
                      <Typography className={classes.centerTitle}>
                        We Are &nbsp; By
                      </Typography>
                    </motion.p>
                  </motion.div>
                </div>
              </div>
            )}
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={card2}>
                <div className={classes.mainCardBody}>
                  <div className={classes.Card}>
                    {" "}
                    <SectorCard
                      title="Digital Marketing and Tech Agencies"
                      desc="Growing agencies who want to excel by hiring experienced outsource agency partner who can take of there Digital marketing projects and campaigns so they can take care of there clients and increase revenue."
                      image={image1}
                      path="/"
                    />
                    <SectorCard
                      title="E-Commerce"
                      desc="A great product is sold when the marketing is right. Adeptd professionals have established revenue generating digital marketing plan that drives guaranteed sales through your website."
                      image={image2}
                      path="/"
                    />
                  </div>

                  <div className={classes.Card}>
                    {/* <motion.p variants={card1}> */}
                    {/* <Card className={classes.cardBody}>
                  <CardActionArea>
                    <CardContent className={classes.cardBodyTwo}>
                      <Typography className={classes.Cardtitle}>
                        CPA & Law firms
                      </Typography>
                      <Typography className={classes.desc}>
                        i. Adeptd is the seasoned marketer and tech solution
                        provider to Tax, Accounting and CPA law firm with proven
                        digital marketing growth hacks curated by the certified
                        tax professionals and our team together that drives
                        guaranteed business qualified leads to your websites..
                      </Typography>
                      <img src={image5} className={classes.image} alt="img" />
                    </CardContent>
                  </CardActionArea>
                </Card> */}
                    <div className={classes.CardCenter}>
                      <SectorCard
                        title="For Accounting Firms"
                        desc="Adeptd is the seasoned marketer and tech solution provider to Tax, Accounting and CPA firm with proven digital marketing growth hacks curated by the certified tax professionals and our team together that drives guaranteed business qualified leads to your websites."
                        image={image5}
                        path="cpamarketing"
                      />
                    </div>
                    {/* </motion.p> */}
                  </div>

                  <div className={classes.Card}>
                    <SectorCard
                      title="Automobile"
                      desc="Are you struggling and have spent a huge budget calling out interested customers to your car showroom? Adeptd know your local competition and understand what a local car buyer is searching for or looking on the internet. With the help of our hyperlocal performance marketing and organic growth plans can help you grab them."
                      image={image4}
                      path="/"
                    />
                    <SectorCard
                      title="Service Providers"
                      desc="Services are not easy to sell but it is not impossible. Adeptd is proud on there expert skills and proven performance marketing expertise that help any service based businesses grab In-bound marketing qualified leads through digital channels instantly."
                      image={image3}
                      path="/"
                    />
                  </div>
                </div>
              </motion.p>
            </motion.div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Sectors;
