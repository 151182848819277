import google from "../images/listicons/business.svg";
import yahoo from "../images/listicons/yahoo.png";
import bing from "../images/listicons/bing.png";
import duck from "../images/listicons/duck.png";
import googleAd from "../images/listicons/googleAd.png";
import instaAd from "../images/listicons/instaAd.png";
import fbAd from "../images/listicons/fbAd.png";
import linkdinAd from "../images/listicons/linkdinAd.png";
import tiktokAd from "../images/listicons/tiktokAd.png";
import wordpress from "../images/listicons/wordpress.png";
import shopify from "../images/listicons/whiteshopify.svg";
import wooCom from "../images/listicons/wooCom.png";
import php from "../images/listicons/php.png";
import samerush from "../images/samerush.png"
import react from "../images/listicons/reactjs.svg";


export const tabData = [
  {
    id: 1,
    name: "My website isn’t getting enough traffic!",
  },
  {
    id: 2,
    name: "My advertising campaigns aren't generating quality leads.",
  },
  {
    id: 3,
    name: "I don’t know what my website should look like. Should I develop a new one or renew the existing one?",
  },
];

export const tabAboutData = [
  {
    id: 1,
    name: "My website isn’t getting enough traffic!",
  },
  {
    id: 2,
    name: "My advertising campaigns aren't generating quality leads.",
  },
  {
    id: 3,
    name: "My Website Doesn't look professional or is it outdated? Can I have a website that stands out in my Industry?",
  },
];
export const rightData = [
  {
    id: 1,
    title: "Search Engine Optimization & Content Marketing",
    desc: "Without reliable site traffic, you lose out on profitable customers, leads, and sales. Fortunately, unique SEO strategies can help you get back on track by increasing your visibility on search engine results, allowing more people to locate and visit your website.",
    list: [
      {
        title: "SEO Specific Content Gap analysis",
      },
      {
        title: "Keyword Targeted Content Writing",
      },
      {
        title: "Local SEO",
      },
      {
        title: "Youtube SEO",
      },
      {
        title: "Landing Page SEO",
      },
      {
        title: "PR and Guest Posts",
      },
      {
        title: "SEO-Specific Website Tech Audit & Implementation",
      },
      {
        title: "Backlinks Creation",
      },
      {
        title: "Website Blog Recommendations and Writing",
      },

    ],
    iconList: [
      {
        icon: google,
        wid: false
      },
      {
        icon: yahoo,
        wid: true
      },
      {
        icon: bing,
        wid: true
      },
      {
        icon: duck,
      },
      {
        icon: samerush
      }
    ],
  },
  {
    id: 2,
    title: "All Channels | In-bound Lead Generating Funnel Creation",
    desc: "Spending money won't help your Ads reach the right audience that is looking for your solution! Your sector is very competitive and you need your industry-specific paid ad professionals. Adept look into the loopholes and precise audience targeting by mapping the appropriate keywords, behaviors, and other attributes that drive high-quality leads to your business just in a week!",
    list: [
      {
        title: "Facebook and Google Ads campaigns",
      },
      {
        title: "E-commerce Performance Marketing Ad Campaign Plan",
      },
      {
        title: "Lead Generation/ Service Industry Ad Campaign plan",
      },
      {
        title: "Digital Inbound Lead Generation funnel",
      },
      {
        title: "Existing Ad Account Audit and Planning",
      },
      {
        title: "Paid Marketing A/B Testing",
      },
      {
        title: "Display and Facebook Ad creatives",
      },
      {
        title: "Motion Graphics Ad Creatives",
      },
    ],
    iconList: [
      {
        icon: fbAd,
      },
      {
        icon: instaAd,
      },
      {
        icon: googleAd,
      },
      {
        icon: linkdinAd,
      },
      {
        icon: tiktokAd,
      },
    ],
  },
  {
    id: 3,
    title: "Industry-Driven Website Design and Development",
    desc: "Your product or service needs to be showcased in the right way! Website is your brand's digital front and reflects your brand image thus our award-winning designers, developers, and digital brand strategists collaborate to bring out an exceptional website for you.",
    list: [
      {
        title: "Custom Made Design",
      },
      {
        title: "Unique Copywriting and designing",
      },
      {
        title: "Search Engine Optimized and Responsive Web Development",
      },
      {
        title: "Website Migrations",
      },
      {
        title: "Converting Landing Page Development",
      },
      {
        title: "Complimentary Web Maintenance",
      },
      {
        title: <p>&nbsp;</p>,
        icon: false
      },
      {
        title: <p>&nbsp;</p>,
        icon: false
      }
    ],
    iconList: [
      {
        icon: wordpress,
      },
      {
        icon: shopify,
        wid: true
      },
      {
        icon: wooCom,
      },
      {
        icon: php,
        wid: true
      },
      {
        icon: react,

      },
    ],
  },
];

export const rightAboutData = [
  {
    id: 1,
    title: "SEO | Law Firm Organic growth Solution",
    desc: "Without reliable site traffic, you lose out on profitable customers, leads, and sales. Fortunately, unique SEO strategies can help you get back on track by increasing your visibility in search engine results, allowing more people to locate and visit your website.",
    list: [
      {
        title:
          "National and local website Visibility and keyword ranking on industry terms based on Tax representation, IRS problems, bookkeeping, etc.",
      },
      {
        title: "Capturing organic leads",
      },
      {
        title: "SEO Specific Content Writing",
      },
      {
        title: "Tax and resolution industry-specific backlinks and PR",
      },
      {
        title: <p>&nbsp;</p>,
        icon: false
      },
      {
        title: <p>&nbsp;</p>,
        icon: false
      },
      {
        title: <p>&nbsp;</p>,
        icon: false
      },
      {
        title: <p>&nbsp;</p>,
        icon: false
      },
    ],
    iconList: [
      {
        icon: google,
        wid: false
      },
      {
        icon: yahoo,
        wid: true
      },
      {
        icon: bing,
        wid: true
      },
      {
        icon: duck,
      },
      {
        icon: samerush
      }
    ],
  },
  {
    id: 2,
    title: "Performance Marketing Ads | Proven Lead Funnel Establishment",
    desc: "Spending money won't help your advertisements reach the right customers that need your solution! The tax resolution sector is well-known to our paid ad professionals, who target the precise audience by mapping the appropriate keywords, behaviors, and other attributes that drive high-quality leads to your doors.",
    list: [
      {
        title:
          "Facebook and Google Ad campaigns specifically designed for CPA, Law, and Tax firms",
      },
      {
        title:
          "Inbound lead generation flow creation through paid advertisement",
      },
      {
        title: "Lead automation & marketing funnel setup on leading CRMs like Zoho, HubSpot and Salesforce",
      },
      {
        title: <p>&nbsp;</p>,
        icon: false
      },
      {
        title: <p>&nbsp;</p>,
        icon: false
      },
      {
        title: <p>&nbsp;</p>,
        icon: false
      },
      
    ],
    iconList: [
      {
        icon: fbAd,
      },
      {
        icon: instaAd,
      },
      {
        icon: googleAd,
      },
      {
        icon: linkdinAd,
      },
      {
        icon: tiktokAd,
      },
    ],
  },
  {
    id: 3,
    title: "Tax Industry-Based Website and Landing Pages",
    desc: "Tax solutions are a need of every citizen and thus it needs to be showcased in the right way! Website is your brand's digital front and reflects your brand image thus our award-winning designers, developers, and digital brand strategists collaborate to bring out an exceptional website for you.",
    list: [
      {
        title: "Custom Made Design",
      },
      {
        title: "Unique Copywriting and designing",
      },
      {
        title: "Search Engine Optimized and Responsive Web Development",
      },
      {
        title: "Website Migrations",
      },
      {
        title: "Converting Landing Page Development",
      },
      {
        title: "Complimentary Web Maintenance",
      },
      {
        title: <p>&nbsp;</p>,
        icon: false
      },
    ],
    iconList: [
      {
        icon: wordpress,
      },
      {
        icon: shopify,
        wid: true
      },
      {
        icon: wooCom,
      },
      {
        icon: php,
        wid: true
      },
      {
        icon: react,

      },
    ],
  },
];
