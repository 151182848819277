import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import thankYou from '../../../images/thankyou.svg'

const useStyles = makeStyles((theme) => ({
    right: {
        width: "47%",
        display: 'flex',
        [theme.breakpoints.between("xs", "sm")]: {
            width: '100%'
        }

    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        width: '88px',
        height: '88px',
        textAlign: 'center !important',
        margin: '10px 0px'
    },
    title: {
        fontFamily: 'Poppins !important',
        fontWeight: '600 !important',
        fontSize: '32px !important',
        color: '#FFFFFF !important',
        textAlign: 'center !important',
        margin: '20px 0px !important'
    },
    desc: {
        fontFamily: 'Poppins !important',
        fontWeight: '500 !important',
        fontSize: '22px !important',
        color: '#FFFFFF !important',
        textAlign: 'center !important',
        margin: '20px 0px !important'
    },
    mail: {
        fontFamily: 'Poppins !important',
        fontWeight: '500 !important',
        fontSize: '20px !important',
        color: '#179446 !important',
        margin: '20px 0px !important',
        textDecoration: 'underline !important',
        cursor: "pointer !important"
    }
}))
const ThankYou = () => {
    const classes = useStyles()
    const handleMail = (e) => {
        window.location.href = "mailto:hello@adeptd.com";
        e.preventDefault();
    }
    return (
        <div className={classes.right}>
            <div className={classes.content}>
                <img src={thankYou} alt="thanku" className={classes.logo} />
                <Typography className={classes.title}>Thank you for contacting us!</Typography>
                <Typography className={classes.desc}>You can expect our reply within 24 hours or you can reach out to us on <span className={classes.mail} onClick={handleMail}>hello@adeptd.com</span> anytime.</Typography>
            </div>
        </div>
    )
}

export default ThankYou