import React from "react";
import { makeStyles } from "@mui/styles";
import Heading from "../common/Heading";
import { Container, createTheme, useMediaQuery } from "@mui/material";
import GrowthCompo from "./GrowthCompo";
import GrowCard from "./GrowCard";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "100px",
    [theme.breakpoints.only("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  first: {
    display: "flex",
    flexDirection: "column",
  },
  allcardBody: {
    display: "flex",
    width: "100%",
    justifyContent: "Center",
    marginTop: "50px",
    marginBottom: "50px",
  },
  allcardBody2: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
}));
const WeGrow = () => {
  const classes = useStyles();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const text = {
    offscreen: { scale: 0.5, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2.5 },
    },
  };
  const first = {
    offscreen: { x: -50, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 1 },
    },
  };
  const second = isMobile
    ? {
        offscreen: { x: -50, opacity: 0 },
        onscreen: {
          y: 0,
          x: 0,
          opacity: 1,
          transition: { type: "spring", bounce: 0.4, duration: 1 },
        },
      }
    : {
        offscreen: { scale: 0.5, opacity: 0 },
        onscreen: {
          y: 0,
          x: 0,
          scale: 1,
          opacity: 1,
          transition: { type: "spring", bounce: 0.4, duration: 1 },
        },
      };
  const third = isMobile
    ? {
        offscreen: { x: -50, opacity: 0 },
        onscreen: {
          y: 0,
          x: 0,
          scale: 1,
          opacity: 1,
          transition: { type: "spring", bounce: 0.4, duration: 1 },
        },
      }
    : {
        offscreen: { x: 50, opacity: 0 },
        onscreen: {
          y: 0,
          x: 0,
          scale: 1,
          opacity: 1,
          transition: { type: "spring", bounce: 0.4, duration: 1 },
        },
      };
  return (
    <>
      <div>
        {/* <motion.p variants={text}> */}
        <Heading
          firstTitle=""
          secondTitle="Highlights"
          thirdTitle="The reason businesses trust Adeptd!"
        />
        {/* </motion.p> */}
        <Container maxWidth="lg">
          <div className={classes.main}>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={first}>
                <div className={classes.first}>
                  <GrowthCompo
                    title="2"
                    sign="X"
                    desc="Average ROI Calculated"
                  />
                  <GrowthCompo
                    title="30"
                    sign="+"
                    desc="Amazing Websites Delivered"
                  />
                  <GrowthCompo
                    title="10"
                    sign="+"
                    desc="Year's Varied Marketing Experience"
                  />
                </div>
              </motion.p>
            </motion.div>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={second}>
                <div className={classes.first}>
                  <GrowthCompo title="30" sign="+" desc="Projects Delivered" />
                  <GrowthCompo
                    title="10"
                    sign="+"
                    desc="New Brand Established"
                  />
                  <GrowthCompo
                    title="20"
                    sign="+"
                    desc="Year's Combined Marketing & Tech Experience"
                  />
                </div>
              </motion.p>
            </motion.div>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={third}>
                <div className={classes.first}>
                  <GrowthCompo title="96%" sign="%" desc="Client Retention" />
                  <GrowthCompo title="3" sign="+" desc="Country Partners" />
                  <GrowthCompo
                    title="4k+"
                    sign="k+"
                    desc="Marketing Qualified Leads Delivered"
                  />
                </div>
              </motion.p>
            </motion.div>
          </div>
        </Container>
        {/* <div className={classes.allcardBody}>
          <div className={classes.allcardBody2}>
            <div className="slider_wegrow">
              <ul>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
                <li>
                  <GrowCard />
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default WeGrow;
