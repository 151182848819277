import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const useStyle=makeStyles((theme)=>({
    mainBanner:{
        width:'100%',
        position:'fixed',
        background:'white',
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        zIndex:'999',
        marginBottom:'20px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    text:{
        color:"#179446 !important",
        fontSize:'15px !important',
        fontFamily:"Poppins !important",
        fontWeight:"400px !important",
        paddingTop:"10px !important",
        userSelect:"none !important",
        [theme.breakpoints.only('xs')]:{
            fontSize:"10px !important"
        },
        [theme.breakpoints.down('sm')]:{
            fontSize:"9px !important"
        }
    },
    textAlert:{
        color:"red !important",
        fontSize:'15px !important',
        fontFamily:"Poppins !important",
        fontWeight:"400px !important",
        paddingTop:"10px !important",
        userSelect:"none !important",
        [theme.breakpoints.only('xs')]:{
            fontSize:"10px !important",
            margin:"0 !important"
        },
        [theme.breakpoints.down('sm')]:{
            fontSize:"9px !important"
        }
    },
    link:{
        color:'#3A3A40 !important',
        fontFamily:"Poppins !important",
        fontWeight:"700 !important",
        cursor:"pointer",
        marginLeft:'5px',
        [theme.breakpoints.only('xs')]:{
            fontSize:'10px !important',
            padding:"5px 5px !important",
            textAlign:'center !important',
            cursor:"pointer",
        },
        [theme.breakpoints.down('sm')]:{
            fontSize:"9px !important",
            cursor:"pointer",
            marginLeft:"0px !important",
            padding:"5px 5px !important"
        }
        


    }
}))

const FreezeBanner = () => {
const classes=useStyle()
const navigate=useNavigate()
  return (
    <div className={classes.mainBanner}>
        <div className='d-flex align-items-center justify-content-center p-2 gap-1'>
        <p className={`${classes.textAlert} text-danger animate__animated animate__pulse animate__infinite	infinite m-0`}>Alert </p><p className={classes.text} style={{margin:0}}>| Protect Yourself from the Telegram Scam! <span onClick={()=>navigate("/protect-against-fraud")} style={{textDecoration:"underline"}} className={classes.link}>Learn More Here!</span></p>
        </div>
    </div>
  )
}

export default FreezeBanner