import { Button, Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";

const useStyles = makeStyles((theme) => ({
  btn: {
    position: "fixed !important",
    bottom: "50px !important",
    right: "40px !important",
    textTransform: "none !important",
    backgroundColor: "#179446 !important",
    color: "white !important",
  },
}));
const TopButton = () => {
  const classes = useStyles();
  const handleSroll = () => {
    window.scrollTo(0, 0);
  };
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [setScrollY]);

  const scrolled = () => scrollY > 100;
  return (
    <>
      {scrolled() ? (
        <Fab className={`${classes.btn} fixedBtn`} onClick={handleSroll}>
          <ArrowUpwardRoundedIcon />
        </Fab>
      ) : (
        ""
      )}
    </>
  );
};

export default TopButton;
