import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import img from "../../images/visionimg.jpg";
import VisionCard from "./VisionCard";
import img1 from "../../images/visioncardimg1.svg";
import img2 from "../../images/visioncardimg1border.svg";
import img3 from "../../images/visioncardimg2.svg";
import img4 from "../../images/visioncardimg2border.svg";
import img5 from "../../images/visioncardimg3.svg";
import img6 from "../../images/visioncardimg3border.svg";
import img7 from "../../images/visioncardimg4.svg";
import img8 from "../../images/visioncardimg4border.svg";
import img9 from "../../images/visioncardimg5.svg";
import img10 from "../../images/visioncardimg5border.svg";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "100px",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.only("sm")]: {
      flexDirection: "column",
    },
  },
  left: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      textAlign: "center",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  right: {
    width: "48%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      marginTop: "40px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      marginTop: "40px",
    },
  },
  heading: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "50px !important",
    color: "#179446 !important",
    fontWeight: "700 !important",
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    color: "#3a3a3a !important",
    fontWeight: "500 !important",
    marginTop: "30px !important",
  },
  desc2: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    color: "#3a3a3a !important",
    fontWeight: "500 !important",
    marginTop: "30px !important",
  },
  bottomdiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "80px",
    marginBottom: "50px",
  },
  title: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    color: "#3a3a3a !important",
    fontWeight: "600 !important",
    textAlign: "center !important",
  },
  title2: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    color: "#3a3a3a !important",
    fontWeight: "500 !important",
    textAlign: "center !important",
  },
  cardbody: {
    marginTop: "100px",
  },
  centerCard: {
    display: "flex",
    justifyContent: "center",
  },
}));
const OurVision = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const textAnimate = {
    offscreen: { x: -200, opacity: 0 },
    onscreen: {
      y: 0,
      scale: 1,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const textAnimate2 = {
    offscreen: { scale: 0, opacity: 0 },
    onscreen: {
      y: 0,
      scale: 1,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const image = {
    offscreen: { opacity: 0 },
    onscreen: {
      y: 0,
      scale: 1,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.main}>
          <div className={classes.left}>
            <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
              {visible ? (
                <Typography className={classes.heading}>
                  <TypeAnimation
                    wrapper="span"
                    speed={12}
                    sequence={["Our Vision", 1000, "Our Vision", 2000]}
                    cursor={false}
                    repeat={Infinity}
                  />
                </Typography>
              ) : (
                <></>
              )}
            </ScrollTrigger>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={textAnimate}>
                <Typography className={classes.desc}>
                  We uphold ethical standards, thus our motto is to serve our
                  clients fairly and without making any false commitments thus
                  we evaluate and{" "}
                  <span style={{ fontWeight: "600" }}>
                    {" "}
                    develop a sustainable and lucrative digital marketing
                    strategy for you.
                  </span>
                </Typography>
              </motion.p>
            </motion.div>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={textAnimate}>
                <Typography className={classes.desc2}>
                  We are a young and innovative digital marketing company &nbsp;
                  <span style={{ fontWeight: "600" }}>
                    founded on performance, trustworthiness, and reliability!
                  </span>
                </Typography>
              </motion.p>
            </motion.div>
          </div>
          <div className={classes.right}>
            <img src={img} alt="image" />
          </div>
        </div>
        <div className={classes.bottomdiv}>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
          >
            <motion.p variants={textAnimate2}>
              <Typography className={classes.title}>
                With our expertise and unmatched service quality our clients
                <br /> are successfully been served with
              </Typography>
            </motion.p>
          </motion.div>
        </div>
        <div className={classes.cardbody}>
          <Grid container spacing={4}>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={classes.centerCard}
            >
              <VisionCard
                img2={img2}
                img={img1}
                title="Performance Marketing - Digital Advertising"
              />
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
              sm={6}
              md={4}
              className={classes.centerCard}
            >
              <VisionCard
                img={img3}
                img2={img4}
                title="Search Engine Optimization"
              />
            </Grid>
            <Grid
              item
              lg={4}
              xs={12}
              sm={6}
              md={4}
              className={classes.centerCard}
            >
              <VisionCard
                img={img5}
                img2={img6}
                title="Web & Mobile Development"
              />
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
              sm={6}
              spacing={4}
              md={6}
              // style={{ display: "flex", justifyContent: "flex-end" }}
              className={classes.centerCard}
            >
              <VisionCard
                img={img7}
                img2={img8}
                title="NFT Marketplace and 3.0 Web Development"
              />
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
              sm={12}
              md={6}
              spacing={4}
              // style={{ display: "flex", justifyContent: "flex-start" }}
              className={classes.centerCard}
            >
              <VisionCard
                img={img9}
                img2={img10}
                title="Branding and Creative Designing"
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.bottomdiv}>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
          >
            <motion.p variants={textAnimate2}>
              <Typography className={classes.title2}>
                We are enabling all global businesses to have a world-class
                outsourced digital
                <br /> marketing and technology team that supports them in
                achieving their core
                <br /> business goal by establishing the optimal digital
                marketing curve.
              </Typography>
            </motion.p>
          </motion.div>
        </div>
      </Container>
    </>
  );
};

export default OurVision;
