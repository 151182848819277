import { makeStyles } from "@mui/styles";
import React, { useContext } from "react";
import useMousePosition from "../hooks/useMousePosition";

const useStyles = makeStyles((theme) => ({
  ring: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "40px",
    height: "40px",
    border: "2px solid rgba(23, 148, 70, 1)",
    borderRadius: "100%",
    transform: "translate(-50%, -50%)",
    WebkitTransitionDuration: "100ms",
    transitionDuration: "100ms",
    WebkitTransitionTimingFunction: "ease-in-out",
    transitionTimingFunction: "ease-out",
    willChange: "width, height, transform, border",
    zIndex: 999,
    pointerEvents: "none",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  dot: {
    position: "fixed",
    top: "50%",
    left: "50%",
    width: "8px",
    height: "8px",
    backgroundColor: "black",
    borderRadius: "100%",
    transform: "translate(-50%, -50%)",
    zIndex: 999,
    pointerEvents: "none",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const DotRingCursor = () => {
  //const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  const { x, y } = useMousePosition();
  const classes = useStyles();
  return (
    <>
      <div
        style={{ left: `${x}px`, top: `${y}px` }}
        className={classes.ring}
      ></div>
      <div
        className={classes.dot}
        style={{ left: `${x}px`, top: `${y}px` }}
      ></div>
    </>
  );
};

export default DotRingCursor;
