import { ThemeProvider, useTheme } from "@mui/material";
import image from "../../images/ieltsbanner.png";
import React, { useEffect } from "react";
import Banner from "./common/Banner";
import Challenge from "./common/Challenge";
import Impact from "./common/Impact";
import icon from "../../images/check_circle.svg";
import Service2 from "./common/Service2";
import OurWork from "../common/work/OurWork";
import BackButton from "./common/BackButton";
import Feedback from "../home/Feedback";
import Dream from "../home/Dream";

const IELTS = () => {
  const theme = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <ThemeProvider theme={theme}>
          <BackButton />
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <Banner
            path="ielts"
            icon={icon}
            icon2={""}
            icon3={icon}
            icon4={icon}
            icon5={icon}
            greenheading="Our Clients"
            centerheading="India based IELTS exam preparation online tutoring platform! "
            desc="Performance Marketing"
            cardtitle="Objective"
            carddesc="Interested Student In-bound inquiry though Website "
            firstcarddesc1="Facebook"
            firstcardtitle="Marketing Channel"
            secondcardtitle="Achievement"
            secondcarddesc="234 Inquiry Raised within a month"
            thirdcardtitle="Service Provided"
            thirdcarddesc="Paid Marketing Ads"
            image={image}
          />
          <Service2 />
          <Challenge maintitle="Challenges" path="ielts" />
          <Impact maintitle="Impact" path="ielts" />
          <OurWork />
          <Dream />
          <Feedback />
          {/* <CardSlider /> */}
          {/* <WorkCard /> */}
        </ThemeProvider>
      </div>
    </>
  );
};

export default IELTS;
