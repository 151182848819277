import React from "react";
import { makeStyles } from "@mui/styles";
import { Container, createTheme, Typography, useMediaQuery } from "@mui/material";
import logo from "../images/footerLogo.svg";
import Flogo1 from "../images/footerlogos/footerlinked.svg";
import Flogo2 from "../images/footerlogos/footerinsta.svg";
import Flogo3 from "../images/footerlogos/FacebookBW.svg";
import Flogo4 from "../images/footerlogos/fclutch.svg";
import Flogo11 from "../images/footerlogos/Linkedin.svg";
import Flogo12 from "../images/footerlogos/Instagram.svg";
import Flogo13 from "../images/footerlogos/Facebook.svg";
import Flogo14 from "../images/footerlogos/footerclutch.svg";
import NewLogo from '../images/footerlogos/new.png'
import { NavLink } from "react-router-dom";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import india from "../images/in.svg";
import us from "../images/us.svg";
import logo2 from "../images/footerlogos/clutchfooter2.svg";
import logo3 from "../images/footerlogos/shopifyfooter.svg";
import logo4 from "../images/footerlogos/whyusfooter.svg";
import HoverImage from "react-hover-image/build";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    backgroundColor: "#F6F6F6",
    paddingTop: "50px",
    paddingBottom: "30px",
    display: "flex",
    marginTop: "50px",
  },
  Submain: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.only("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
  },
  first: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
  },
  desc: {
    fontFamily: "Poppins !important",
    color: "#3A3A3A !important",
    fontSize: "18px !important",
    marginTop: "30px !important",
  },
  logo: {
    width: "150px",
  },
  logodiv: {
    display: "flex",
    marginTop: "30px",
    alignItems: "center",
    width: "60%",
    justifyContent: "space-between",
  },
  Flogo: {
    marginRight: "10px",
    cursor: "pointer",
  },
  serviceDiv: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.only("sm")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: "40px",
    },
  },
  serviceTitle: {
    fontFamily: "Poppins !important",
    color: "#3A3A3A !important",
    fontSize: "22px !important",
    fontWeight: "600 !important",
  },
  menuDiv: {
    marginTop: "30px",
    [theme.breakpoints.only("sm")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.only("xs")]: {
      marginTop: "20px",
    },
  },
  servicemenu: {
    marginBottom: "10px !important",
    fontFamily: "Poppins !important",
    color: "#3A3A3A !important",
    fontSize: "18px !important",
  },
  copyRight: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    background:
      "linear-gradient(90deg, #179446 0%, #179446 0%, #89CA74 53.13%)",
  },
  copyRightTitle: {
    fontFamily: "Poppins !important",
    color: "white !important",
    fontSize: "17px !important",
  },
  left: {
    width: "45%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      justifyContent: "space-around",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  right: {
    width: "55%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-end",
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      justifyContent: "center",
      marginTop: "40px",
      alignItems: "center",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      justifyContent: "center",
      marginTop: "40px",
      alignItems: "center",
    },
  },
  text: {
    [theme.breakpoints.down("md")]: {
      fontSize: "80px",
    },
  },
  addressDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    // backgroundColor: "#159346",
    padding: "10px 10px",
    alignItems: "center",
    color: "#ffffff",
    border: "2px solid #159346",
    borderRadius: "7px !important",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
  },
  address: {
    display: "flex",
    marginBottom: "10px",
  },
  add: {
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    color: "#3a3a3a !important",
  },
  emailcard: {
    fontFamily: "Poppins !important",
    color: "#3a3a3a !important",
    fontSize: "18px !important",
    border: "2px solid #159346",
    // backgroundColor: "rgba(21, 147, 70) !important",
    display: "inline !important",
    padding: "3px 20px !important",
    borderRadius: "7px !important",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
  },
  logodiv2: {
    display: "flex",
    alignItems: "center",
    width: "80%",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  logos: {
    [theme.breakpoints.down("sm")]: {
      width: "60px",
    },
    logosNew:{
     
      [theme.breakpoints.down("sm")]: {
        width: "60px",
      }
    }
  },
}));

const Footer = () => {
  const openInNewTab = (url) => {
    // 👇️ setting target to _blank with window.open
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const classes = useStyles();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"))
  return (
    <>
      <div className={classes.main}>
        <Container maxWidth="lg">
          <div className={classes.Submain}>
            <div className={classes.left}>
              <div className={classes.first}>
                <NavLink
                  style={{ textDecoration: "none" }}
                  to={`${process.env.PUBLIC_URL}/`}
                >
                  <img src={logo} className={classes.logo} alt="logo" />
                </NavLink>

                <div style={isMobile ? { width: '150%' } : {
                  width: '100%'
                }}>
                  <Typography className={classes.desc}>
                    We are shaping tough businesses <br />
                    Worldwide through our unique
                    <br />
                    Digital Marketing Solutions!
                  </Typography>
                </div>
                <div>
                  <div style={{ marginTop: "30px" }}>
                    <Typography className={classes.emailcard}>
                      hello@adeptd.com
                    </Typography>
                  </div>
                </div>
                <div className={classes.addressDiv}>
                  <div className={classes.address}>
                    <LocationOnRoundedIcon
                      style={{
                        width: "20px",
                        marginRight: "10px",
                        color: "#3a3a3a",
                      }}
                    />
                    <img
                      src={india}
                      style={{
                        width: "25px",
                        height: "15px",
                        marginTop: "5px",
                        marginRight: "8px",
                      }}
                    />
                    <Typography className={classes.add}>
                      <span style={{ fontWeight: "700" }}>India :</span> G-20,
                      Emerald Tower, Udaipur, Rajasthan, 313001
                    </Typography>
                  </div>
                  <div className={classes.address}>
                    <LocationOnRoundedIcon
                      style={{
                        width: "20px",
                        marginRight: "10px",
                        color: "#3a3a3a",
                      }}
                    />
                    <img
                      src={us}
                      style={{
                        width: "25px",
                        height: "15px",
                        marginTop: "5px",
                        marginRight: "8px",
                      }}
                    />
                    <Typography className={classes.add}>
                      <span style={{ fontWeight: "700" }}>U.S :</span> 5325
                      Yorktown Blvd. Corpus Christi, Texas 78413
                    </Typography>
                  </div>
                </div>
                <div className={classes.logodiv}>
                  <HoverImage
                    src={Flogo1}
                    hoverSrc={Flogo11}
                    className="fab4"
                    onClick={() =>
                      openInNewTab("https://www.linkedin.com/company/adeptd/")
                    }
                  />
                  <HoverImage
                    src={Flogo2}
                    hoverSrc={Flogo12}
                    className="fab4"
                    onClick={() =>
                      openInNewTab(
                        "https://instagram.com/adeptd_media?igshid=YmMyMTA2M2Y="
                      )
                    }
                  />
                  <HoverImage
                    src={Flogo3}
                    hoverSrc={Flogo13}
                    // className="fab4"
                    onClick={() =>
                      openInNewTab("https://www.facebook.com/adeptdus")
                    }
                    style={{ width: "37px", cursor: "pointer" }}
                  />
                  <HoverImage
                    src={Flogo4}
                    hoverSrc={Flogo14}
                    className="fab4"
                    onClick={() =>
                      openInNewTab("https://clutch.co/profile/adeptd")
                    }
                  />
                </div>
              </div>
              {/* <div className={classes.serviceDiv}>
              <Typography className={classes.serviceTitle}>Services</Typography>
              <div className={classes.menuDiv}>
                <Typography className={classes.servicemenu}>SEO</Typography>
                <Typography className={classes.servicemenu}>
                  Social Media Marketing
                </Typography>
                <Typography className={classes.servicemenu}>
                  Web Development
                </Typography>
                <Typography className={classes.servicemenu}>
                  App Development
                </Typography>
                <Typography className={classes.servicemenu}>
                  Zoho Setup
                </Typography>
                <Typography className={classes.servicemenu}>
                  Pixel Setup
                </Typography>
                <Typography className={classes.servicemenu}>
                  NFT Market Place
                </Typography>
                <Typography className={classes.servicemenu}>Web 3.0</Typography>
                <Typography className={classes.servicemenu}>
                  Blockchain
                </Typography>
              </div>
            </div> */}
              <div className={classes.serviceDiv}>
                <Typography className={classes.serviceTitle}>
                  Resources
                </Typography>
                <div className={classes.menuDiv}>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to={`/whyus`}
                  >
                    <Typography className={classes.servicemenu}>
                      Why Us
                    </Typography>
                  </NavLink>
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to={`/cpamarketing`}
                  >
                    <Typography className={classes.servicemenu}>
                      CPA & Law Firm <br />
                      Marketing
                    </Typography>
                  </NavLink>
                  {/* <NavLink
                    style={{ textDecoration: "none" }}
                    to={`${process.env.PUBLIC_URL}/ourwork`}
                  >
                    <Typography className={classes.servicemenu}>
                      Our Work
                    </Typography>
                  </NavLink> */}
                  <NavLink
                    style={{ textDecoration: "none" }}
                    to={`/contactus`}
                  >
                    <Typography className={classes.servicemenu}>
                      Contact US
                    </Typography>
                  </NavLink>
                  {/* <Typography className={classes.servicemenu}>
                    How it Works
                  </Typography> */}
                </div>
              </div>
              {/* <div className={classes.serviceDiv}>
              <Typography className={classes.serviceTitle}>
                Resources
              </Typography>
              <div className={classes.menuDiv}>
                <Typography className={classes.servicemenu}>Clients</Typography>
                <Typography className={classes.servicemenu}>
                  Testimonials
                </Typography>
                <Typography className={classes.servicemenu}>
                  Portfolio
                </Typography>
              </div>
            </div> */}
            </div>
            <div className={classes.right}>
              <NavLink to={`${process.env.PUBLIC_URL}/contactus`}>
                <h3 className={`${classes.text} animate-charcter`}>
                  Let's Talk
                </h3>
              </NavLink>
              <div className={classes.logodiv2} style={{}}>
                {/* <img src={logo1} className={classes.logos} /> */}
                <img src={logo2} className={classes.logos} />
                <img src={logo3} className={classes.logos} />
                <img src={logo4} className={classes.logos} />
                <img src={NewLogo} className={classes.logosNew} style={{ width:"150px",height:'auto',objectFit:'cover',cursor:'pointer',}}  onClick={()=> window.open("https://www.designrush.com/trends", "_blank")}/>

              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className={classes.copyRight}>
        <Typography className={classes.copyRightTitle}>
          {new Date().getFullYear()} © Adeptd | Privacy Policy | Terms and Conditions
        </Typography>
      </div>
    </>
  );
};

export default Footer;
