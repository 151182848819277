import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import icon from "../../images/sliderICON.svg";
import { makeStyles } from "@mui/styles";

const marks = [
  {
    value: 0,
    label: "10-20",
  },
  {
    value: 25,
    label: "20-50",
  },
  {
    value: 50,
    label: "50-80",
  },
  {
    value: 75,
    label: "100-120",
  },
  {
    value: 100,
    label: "150+",
  },
];
const useStyle = makeStyles((theme) => ({
  root: {
    width: 180,
  },
  slider: {
    width: "550px",
    [theme.breakpoints.only("sm")]: {
      width: "450px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "250px",
    },
  },
}));
const SuccessSlider = styled(Slider)(({ theme }) => ({
  color: "#3330E4",
  "& .MuiSlider-thumb": {
    backgroundImage: `url(${icon}) !important`,
    backgroundRepeat: "no-repeat !important;",
    backgroundColor: "transparent !important",
    borderRadius: "0 !important",
    width: "30px !important",
    height: "30px !important",
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `none !important`,
    },
    "&.Mui-active": {
      boxShadow: `none !important`,
    },
    "&:before": {
      boxShadow: `none !important`,
    },
  },
}));

export default function FormSlider() {
  const classes = useStyle();
  return (
    <Box className={classes.slider}>
      <SuccessSlider
        defaultValue={0}
        step={25}
        classes={classes.rootSlider}
        marks={marks}
      />
    </Box>
  );
}
