import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import image from "../../images/profilepic.svg";
import image2 from "../../images/reviewClutch.png";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    display: "flex",
    flexDirection: "column",
    width: "320px !important",
    height: "320px !important",
    borderRadius: "11px !important",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.16) !important",
  },
  main: {
    width: "100% !important",
    height: "100% !important",
    marginRight: "100px ",
  },
  first: {
    display: "flex",
    marginTop: "20px",
    marginBottom: "30px",
  },
  titleBody: {
    position: "relative",
    bottom: "0",
    transform: "translate(-44%, -704%)",
    width: "40px",
    borderRadius: "50%",
    height: "40px",
  },
  image: {
    position: "absolute",
    // bottom: "0",
    // transform: "translate(283%, -131%)",
    top: "17px",
    right: "17px",
  },
  titleBody2: {
    marginLeft: "15px",
  },
  title: {
    fontFamily: "Poppins !important",
    fontSize: "18px !important",
    fontWeight: "500 !important",
    color: "#3A3A3A !important",
  },
  title2: {
    fontFamily: "Poppins !important",
    fontSize: "12px !important",
    fontWeight: "400 !important",
    color: "#3A3A3A !important",
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    color: "#767676 !important",
  },
}));
const GrowCard = ({ owner, profile, title, desc }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.main}>
        <Card className={classes.cardBody}>
          <CardActionArea style={{ width: "100%" }}>
            <CardContent className={classes.cardBodytwo}>
              <div className={classes.first}>
                <div className={classes.titleBody2}>
                  <Typography className={classes.title}>{owner}</Typography>
                  <div>
                    <img src={image2} className={classes.image} alt="new" />
                  </div>
                  <Typography className={classes.title2}>{title}</Typography>
                </div>
              </div>
              <div className={classes.desc}>{desc}</div>
            </CardContent>
          </CardActionArea>
        </Card>
        <div>
          <img src={profile} className={classes.titleBody} alt="body" />
        </div>
      </div>
    </>
  );
};

export default GrowCard;
