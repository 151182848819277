import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import OurUSPCard from "./OurUSPCard";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import { motion, Variants } from "framer-motion";
import AnimatedText from "react-animated-text-content";
import icon1 from "../../images/UspCardIcn1.svg";
import icon2 from "../../images/UspCardIcn2.svg";
import icon3 from "../../images/UspCardIcn3.svg";
import icon4 from "../../images/UspCardIcn4.svg";
import icon5 from "../../images/UspCardIcn5.svg";
import bg from "../../images/backgroundUSP.svg";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    paddingTop: "80px",
    paddingBottom: "80px",
    backgroundImage: `url(${bg})`,
    backgroundRepeat: "repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  headingdiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  greenheadingdiv: {
    textAlign: "left",
    width: "65%",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "90%",
    },
  },
  centerheadingdiv: {
    textAlign: "center",
  },
  descheadingdiv: {
    textAlign: "center",
    marginTop: "20px",
  },
  greenheading: {
    fontFamily: "Nothing you could do !important",
    fontSize: "50px !important",
    color: "#179446 !important",
    fontWeight: "700 !important",
    // marginLeft: "300px !important",
    textAlign: "center !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
      marginLeft: "70px!important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px !important",
    },
  },
  centerheading: {
    fontFamily: "Poppins !important",
    fontSize: "50px !important",
    color: "#3A3A3A !important",
    fontWeight: "700 !important",
    marginTop: "-20px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px !important",
    },
  },
  descheading: {
    fontFamily: "Poppins !important",
    fontSize: "21px !important",
    color: "#3A3A3A !important",
    fontWeight: "500 !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "22px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "22px !important",
    },
  },
  carddiv: {
    display: "flex",
    flexDirection: "column",
    marginTop: "60px",
  },
  cardleft: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  cardright: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  aeroBody2: {
    marginTop: "-26px",
    marginBottom: "-30px",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      width: "25%",
    },
  },
  firstAero: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    // marginLeft: "180px !important",
    transform: "rotate(322deg)",
    textAlign: "center !important",
    [theme.breakpoints.only("xs")]: {
      textAlign: "right !important",
    },
  },
}));
const OurUSP = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const text = {
    offscreen: { scale: 0.5, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2.5 },
    },
  };
  const textAnimate2 = {
    offscreen: { x: -100, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const cardleft = {
    offscreen: { x: -100, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const cardright = isMobile
    ? {
        offscreen: { x: -100, opacity: 0 },
        onscreen: {
          y: 0,
          x: 0,
          opacity: 1,
          transition: { type: "spring", bounce: 0.4, duration: 3 },
        },
      }
    : {
        offscreen: { x: 100, opacity: 0 },
        onscreen: {
          y: 0,
          x: 0,
          opacity: 1,
          transition: { type: "spring", bounce: 0.4, duration: 3 },
        },
      };
  return (
    <>
      <div className={classes.main}>
        <Container fixed>
          <div className={classes.headingdiv}>
            <div className={classes.greenheadingdiv}>
              <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
                {visible ? (
                  <Typography className={classes.greenheading}>
                    <TypeAnimation
                      repeat={Infinity}
                      sequence={["Unique", 2000]}
                      wrapper="div"
                      cursor={false}
                    />
                  </Typography>
                ) : (
                  <Typography className={classes.greenheading}>
                    Unique
                  </Typography>
                )}
              </ScrollTrigger>
            </div>
            <div className={classes.aeroBody2}>
              <Typography className={`${classes.firstAero} floatingstraight`}>
                ^
              </Typography>
            </div>
            <div className={classes.centerheadingdiv}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={text}>
                  <Typography className={classes.centerheading}>
                    What is so &nbsp; about us?
                  </Typography>
                </motion.p>
              </motion.div>
            </div>
            <div className={classes.descheadingdiv}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                {/* <motion.p variants={textAnimate2}> */}
                {/* <Typography className={classes.descheading}> */}
                <AnimatedText
                  type="chars" // animate words or chars
                  animation={{
                    // x: "-100px",
                    // y: "-18px",
                    scale: 1,
                    ease: "ease",
                  }}
                  animationType="wave"
                  interval={0.02}
                  duration={0.3}
                  tag="p"
                  className={classes.descheading}
                  includeWhiteSpaces
                  threshold={0.1}
                  rootMargin="20%"
                >
                  We are Adeptd which stands for Digital proficiency delivered
                </AnimatedText>
                {/* </Typography> */}
                {/* </motion.p> */}
              </motion.div>
            </div>
          </div>
          <div className={classes.carddiv}>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={cardleft}>
                <div className={classes.cardleft}>
                  <OurUSPCard
                    icon={icon1}
                    title="40+ Law Firms Believe in us!"
                    desc="We are digital marketing and tech partners to individual CPAs and accounting firms who wanted to excel in their business through digital marketing techniques"
                  />
                </div>
              </motion.p>
            </motion.div>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={cardright}>
                <div className={classes.cardright}>
                  <OurUSPCard
                    icon={icon2}
                    title="4000+ Marketing Qualified Leads Delivered"
                    desc="We are a team of digital marketing experts with more than ten years of experience that created CPA industry-driven digital marketing strategy that has helped law firms attract high-end clients who are looking for them online."
                  />
                </div>
              </motion.p>
            </motion.div>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={cardleft}>
                <div className={classes.cardleft}>
                  <OurUSPCard
                    icon={icon3}
                    title="96% Partner Retention"
                    desc="Our tax industry partners have found their peace of mind with us! Our techniques and subject matter understanding make them comfortable with our marketing solutions."
                  />
                </div>
              </motion.p>
            </motion.div>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={cardright}>
                <div className={classes.cardright}>
                  <OurUSPCard
                    icon={icon4}
                    title="30+ Amazing Websites Delivered"
                    desc="We are digital architects and are concerned about your online reputation thus we curate your website design that stands out from the boring CPA website templates and makes your services and brand image stand out on the web."
                  />
                </div>
              </motion.p>
            </motion.div>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={cardleft}>
                <div className={classes.cardleft}>
                  <OurUSPCard
                    icon={icon5}
                    title="100% Success rate for All our Accounting industry Client"
                    desc="We are your subject matter expert giving more than 2X ROI to our client's businesses on their marketing spend because we know the exact digital marketing technique that works best."
                  />
                </div>
              </motion.p>
            </motion.div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default OurUSP;
