import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Container,
  createTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TypeAnimation } from "react-type-animation";
import ScrollTrigger from "react-scroll-trigger";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
  },
  firstheadingdiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  firstheading: {
    fontFamily: "Nothing you could do !important",
    fontSize: "52px !important",
    color: "#179446 !important",
    fontWeight: "700 !important",
    [theme.breakpoints.only("md")]: {
      fontSize: "44px!important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px!important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px!important",
    },
    [theme.breakpoints.between(571, 600)]: {
      marginLeft: "-40px !important",
    },
  },
  subdiv: {
    width: "95%",
  },
  centerheading: {
    fontFamily: "Poppins !important",
    fontSize: "52px !important",
    color: "#3A3A3A !important",
    fontWeight: "700 !important",
    [theme.breakpoints.only("md")]: {
      fontSize: "44px!important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px!important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px!important",
      marginBottom: "20px !important",
    },
  },
  centerheadingdiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "-60px",
  },
  aeroBody: {
    display: "flex",
    marginTop: "-35px",
    marginBottom: "0px",
    justifyContent: "space-around",
    width: "28%",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      justifyContent: "flex-start",
    },
  },
  firstAero: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    marginLeft: "30px !important",
    transform: "rotate(322deg)",
    [theme.breakpoints.between(571, 600)]: {
      marginLeft: "-18px !important",
    },
    [theme.breakpoints.between(473, 555)]: {
      marginLeft: "0 !important",
    },
  },
}));
const Bannerheading = ({ secondheading, firstheading, thirdheading }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const textAnimate3 = {
    offscreen: { scale: 0.5, opacity: 0 },
    onscreen: {
      scale: 1,
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <>
      <motion.div
        className={classes.main}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.5 }}
      >
        <Container maxWidth="lg">
          <div className={classes.firstheadingdiv}>
            <div className={classes.subdiv}>
              <Typography className={classes.firstheading}>
                <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
                  {visible ? (
                    <TypeAnimation
                      wrapper="span"
                      speed={45}
                      sequence={[firstheading, 1000, firstheading, 2000]}
                      cursor={false}
                      repeat={Infinity}
                    />
                  ) : (
                    <Typography className={classes.firstheading}>
                      {firstheading}
                    </Typography>
                  )}
                </ScrollTrigger>
              </Typography>
            </div>
          </div>
          <div className={classes.aeroBody}>
            <Typography
              className={
                isMobile
                  ? `${classes.firstAero} floatingmob`
                  : `${classes.firstAero} floating`
              }
            >
              ^
            </Typography>
          </div>
          <div className={classes.centerheadingdiv}>
            <motion.p variants={textAnimate3}>
              <Typography className={classes.centerheading}>
                {secondheading}
                <br />
                {thirdheading}
              </Typography>
            </motion.p>
          </div>
        </Container>
      </motion.div>
    </>
  );
};

export default Bannerheading;
