import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import ProcessSvg from "./ProcessSvg";
import { motion, Variants } from "framer-motion";
import AnimatedText from "react-animated-text-content";
import NewProcess from "./NewProcess";
import { NavLink } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
      marginTop: "100px",
    },
    [theme.breakpoints.only("sm")]: {
      marginTop: "0px",
    },
  },
  rightContent: {
    width: "50%",
    display: "flex",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      marginTop: "30px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      marginTop: "100px",
    },
  },
  leftContent: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      textAlign:'center'
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
  },
  pathTitle: {
    fill: "#3A3A3A",
    fontFamily: "Poppins !important",
    fontSize: "25px",
    marginLeft: "20px",
    width: "20px",
    fontWeight: "400",
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
  },
  workText: {
    fontFamily: "Poppins !important",
    fontSize: "18px !important",
    color: "#3A3A3A !important",
    fontWeight: "400 !important",
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "14px !important",
    },
  },
  title: {
    fontFamily: "Poppins !important",
    fontSize: "50px !important",
    color: "#3A3A3A !important",
    fontWeight: "700 !important",
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
      fontSize: "40px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "36px !important",
    },
  },
  processText: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "50px !important",
    color: "#179446 !important",
    fontWeight: "700 !important",
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
      fontSize: "40px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "36px !important",
    },
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "18px !important",
    color: "#3A3A3A !important",
    fontWeight: "400 !important",
    inlineSize: "400px",
    [theme.breakpoints.only("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "16px !important",
    },
  },
  btn: {
    textTransform: "capitalize !important",
    backgroundColor: "#159346 !important",
    borderRadius: "50px !important",
    color: "white !important",
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    padding: "10px 25px !important",
    marginTop: "30px !important",
    [theme.breakpoints.only("xs")]: {
      padding: "10px 20px !important",
      fontSize: "10px !important",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.only("sm")]: {
      padding: "10px 22px !important",
      fontSize: "12px !important",
    },
  },
}));
const Process = () => {
  const classes = useStyle();
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const text = {
    offscreen: { x: -100, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2.5 },
    },
  };
  return (
    <>
      <motion.div
        className={classes.root}
        initial={"offscreen"}
        whileInView={"onscreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.5 }}
      >
        <div className={classes.leftContent}>
          <div>
            {/* <Typography className={classes.workText}>Work Flow</Typography> */}
            <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
              {visible ? (
                <Typography className={classes.processText}>
                  <TypeAnimation
                    wrapper="span"
                    speed={12}
                    sequence={["Process", 1000, "Process", 2000]}
                    cursor={false}
                    repeat={Infinity}
                  />
                </Typography>
              ) : (
                <></>
              )}
            </ScrollTrigger>
            <motion.p variants={text}>
              <Typography className={classes.title}>How to Start?</Typography>
            </motion.p>
            {/* <motion.p variants={text}>
            <Typography className={classes.desc}> */}
            <AnimatedText
              type="chars" // animate words or chars
              animation={{
                // x: "-100px",
                // y: "-18px",
                scale: 1,
                ease: "ease",
              }}
              animationType="wave"
              interval={0.02}
              duration={0.3}
              tag="p"
              className={classes.desc}
              includeWhiteSpaces
              threshold={0.1}
              rootMargin="20%"
            >
              Our onboarding process is simple and customer-centric. We exercise
              intimate relations with your business and its objectives and then
              offer the best-suited digital marketing and tech solutions that
              defines powerful growth to the business.
            </AnimatedText>
            {/* </Typography>
          </motion.p> */}
            <NavLink
              to={`${process.env.PUBLIC_URL}/contactus`}
              style={{ textDecoration: "none" }}
            >
              <Button className={classes.btn}>Get In Touch</Button>
            </NavLink>
          </div>
        </div>
        <div className={classes.rightContent}>
          <NewProcess />
        </div>
      </motion.div>
    </>
  );
};

export default Process;
