import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Container, Grid, Typography } from "@mui/material";
import GrowthCompo from "../home/GrowthCompo";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import AnimatedText from "react-animated-text-content";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "100px",
  },
  heading: {
    fontFamily: "Nothing you could do !important",
    fontSize: "50px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    textAlign: "center!important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "42px !important",
    },
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    fontWeight: "500 !important",
    color: "#3a3a3a !important",
    textAlign: "center !important",
    inlineSize: "750px !important",
    textAlign: "center !important",
    marginTop: "40px !important",
    [theme.breakpoints.only("xs")]: {
      inlineSize: "auto !important",
    },
    [theme.breakpoints.only("sm")]: {
      inlineSize: "auto !important",
    },
  },
  first: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
  },
  count: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
    // [theme.breakpoints.only("xs")]: {
    //   flexDirection: "column",
    //   alignItems: "center",
    // },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  footer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  footerdiv: {
    borderRadius: "50px 0px 0px 50px",
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
  },
  title: {
    fontFamily: "Nothing you could do !important",
    fontSize: "30px !important",
    fontWeight: "600 !important",
    color: "#179446D9 !important",
  },
  left: {
    display: "flex",
    width: "50%",
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    // [theme.breakpoints.only("lg")]: {
    //   flexDirection: "column",
    //   alignItems: "center",
    // },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  right: {
    display: "flex",
    width: "50%",
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    // [theme.breakpoints.only("lg")]: {
    //   flexDirection: "column",
    //   alignItems: "center",
    // },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));
const Trusted = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  return (
    <>
      <div className={classes.main}>
        <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
          {visible ? (
            <Typography className={classes.heading}>
              <TypeAnimation
                wrapper="span"
                speed={12}
                sequence={[
                  "Why we are trusted Globally?",
                  1000,
                  "Why we are trusted Globally?",
                  2000,
                ]}
                cursor={false}
                repeat={Infinity}
              />
            </Typography>
          ) : (
            <></>
          )}
        </ScrollTrigger>
        <div
          style={{
            textAlign: "center",
            width: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <AnimatedText
            type="chars" // animate words or chars
            animation={{
              scale: 1,
              ease: "ease",
            }}
            animationType="wave"
            interval={0.02}
            duration={0.3}
            tag="p"
            className={classes.desc}
            includeWhiteSpaces
            threshold={0.1}
            rootMargin="20%"
          >
            Adeptd denotes Competence in digital. We are seasoned marketers and
            tech specialists working round the clock to deliver top-notch
            digital marketing services at most affordable rates so every
            business can attain there excellence.
          </AnimatedText>
        </div>
        <div style={{ marginTop: "30px" }} className={classes.count}>
          <div className={classes.left}>
            <div className={classes.first}>
              <GrowthCompo
                title="10"
                sign="+"
                desc="Varied Marketing Experience"
              />
              <GrowthCompo title="98" sign="%" desc="Client Retention" />
            </div>
            <div className={classes.first}>
              <GrowthCompo
                title="6"
                sign="X"
                desc="Average ROI
              Calculated"
              />
              <GrowthCompo title="3" sign="+" desc="Country Partners" />
              <GrowthCompo title="10" sign="+" desc="New Brand Established " />
            </div>
          </div>
          <div className={classes.right}>
            <div className={classes.first}>
              <GrowthCompo title="50" sign="+" desc="Projects Delivered" />
              <GrowthCompo
                title="6"
                sign="k+"
                desc="Marketing Qualified Leads Delivered"
              />
              <GrowthCompo
                title="35"
                sign="+"
                desc="Time-zone Aligned with Major Countries including U.S"
              />
            </div>
            <div className={classes.first}>
              <GrowthCompo
                title="40"
                sign="+"
                desc="Amazing Websites Delivered"
              />
              <GrowthCompo
                title="20"
                sign="+"
                desc="Combined Marketing & Tech Experience"
              />
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.footerdiv}>
            <Typography className={classes.title}>
              …. And still counting
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trusted;
