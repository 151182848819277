import React, { useEffect } from "react";
import Banner from "../components/about/Banner";
import OurUSP from "../components/about/OurUSP";
import ContactUs from "../components/common/ContactUs";
import Offer from "../components/common/offer/Offer";
import Process from "../components/common/process/Process";
import Testimonial from "../components/common/testimonial/Testimonial";
import OurWork from "../components/common/work/OurWork";
import { ThemeProvider, useTheme } from "@mui/material";
import { rightAboutData, tabAboutData } from "../data/Offer";
import TopButton from "../components/common/TopButton";
import GrowCard from "../components/home/GrowCard";
import Testimonials from "../components/about/Testimonials";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  return (
    <>
      <ThemeProvider theme={theme}>
        <Banner />
        <OurUSP />
        <Offer
          heading="What We Offer"
          desc="Our Growth Consultant from marketing, tech, and CPA background came all together to prepare an industry-specific digital marketing growth system for you!"
          tabData={tabAboutData}
          rightData={rightAboutData}
          path="about"
        />
        <Testimonial />
        <Testimonials />
        <Process />
        <OurWork path="cpa" />
        <ContactUs />
      </ThemeProvider>
    </>
  );
};

export default About;
