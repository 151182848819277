import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import nikki from '../../images/ourwork/nikki_andrews.png'
import best_tax_pro from '../../images/ourwork/best_tax_pro.png'
import resolve from '../../images/ourwork/resolve.png'
import md_sullivan from '../../images/ourwork/md_sullivan.png'
import tide_water from '../../images/ourwork/tide_water.png'
import cta from '../../images/ourwork/cta.png'
import halls_irs from '../../images/ourwork/halls_irs.png'
import profit from '../../images/ourwork/profit.png'
import fair_winds from '../../images/ourwork/fair_winds.png'
import estate from '../../images/ourwork/estate.png'
import taxcure from '../../images/ourwork/tax_cure.png'
import tax_resolution from '../../images/ourwork/tax_resolution.png'
import moi from '../../images/ourwork/moi.png'
import ddw from '../../images/ourwork/ddw.png'
import pack from '../../images/ourwork/pack.png'
import tautology from '../../images/ourwork/tautology.png'
import aloha from '../../images/ourwork/aloha.png'
import rentory from '../../images/ourwork/rentory.png'
import { makeStyles } from '@mui/styles'
import useInterSectionObseve from './useInterSectionObseve'
import { useInView } from 'react-intersection-observer'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    bannerImg: {
        width: '100%',
        height: 'auto',
    },
    box: {
        position: 'absolute',
        width: '200px',
        background: '#FDFDFD',
        padding: '10px',
        right: '0%',
        zIndex: '1',
        top: '50%',

    },
    boxInvisible: {
        visibility: 'hidden'
    },
    title: {
        fontFamily: 'Poppins !important',
        fontWeight: '500 !important',
        fontSize: '16px !important',
        color: '#159346 !important'
    },
    desc: {
        fontFamily: 'Poppins !important',
        fontWeight: '400 !important',
        fontSize: '14px !important',
        color: '#3A3A3A !important'
    },
    boxContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 20px'
    }
}))
const ImageItem = ({ index, img, setShow, desc, title }) => {
    const classes = useStyles();
    const ref = useRef(null)
    const cb = useCallback(() => {
        setShow(index);
    }, [setShow, index])
    //custom hook
    useInterSectionObseve(ref, cb)
    return (
        <div id={`s-${index}`} ref={ref} style={{ position: 'relative' }} className='mainContainer'>
            <div className={`${classes.box} box`}>
                <div className={classes.boxContent}>
                    <Typography className={classes.title}>{title}</Typography>
                    <Typography className={classes.desc}>{desc}</Typography>
                </div>
            </div>
            <img src={img} alt="work" className={classes.bannerImg} />
            {/* <img src={best_tax_pro} alt="work" className={classes.bannerImg} ref={taxProRef} id="2" />
            <img src={resolve} alt="work" className={classes.bannerImg} />
            <img src={md_sullivan} alt="work" className={classes.bannerImg} />
            <img src={tide_water} alt="work" className={classes.bannerImg} />
            <img src={cta} alt="work" className={classes.bannerImg} />
            <img src={halls_irs} alt="work" className={classes.bannerImg} />
            <img src={profit} alt="work" className={classes.bannerImg} />
            <img src={fair_winds} alt="work" className={classes.bannerImg} />
            <img src={estate} alt="work" className={classes.bannerImg} />
            <img src={taxcure} alt="work" className={classes.bannerImg} />
            <img src={tax_resolution} alt="work" className={classes.bannerImg} />
            <img src={moi} alt="work" className={classes.bannerImg} />
            <img src={ddw} alt="work" className={classes.bannerImg} />
            <img src={pack} alt="work" className={classes.bannerImg} />
            <img src={tautology} alt="work" className={classes.bannerImg} />
            <img src={aloha} alt="work" className={classes.bannerImg} />
            <img src={rentory} alt="work" className={classes.bannerImg} /> */}
        </div>
    )
}

export default ImageItem