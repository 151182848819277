import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Container, Typography } from "@mui/material";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import logo1 from "../../images/whyuslogo1.svg";
import logo2 from "../../images/clutch_banner.png";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "50px !important",
    color: "#179446 !important",
    fontWeight: "700 !important",
    float: "left !important",
    marginBottom: "20px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "42px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "48px !important",
    },
  },
  centertitle: {
    fontFamily: "Poppins !important",
    fontSize: "50px !important",
    color: "#3a3a3a !important",
    fontWeight: "500 !important",
    marginBottom: "30px !important",
    marginTop: "20px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "32px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "38px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px !important",
    },
  },
  second: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
  },
  logodiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      width: "80px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "110px",
    },
    [theme.breakpoints.only("md")]: {
      width: "150px",
    },
  },
  logo2: {
    width: '280px',
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      width: "80px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "110px",
    },
    [theme.breakpoints.only("md")]: {
      width: "150px",
    },
  },
}));
const Banner = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const openInNewTab = (url) => {
    // 👇️ setting target to _blank with window.open
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const textAnimate = {
    offscreen: { scale: 0, opacity: 0 },
    onscreen: {
      y: 0,
      scale: 1,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const logo = {
    offscreen: { opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  return (
    <>
      <Container maxWidth={"lg"}>
        <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
          {visible ? (
            <Typography className={classes.heading}>
              <TypeAnimation
                wrapper="span"
                speed={12}
                sequence={["Why Adeptd?", 1000, "Why Adeptd?", 2000]}
                cursor={false}
                repeat={Infinity}
              />
            </Typography>
          ) : (
            <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
              <Typography className={classes.heading}>
                <TypeAnimation
                  wrapper="span"
                  speed={12}
                  sequence={["Why Adeptd?", 1000, "Why Adeptd?", 2000]}
                  cursor={false}
                  repeat={Infinity}
                />
              </Typography>
            </ScrollTrigger>
          )}
        </ScrollTrigger>
        <div className={classes.main}>
          <div className={classes.second}>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={textAnimate}>
                <Typography className={classes.centertitle}>
                  We are a Full-Service{" "}
                  <span style={{ fontWeight: "700" }}> Outsourcing</span>{" "}
                  Digital Performance Marketing |{" "}
                  <span style={{ fontWeight: "700" }}>
                    {" "}
                    Creative & Tech Agency
                  </span>{" "}
                  Achieving Digital Excellence for{" "}
                  <span style={{ fontWeight: "700" }}>Businesses Globally</span>
                </Typography>
              </motion.p>
            </motion.div>
            {/* <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={textAnimate}>
                <Typography className={classes.centertitle}>
                  Digital Performance Marketing | Creative & Tech Agency
                </Typography>
              </motion.p>
            </motion.div>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={textAnimate}>
                <Typography className={classes.centertitle}>
                  Achieving Digital Excellence for{" "}
                  <span style={{ fontWeight: "600" }}>Businesses Globally</span>
                </Typography>
              </motion.p>
            </motion.div> */}
          </div>
          <div className={classes.logodiv}>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={logo}>
                <img
                  src={logo1}
                  className={classes.logo}
                  style={{ marginRight: "60px" }}
                  onClick={() => openInNewTab("https://themanifest.com/ppc/agencies/baidu?page=2#most-reviewed")}
                />
                <img src={logo2} className={classes.logo2} onClick={() => openInNewTab("https://clutch.co/profile/adeptd")} />
              </motion.p>
            </motion.div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Banner;
