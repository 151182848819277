import React, {
  Component,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import ReactCardCarousel from "react-card-carousel";
import { makeStyles } from "@mui/styles";
import FeedbackCard from "../../home/FeedbackCard.js";
import WorkCard from "./WorkCard.js";
import img from "../../../images/CaseStudyCardImg.png";
import img2 from "../../../images/CaseStudyCardImg2.png";
import img3 from "../../../images/CaseStudyCardImg3.png";
import img4 from "../../../images/CaseStudyCardImg4.png";
import icn from "../../../images/check_circle.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { debounce } from "lodash";
import { useScrollLock } from "../../common/hooks/useScrollLock.js";
import { useScroll } from "framer-motion";
import { useWindowScrollPositions } from "../../common/hooks/useWindowScrollPositions .js";
import ScrollTrigger from "react-scroll-trigger";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    height: "400px",
    marginTop: "50px",
  },
  cardStyle: {
    textAlign: "center",
    fontFamily: "sans-serif",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "10px",

    cursor: "pointer",
    width: "100%",
    padding: "10px 20px",

    // paddingRight: "200px",
    // paddingLeft: "200px"
    // padding: "0px 200px",
  },
}));

export default function CardSlider({ path }) {
  const classes = useStyles();
  const [slide, setSlide] = useState(0);
  const { lockScroll, unlockScroll } = useScrollLock();
  //const { scrollX, scrollY } = useWindowScrollPositions()
  const [trigger, setTrigger] = useState(false);
  const sliderRef = useRef();
  const [scrollY, setScrollY] = useState(false);
  const onWheelSlider = debounce((e, ref) => {
    if (!ref.current) return;
    if (e.deltaX > 0) {
      ref.current.slickNext();
    } else if (e.deltaX < 0) {
      ref.current.slickPrev();
    }
  }, 20);
  const divRef = useRef(null);
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    afterChange: (currentSlide) => {
      console.log(currentSlide);
      setSlide(currentSlide);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onEnter = () => {
    setTrigger(true);
  };

  const onExit = () => {
    setTrigger(false);
  };

  function scroll(e) {
    e.preventDefault();
    if (sliderRef === null) return 0;
    e.deltaX > 0
      ? sliderRef.current.slickNext()
      : sliderRef.current.slickPrev();
  }

  const handleUpScroll = (e) => {
    if (e.deltaX > 0) {
      sliderRef.current.slickNext();
    }
  };
  const handleDownScroll = (e) => {
    if (e.deltaX < 0) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", scroll, true);

    return () => {
      window.removeEventListener("wheel", scroll, true);
    };
  }, []);
  return (
    <div className={classes.cardContainer} ref={divRef} id="slide">
      <ScrollTrigger onEnter={onEnter} onExit={onExit}>
        <ReactScrollWheelHandler
          upHandler={handleUpScroll}
          downHandler={handleDownScroll}
          preventScroll={slide === 0 || slide === 3 ? false : true}
        >
          <Slider
            infinite={true}
            initialSlide={0}
            ref={sliderRef}
            {...settings}
          >
            <div className={classes.cardStyle}>
              {console.log(sliderRef.current)}
              <WorkCard
                title="Florida Based Tax Representation and IRS resolution Firm!"
                subTitle="Performance Marketing"
                obj="Objective"
                icn={icn}
                list="Customer Calls  and Inquiry Form  Submits looking for  Tax resolution  Services"
                img={img}
                path="johnwood"
              />
            </div>
            <div className={classes.cardStyle}>
              <WorkCard
                title="California Based IRS Tax Resolution Firm!"
                subTitle="Performance Marketing"
                obj="Objective"
                icn={icn}
                list="Customer Calls  and Inquiry Form  Submits looking for  Tax resolution  Services"
                img={img2}
                path="swat"
              />
            </div>
            {
              path === "cpa" ?
                "" :
                <div className={classes.cardStyle}>
                  <WorkCard
                    title="India based IELTS Edutech company!"
                    subTitle="Performance Marketing"
                    obj="Objective"
                    icn={icn}
                    list="Interested Student In-bound inquiry though Website"
                    img={img3}
                    path="ielts"
                  />
                </div>
            }
            <div className={classes.cardStyle}>
              <WorkCard
                title="Texas based IRS Tax Resolution and Planning Firm!"
                subTitle="Performance Marketing"
                obj="Objective"
                icn={icn}
                list="Customer Calls  and Inquiry Form  Submits looking for  Tax resolution  Services"
                img={img4}
                path="hopkins"
              />
            </div>
          </Slider>
        </ReactScrollWheelHandler>
      </ScrollTrigger>
      {/* <ReactCardCarousel
        disable_box_shadow={true}
        autoplay={true}
        autoplay_speed={6000}
        spread="wide"
        disable_keydown={true}
        disable_fade_in={false}
      >
        <div className={classes.cardStyle}>
          <WorkCard
            title="A Texas based CPA and Tax Resolution!"
            subTitle="Performance Marketing"
            obj="Objective"
            icn={icn}
            list="Customer Calls  and Inquiry Form  Submits looking for  Tax resolution  Services"
            img={img}
          />
        </div>
        <div className={classes.cardStyle}>
          <WorkCard
            title="A California based Tax Planning and IRS Resolution Firm!"
            subTitle="Performance Marketing"
            obj="Objective"
            icn={icn}
            list="Customer Calls  and Inquiry Form  Submits looking for  Tax resolution  Services"
            img={img2}
          />
        </div>
        <div className={classes.cardStyle}>
          <WorkCard
            title="India based IELTS exam preparation online tutorial platform!"
            subTitle="Performance Marketing"
            obj="Objective"
            icn={icn}
            list="Interested Student In-bound inquiry though Website"
            img={img3}
          />
        </div>
        <div className={classes.cardStyle}>
          <WorkCard
            title="A Texas based CPA and Tax Resolution!"
            subTitle="Performance Marketing"
            obj="Objective"
            icn={icn}
            list="Customer Calls  and Inquiry Form  Submits looking for  Tax resolution  Services"
            img={img4}
          />
        </div>
      </ReactCardCarousel> */}
    </div>
  );
}
