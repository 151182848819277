import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import Hopkins from "../Hopkins.js";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex !important",
    width: "650px !important",
    height: "400px !important",
    justifyContent: "space-between !imporatnt",
    boxShadow: "0px 4.00023px 16.0009px rgba(0, 0, 0, 0.15) !important ",
    borderRadius: "15.0009px !important",
    [theme.breakpoints.only('xs')]: {
      width: '500px !important',
      flexDirection: 'column !important',


    }
  },
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "center",

  },
  left: {
    width: "50%",
    textAlign: "left",
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },
  right: {
    width: "50%",
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },
  cardTitle: {
    fontFamily: "Poppins !important",
    fontSize: "20px !important",
    fontWeight: "500 !important",
    color: "#3A3A3A !important",
    marginTop: "10px !important",
    [theme.breakpoints.only('xs')]: {
      fontSize: "14px !important",
    }
  },
  cardSubTitle: {
    fontFamily: "Nothing you could do !important",
    fontSize: "20px !important",
    fontWeight: "700 !important",
    color: "#159346 !important",
    marginTop: "20px!important",
    [theme.breakpoints.only('xs')]: {
      fontSize: "14px !important",
    }
  },
  objDiv: {
    marginTop: "25px !important",

  },
  objTitle: {
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
    color: "#3a3a3a !important",
    [theme.breakpoints.only('xs')]: {
      fontSize: "12px !important",
    }
  },
  list: {
    display: "flex",
    marginTop: "13px",
  },
  icon: {
    marginRight: "10px",
    width: "20px",
    marginBottom: "5px",
  },
  listItem: {
    fontFamily: "Poppins !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    color: "#3a3a3a !important",
    [theme.breakpoints.only('xs')]: {
      fontSize: "10px !important",
    }
  },
  img: {
    width: "250px",
    [theme.breakpoints.only('xs')]: {
      width: "150px",
    }
  },
  btn: {
    backgroundColor: "#159346 !important",
    textTransform: "none !important",
    marginTop: "60px !important",
    color: "#FDFDFD !important",
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    borderRadius: "100px !important",
    padding: "8px 15px !important",
    [theme.breakpoints.only('xs')]: {
      fontSize: "12px !important",
      marginTop: "60px !important",
      padding: "5px 5px !important",
    }
  },
}));

const WorkCard = ({ title, subTitle, obj, icn, list, img, path }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.main}>
        <Card className={classes.card}>
          <CardContent
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className={classes.left}>
              <Typography className={classes.cardTitle}>{title}</Typography>
              <Typography className={classes.cardSubTitle}>
                {subTitle}
              </Typography>
              <div className={classes.objDiv}>
                <Typography className={classes.objTitle}>{obj}</Typography>
                <div className={classes.list}>
                  <div>
                    <img src={icn} className={classes.icon} />
                  </div>
                  <Typography className={classes.listItem}>{list}</Typography>
                </div>
              </div>
              <NavLink
                to={`/${path}`}
                style={{ textDecoration: "none" }}
              >
                <Button className={classes.btn}>View Case Study</Button>
              </NavLink>
            </div>
            <div className={classes.right}>
              <img src={img} className={`${classes.img} floatingimage`} />
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default WorkCard;
