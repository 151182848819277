import React from "react";
import { makeStyles } from "@mui/styles";
import logo1 from "../../images/googleadwordBW.png";
import logo2 from "../../images/headerLogo/metaBW.png";
import logo3 from "../../images/headerLogo/YTBW.png";
import logo4 from "../../images/headerLogo/pintrestBW.png";
import logo5 from "../../images/headerLogo/twitterBW.png";
import logo6 from "../../images/headerLogo/linkedinBW.png";
import logo11 from "../../images/googleadword.png";
import logo12 from "../../images/headerLogo/metacolor.png";
import logo13 from "../../images/headerLogo/YTcolor.png";
import logo14 from "../../images/headerLogo/pintrestcolor.png";
import logo15 from "../../images/headerLogo/twittercolor.png";
import logo16 from "../../images/headerLogo/linkedincolor.png";
import { Grid } from "@mui/material";
import HoverImage from "react-hover-image/build";

const useStyles = makeStyles((theme) => ({
  third: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    [theme.breakpoints.only("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: "50px",
    },
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      marginTop: "50px",
    },
  },
  logo: {
    width: "70%",
    aspectRatio: "6/2",
    objectFit: "contain",
    // height: "30px",
    cursor: "pointer",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.3)",
    },
    [theme.breakpoints.only("sm")]: {
      marginBottom: "40px",
      width: "250px",
    },
    [theme.breakpoints.only("xs")]: {
      marginBottom: "40px",
    },
    [theme.breakpoints.only("md")]: {
      marginBottom: "40px",
    },
  },
  logoDiv: {
    display: "flex",
    justifyContent: "center",
  },
}));
const BannerLogo = () => {
  const classes = useStyles();
  const images = [logo1, logo2, logo3, logo4, logo5, logo6];
  return (
    <>
      {/* <Dock images={images} /> */}
      <Grid container>
        <div className={classes.third}>
          <Grid
            item
            xl={2}
            lg={2}
            md={4}
            sm={6}
            xs={6}
            className={classes.logoDiv}
          >
            <HoverImage
              src={logo1}
              hoverSrc={logo11}
              className={classes.logo}
            />
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={4}
            sm={6}
            xs={6}
            className={classes.logoDiv}
          >
            <HoverImage
              src={logo2}
              hoverSrc={logo12}
              className={classes.logo}
            />
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={4}
            sm={6}
            xs={6}
            className={classes.logoDiv}
          >
            <HoverImage
              src={logo3}
              hoverSrc={logo13}
              className={classes.logo}
            />
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={4}
            sm={6}
            xs={6}
            className={classes.logoDiv}
          >
            <HoverImage
              src={logo4}
              hoverSrc={logo14}
              className={classes.logo}
            />
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={4}
            sm={6}
            xs={6}
            className={classes.logoDiv}
          >
            <HoverImage
              src={logo5}
              hoverSrc={logo15}
              className={classes.logo}
            />
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={4}
            sm={6}
            xs={6}
            className={classes.logoDiv}
          >
            <HoverImage
              src={logo6}
              hoverSrc={logo16}
              className={classes.logo}
            />
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default BannerLogo;
