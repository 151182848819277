import { useEffect } from "react";

function useInterSectionObseve(ref, callback) {
    useEffect(() => {
        if (ref.current) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry && entry.boundingClientRect.top <= 0) {
                        callback();
                    }
                },
                {
                    root: null,
                    rootMargin: "-325px 0px",
                    threshold: [
                        ...Array.from(
                            Array(101)
                                .fill(null)
                                .map((_, i) => i / 100)
                        )
                    ]
                }
            );

            observer.observe(ref.current);

            return () => observer.disconnect();
        }

        return () => { };
    }, [ref, callback]);
}

export default useInterSectionObseve;
