import { ThemeProvider, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import TopButton from "../components/common/TopButton";
import Banner from "../components/whyus/Banner";
import LetsTalk from "../components/whyus/LetsTalk";
import OurVision from "../components/whyus/OurVision";
import Trusted from "../components/whyus/Trusted";
import Sector from "../components/home/Sectors";
import Clients from "../components/common/Clients"
import Testimonial from "../components/common/testimonial/Testimonial"
import GrowSlider from "../components/whyus/GrowSlider";
const WhyUs = () => {
  const theme = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ThemeProvider theme={theme}>
        <Banner />
        <Trusted />
        <OurVision />
        <LetsTalk heading="Take First Step to Attain your Ultimate Business Growth!" />
        <Sector path="whyus" />
        <Clients />
        <Testimonial path="whyus" />
        {/* <GrowSlider /> */}
        <LetsTalk heading="Talk to us about your project, we bet you won’t Regret!" />
      </ThemeProvider>
    </>
  );
};

export default WhyUs;
