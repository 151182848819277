import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Heading from "../common/Heading";
import image from "../../images/Map.svg";
import FeedbackCard from "./FeedbackCard";
import SlickSlider from "./SlickSlider";
import joehopkins from "../../images/testimonial/joe_hopkins.png";
import jennifer from "../../images/testimonial/jennifer.jfif";
import amit from "../../images/testimonial/amit.png";
import walker from "../../images/testimonial/walker.jfif";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { createTheme, useMediaQuery } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    backgroundColor: "#fdfdfd",
    overflow: "hidden !important",
    paddingTop: "60px",
    display: "flex",
    flexDirection: "column",
  },
  bottomdiv: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    width: "100%",
    height: "650px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const Feedback = () => {
  const classes = useStyles();
  const items = [
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <FeedbackCard
        blur={true}
        title="Amit Chandel"
        title1="President"
        title2="Focus CPA Group Inc, California"
        desc="We would not have achieved what we are getting without their team."
        profilepic={amit}
      />
      <FeedbackCard
        blur={false}
        title="Jennifer Ginther"
        title1="President"
        title2="Fairwinds Bookkeeping & Tax Solutions, Inc"
        desc="I was very pleased with how it turned out as well as how easy it was to get taken care of. We love that we can get all the help we need with landing pages, webpages and marketing all in one place. It was easy and pricing was excellent."
        profilepic={jennifer}
      />
      <FeedbackCard
        blur={true}
        title="Joe Hopkins"
        title1="Owner"
        title2="Hopkins CPA Firm PC, Texas"
        desc="Extremely reasonable, bang for the buck you would be challenged to find a better service for a better price anywhere in the world. Adeptd works 24/7 to give timely feedback. Excellent quality of work & in depth client satisfaction goals"
        profilepic={joehopkins}
      />
    </div>,

    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <FeedbackCard
        blur={true}
        title="Jennifer Ginther"
        title1="President"
        title2="Fairwinds Bookkeeping & Tax Solutions, Inc"
        desc="I was very pleased with how it turned out as well as how easy it was to get taken care of. We love that we can get all the help we need with landing pages, webpages and marketing all in one place. It was easy and pricing was excellent."
        profilepic={jennifer}
      />
      <FeedbackCard
        blur={false}
        title="Joe Hopkins"
        title1="Owner"
        title2="Hopkins CPA Firm PC, Texas"
        desc="Extremely reasonable, bang for the buck you would be challenged to find a better service for a better price anywhere in the world. Adeptd works 24/7 to give timely feedback. Excellent quality of work & in depth client satisfaction goals"
        profilepic={joehopkins}
      />

      <FeedbackCard
        blur={true}
        title="Darren D Walker"
        title1="Founder & CEO,"
        title2="Darren D. Walker Marketing Inc. Canada"
        desc="They are responsive and incredibly proactive."
        profilepic={walker}
      />
    </div>,
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <FeedbackCard
        blur={true}
        title="Joe Hopkins"
        title1="Owner"
        title2="Hopkins CPA Firm PC, Texas"
        desc="Extremely reasonable, bang for the buck you would be challenged to find a better service for a better price anywhere in the world. Adeptd works 24/7 to give timely feedback. Excellent quality of work & in depth client satisfaction goals"
        profilepic={joehopkins}
      />
      <FeedbackCard
        blur={false}
        title="Darren D Walker"
        title1="Founder & CEO,"
        title2="Darren D. Walker Marketing Inc. Canada"
        desc="They are responsive and incredibly proactive."
        profilepic={walker}
      />

      <FeedbackCard
        blur={true}
        title="Amit Chandel"
        title1="President"
        title2="Focus CPA Group Inc, California"
        desc="We would not have achieved what we are getting without their team."
        profilepic={amit}
      />
    </div>,
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <FeedbackCard
        blur={true}
        title="Darren D Walker"
        title1="Founder & CEO,"
        title2="Darren D. Walker Marketing Inc. Canada"
        desc="They are responsive and incredibly proactive."
        profilepic={walker}
      />

      <FeedbackCard
        blur={false}
        title="Amit Chandel"
        title1="President"
        title2="Focus CPA Group Inc, California"
        desc="We would not have achieved what we are getting without their team."
        profilepic={amit}
      />

      <FeedbackCard
        blur={true}
        title="Jennifer Ginther"
        title1="President"
        title2="Fairwinds Bookkeeping & Tax Solutions, Inc"
        desc="I was very pleased with how it turned out as well as how easy it was to get taken care of. We love that we can get all the help we need with landing pages, webpages and marketing all in one place. It was easy and pricing was excellent."
        profilepic={jennifer}
      />
    </div>,
  ];

  const itemsMobile = [
    <FeedbackCard
      blur={false}
      title="Joe Hopkins"
      title1="Owner"
      title2="Hopkins CPA Firm PC, Texas"
      desc="Extremely reasonable, bang for the buck you would be challenged to find a better service for a better price anywhere in the world. Adeptd works 24/7 to give timely feedback. Excellent quality of work & in depth client satisfaction goals"
      profilepic={joehopkins}
    />,

    <FeedbackCard
      blur={false}
      title="Jennifer Ginther"
      title1="President"
      title2="Fairwinds Bookkeeping & Tax Solutions, Inc"
      desc="I was very pleased with how it turned out as well as how easy it was to get taken care of. We love that we can get all the help we need with landing pages, webpages and marketing all in one place. It was easy and pricing was excellent."
      profilepic={jennifer}
    />,

    <FeedbackCard
      blur={false}
      title="Amit Chandel"
      title1="President"
      title2="Focus CPA Group Inc, California"
      desc="We would not have achieved what we are getting without their team."
      profilepic={amit}
    />,
    <FeedbackCard
      blur={false}
      title="Darren D Walker"
      title1="Founder & CEO,"
      title2="Darren D. Walker Marketing Inc. Canada"
      desc="They are responsive and incredibly proactive."
      profilepic={walker}
    />,
  ];
  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    900: {
      items: 3,
    },
    1024: {
      items: 3,
    },
  };
  useEffect(() => {

  }, [items, itemsMobile])
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <>
      <div className={classes.main}>
        <div>
          <Heading secondTitle="Client's Feedback" thirdTitle="" />
        </div>
        <div className={classes.bottomdiv}>
          <AliceCarousel
            mouseTracking
            autoWidth={isMobile ? false : true}
            disableButtonsControls={true}
            responsive={responsive}
            items={isMobile ? itemsMobile : items}
            autoPlay={true}
            infinite={true}
            autoPlayInterval={5000}
          />
        </div>
      </div>
    </>
  );
};

export default Feedback;

{
  /* <AliceCarousel
autoWidth={false}
mouseTracking
disableButtonsControls={true}
responsive={responsive}
items={isMobile ? itemsMobile : items}
activeIndex={items.length === 3}
/> */
}
