import React, { useEffect } from 'react'
import "./ScamPage.scss"
const ScamPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }, [])
    return (
        <div className='container'>
            <div className='scam-page-container'>
                {/* <div className='seo-header-container'>
                    <p>SEO Meta description</p>
                    <h1>
                        Stay alert and secure with <span>ADEPTD's</span> official guide on avoiding
                        fraudulent job offers and identity theft scams. Verify job legitimacy and protect yourself from
                        being scammed. Learn more now
                    </h1>
                </div> */}
                <div className='job-offer-container'>
                    <h1>
                        Beware of Identity Theft and Fraudulent Job Offers
                    </h1>
                    <p>At ADEPTD, a leading digital marketing and web development agency in India, we are proud
                        to collaborate exclusively with international brands and companies. We are committed to
                        clarifying that ADEPTD <span>does not</span> sponsor or endorse any form of employment that involves
                        financial transactions from candidates for promised higher returns, profits, or commissions.</p>
                    <p>We wish to specifically address a scam involving the promise of payment for following
                        certain Instagram accounts. <span>ADEPTD does not offer any jobs related to following social
                            media accounts for monetary compensation</span></p>
                </div>
                <div className="verification-measures">
                    <h2>Essential Verification Measures:</h2>
                    <ul>
                        <li><span>Email Authenticity:</span> Ensure any correspondence is from our official domain,<span> ‘@adeptd.com’ (for example, hello@adeptd.com).</span></li>
                        <li><span>Official Contact Number</span> Our genuine business number is<span> +91-7742343965</span>. Communications from other numbers should be treated with caution.</li>
                        <li><span>Recruitment Procedure</span> Our recruitment process is thorough, involving HR pre-screening, interviews with department managers, online assessments, and a final round with our CEO/Founder. Communication from ADEPTD will only come from an adeptd.com email address.</li>
                        <li><span>Employee Verification</span> Should someone claim affiliation with ADEPTD, please verify their contact details against our official information.</li>
                    </ul>
                </div>
                <div className="verification-measures mt-1">
                    <h2>If You Encounter Suspicious Offers:</h2>
                    <ul>
                        <li> Immediately contact us at <span> **hello@adeptd.com**</span> if you receive dubious job offers or
                            to verify the legitimacy of any communications.</li>
                        <li>ADEPTD <span>never requests money</span> from potential or current employees. If you have
                            transferred money to someone alleging to be from ADEPTD, be informed that we
                            cannot assume responsibility for such actions.</li>
                    </ul>
                </div>
                <div className="pledge-container mt-1">
                    <div className="pledge-content">
                        <h2>Our Pledge:</h2>
                        <p>We are devoted to the security and well-being of our community. Stay vigilant and report any dubious activities to us directly. Your safety from scams is our priority.</p>
                        <p>Thank you for your attention and cooperation.</p>
                        <p>Sincerely,</p>
                        <p className="signature"><span>Shabbir Salodawala</span></p>
                        <p>Founder & CEO | ADEPTD</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ScamPage