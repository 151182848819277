import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "370px !important",
    height: "345px !important",
    display: "flex !important",
    flexDirection: "column !important",
    borderRadius: "5px !important",
    border: "0.5px solid #179446",
    boxShadow: "none !important",
    marginBottom: "15px",
  },
  cardBodyTwo: {
    width: "100% !important",
    height: "342px !important",
  },
  Cardtitle: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    fontWeight: "600 !important",
    color: "#3A3A3A !important",
  },
  desc: {
    inlineSize: "90%",
    fontFamily: "Poppins !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    marginTop: "20px !important",
    color: "#3A3A3A !important",
    marginBottom: "25px !important",
  },
  image: {
    position: "absolute",
    bottom: "0px",
    right: "-10px",
    width: "180px",
  },
  btn: {
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    textTransform: "none !important",
    color: "#179446 !important",
    marginTop: "0px !important",
    padding: "0px !important",
  },
}));
const SectorCard = ({ image, title, desc, path }) => {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.cardBody}>
        <CardActionArea>
          <CardContent className={classes.cardBodyTwo}>
            <Typography className={classes.Cardtitle}>{title}</Typography>
            <Typography className={classes.desc}>{desc}</Typography>
            <img src={image} className={classes.image} alt="img" />
            {path === "cpamarketing" ? (
              <NavLink
                to={`/${path}`}
                style={{ textDecoration: "none" }}
              >
                <Button className={classes.btn}>know More {">"}</Button>
              </NavLink>
            ) : (
              ""
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default SectorCard;
