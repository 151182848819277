import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Right from "./Right";
import image from "../../../images/Growthbro.svg";
import image2 from "../../../images/Creativethinking.svg";
import image3 from "../../../images/Appmonetization.svg";
import image4 from "../../../images/Womanthinking.svg";
import icon from "../../../images/check_circle.svg";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "Poppins !important",
    fontSize: "48px !important",
    fontWeight: "700 !important",
    color: "#3A3A3A !important",
    textAlign: "center !important",
    [theme.breakpoints.down("md")]: {
      marginTop: "80px !important",
    },
    [theme.breakpoints.only("md")]: {
      marginTop: "80px !important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "44px !important",
    },
  },
}));

const Impact = ({ maintitle, path }) => {
  const classes = useStyles();
  const textAnimate = {
    offscreen: { scale: 0, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  return (
    <>
      <div>
        <Typography className={classes.heading}>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
          >
            <motion.p variants={textAnimate}>{maintitle}</motion.p>
          </motion.div>
        </Typography>
        {path === "swat" ? (
          <Right
            image={image2}
            item1="No ad account to review the history."
            item2="No analytics setup."
            item3="No digital marketing exposure of the company as brand was never been introduced to digital marketing."
            item4="No brand awareness."
            icon={icon}
            icon2={icon}
            icon3={icon}
            icon4={icon}
            heading="US Tax Resolution started with Tax resolution service and want to grab the maximum conversions through performance marketing."
          />
        ) : (
          ""
        )}
        {path === "hopkins" ? (
          <Right
            image={image}
            item1="Hopkins saw a whopping 30x growth in qualified leads who were making direct calls or coming in through inquiry form from PPC ads."
            item2="CPL and CPC dropped by 60%. It was unusual for anyone who knows that how tough it to compete and sustain on Tax and IRS related Keywords and Facebook Ads."
            item3="Migrating website on a better server and WP helped gain better website performance"
            item4="New landing page and implementing CTAs on the heat points of the webpages on the website helped increase in quality conversions"
            icon={icon}
            icon2={icon}
            icon3={icon}
            icon4={icon}
            heading=""
          />
        ) : (
          ""
        )}
        {path === "johnwood" ? (
          <Right
            image={image4}
            item1="Poor Ads image style and vague messaging"
            item2="Google Ads keywords were not targeted properly with broad search terms and keywords"
            item3="Poor bid optimization"
            item4="Website was not updated and had poor support"
            item5="Ad were running with no optimization been made to even Google or Facebook ads"
            item6="Website was not updated and had poor support"
            icon={icon}
            icon2={icon}
            icon3={icon}
            icon4={icon}
            icon5={icon}
            icon6={icon}
            heading="There digital marketing was not going well. After investing more than $5K a month for a while on Facebook and Google ads were not driving them conversions of prospective people that can have IRS issue!"
          />
        ) : (
          ""
        )}
        {path === "ielts" ? (
          <Right
            image={image3}
            item1="CPC and CPA was at minimum"
            icon={icon}
            heading="Adeptd paid marketing experts suggested to just start with Facebook lead gen and brand awareness campaigns which worked wonders as IELTS expert received."
            icon2={icon}
            item2="230+ student inquiries in just 30 days of campaign.."
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Impact;
