import { makeStyles } from '@mui/styles';
import React from 'react'
import GrowCard from '../home/GrowCard';
const useStyles = makeStyles((theme) => ({

    allcardBody: {
        display: "flex",
        width: "100%",
        justifyContent: "Center",
        marginTop: "50px",
        marginBottom: "50px",
    },
    allcardBody2: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
}));
const GrowSlider = () => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.allcardBody}>
                <div className={classes.allcardBody2}>
                    <div className="slider_wegrow">
                        <ul>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                            <li>
                                <GrowCard />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GrowSlider