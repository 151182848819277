import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import { createTheme, ThemeProvider } from "@mui/material";
import Footer from "./components/Footer";
import Hopkins from "./components/works/Hopkins";
import DotRingCursor from "./components/common/cursor/DotRingCursor";
import SwatAdvisor from "./components/works/SwatAdvisor";
import IELTS from "./components/works/IELTS";
import JohnWood from "./components/works/JohnWood";
import WhyUs from "./pages/WhyUs";
import ContactUs from "./components/works/common/ContactUs";
import TopButton from "./components/common/TopButton";
import NotFound from "./components/common/NotFound";
import Work from "./components/ourwork/Work";
import Events from "./components/common/events/Events";
import TagManager from 'react-gtm-module'
import ScamPage from "./components/common/events/ScamPage";

function App() {
  // const tagManagerArgs={
  //   gtmId:"GTM-K94TJTS"
  // }
  // TagManager.initialize(tagManagerArgs)
  const theme = createTheme();
  return (
    <>
      <Router basename={"/"}>
        <ThemeProvider theme={theme}>
          <DotRingCursor />
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <Navbar />
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <TopButton />
        </ThemeProvider>
        <Routes>
          <Route path={`/`} element={<Home />} />
          <Route path={`/cpamarketing`} element={<About />} />
          <Route
            path={`/hopkins`}
            element={<Hopkins />}
          />
          <Route
            path={`/swat`}
            element={<SwatAdvisor />}
          />
          <Route path={`/ielts`} element={<IELTS />} />
          <Route
            path={`/johnwood`}
            element={<JohnWood />}
          />
          <Route path={`/whyus`} element={<WhyUs />} />
          <Route
            path={`/contactus/:email`}
            element={<ThemeProvider theme={theme}><ContactUs /></ThemeProvider>}
          />
          <Route
            path={`/contactus`}
            element={
              <ThemeProvider theme={theme}>
                <ContactUs />
              </ThemeProvider>
            }
          />
          <Route path={`/ourwork`} element={<Work />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/protect-against-fraud" element={
            <ThemeProvider theme={theme}>
              {/* <Events /> */}
              <ScamPage />
            </ThemeProvider>
          } />
        </Routes>
        <ThemeProvider theme={theme}>
          <Footer />
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
