import React from "react";
import { makeStyles } from "@mui/styles";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import icon from "../../images/uspIcn.svg";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "850px",
    borderRadius: "10px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 4px 4px rgba(21, 147, 70, 0.15) !important",
    marginBottom: "50px !important",
  },
  headingDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    marginBottom: "15px",
  },
  icn: {
    width: "60px",
    marginRight: "5px",
  },
  title: {
    fontFamily: "Poppins !important",
    fontSize: "21px !important",
    color: "#159346 !important",
    fontWeight: "500 !important",
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "18px !important",
    color: "#3A3A3A !important",
    fontWeight: "400 !important",
  },
}));
const OurUSPCard = ({ title, desc, icon }) => {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.cardBody}>
        <CardActionArea>
          <CardContent>
            <div className={classes.headingDiv}>
              <img src={icon} className={classes.icn} />
              <Typography className={classes.title}>{title}</Typography>
            </div>
            <div style={{ textAlign: "center" }}>
              <Typography className={classes.desc}>{desc}</Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default OurUSPCard;
