import { Container } from "@mui/material";
import React, { useState } from "react";
import FeedbackCard from "./FeedbackCard";
import { makeStyles } from "@mui/styles";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const useStyle = makeStyles((theme) => ({
  blurEffect: {
    filter: "blur(1px)"
  }
}))

export default function SlickSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: "0px",
    arrows: false,
    className: "slick",
  };
  const items = [
    <FeedbackCard blur={true} />,
    <FeedbackCard blur={false} />,
    <FeedbackCard blur={true} />,
    <FeedbackCard blur={true} />,
    <FeedbackCard blur={false} />,
    <FeedbackCard blur={true} />,
  ]
  const responsive = {
    0: {
      items: 1
    },
    600: {
      items: 2
    },
    1024: {
      items: 3
    }
  };
  return (
    <div >
      <AliceCarousel disableButtonsControls={true} responsive={responsive} items={items} />
    </div>


  );
}

 // <Slider {...settings}>
      //   <div style={{ padding: "50px" }}>
      //     <FeedbackCard />
      //   </div>
      //   <div style={{ padding: "50px" }}>
      //     <FeedbackCard />
      //   </div>
      //   <div style={{ padding: "50px" }}>
      //     <FeedbackCard />
      //   </div>
      //   <div>
      //     <FeedbackCard />
      //   </div>
      // </Slider>