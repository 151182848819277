import React, { useState } from "react";
import Bannerheading from "../common/Bannerheading";
import { makeStyles } from "@mui/styles";
import { Container, Typography } from "@mui/material";
import BannerLogo from "../common/BannerLogo";
import { TypeAnimation } from "react-type-animation";
import ScrollTrigger from "react-scroll-trigger";
import { motion, useScroll, useTransform, Variants } from "framer-motion";
import AnimatedText from "react-animated-text-content";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10rem",
    // marginTop: "100px",
    [theme.breakpoints.only("xs")]: {
      marginBottom: "3rem",
    },
  },
  first: {
    textAlign: "center",
    width: "100%",
    marginTop: "40px",
  },
  second: {
    textAlign: "center",
    width: "100%",
    marginTop: "30px",
    width: "100%",
    display: " flex",
    justifyContent: "center",
  },
  title: {
    fontFamily: "Nothing you could do !important",
    fontSize: "44px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    [theme.breakpoints.only("md")]: {
      fontSize: "44px!important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px!important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px!important",
    },
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "18px !important",
    color: "#3A3A3A !important",
    inlineSize: "750px !important",
    textAlign: "center !important",
    [theme.breakpoints.only("sm")]: {
      fontSize: "16px!important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px!important",
    },
  },
  third: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginTop: "30px",
  },
  logo: {
    width: "150px",
    height: "30px",
  },
}));
const Banner = () => {
  const classes = useStyles();
  
  const logo = {
    offscreen: { opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };

  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  return (
    <>
      <div className={classes.main}>
        <Container maxWidth="lg">
          <Bannerheading
            firstheading="Your Own"
            secondheading="Digital Marketing and Tech"
            thirdheading="Solution Team"
          />

          <div className={classes.first}>
            <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
              {visible ? (
                <Typography className={classes.title}>
                  <TypeAnimation
                    repeat={Infinity}
                    sequence={[" Experience Adept Talent,", 1000]}
                    wrapper="div"
                    cursor={false}
                  />

                  {
                    <TypeAnimation
                      repeat={Infinity}
                      sequence={["Services & Support", 4000]}
                      wrapper="div"
                      cursor={false}
                      speed={15}
                    />
                  }
                </Typography>
              ) : (
                <></>
              )}
            </ScrollTrigger>
          </div>
          <div className={classes.second}>
            {/* <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.p variants={textAnimate2}> */}
            <AnimatedText
              type="chars" // animate words or chars
              animation={{
                // x: "-100px",
                // y: "-18px",
                scale: 1,
                ease: "ease",
              }}
              animationType="wave"
              interval={0.02}
              duration={0.3}
              tag="p"
              className={classes.desc}
              includeWhiteSpaces
              threshold={0.1}
              rootMargin="20%"
            >
              {/* <Typography className={classes.desc}> */}
              Go Digital first with Performance Marketing & Seach Engine Optimization, Creative Designing, Website & App Development, Marketing Automation, and everything in between!
              {/* </Typography> */}
            </AnimatedText>
            {/* </motion.p>
            </motion.div> */}
          </div>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
          >
            <motion.p variants={logo}>
              <BannerLogo />
            </motion.p>
          </motion.div>
        </Container>
      </div>
    </>
  );
};

export default Banner;
