import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import img from "../../../images/contactusimg.jpg";
import {
  Button,
  Checkbox,
  Chip,
  createTheme,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";

import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import Flogo1 from "../../../images/fbcontact.svg";
import Flogo2 from "../../../images/instacontact.svg";
import Flogo3 from "../../../images/fclutchcontact.svg";
import Flogo4 from "../../../images/linkedincontact.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import _without from "lodash/without";
import CancelIcon from "@mui/icons-material/Cancel";
import ThankYou from "./ThankYou";
import emailjs from "@emailjs/browser";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import thankYou from "../../../images/thankyou.svg";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import { motion, Variants } from "framer-motion";
import india from "../../../images/in.svg";
import us from "../../../images/us.svg";
import axios from 'axios'
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "nothing you could do !important",
    fontWeight: "700 !important",
    fontSize: "70px !important",
    textAlign: "center !important",
    color: "#179446 !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "50px !important",
    },
  },
  subdiv: {
    width: "100%",
    height: "100%",
    padding: "70px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  bottom: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "50px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  title1: {
    fontFamily: "Poppins !important",
    fontWeight: "600 !important",
    fontSize: "42px !important",
  },
  title2: {
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
    fontSize: "42px !important",
    marginTop: "30px !important",
  },
  left: {
    width: "47%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  right: {
    width: "47%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "50px",
    },
  },
  addressDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
  },
  address: {
    display: "flex",
    marginBottom: "20px",
  },
  add: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    color: "#FDFDFD !important",
    fontWeight: "400 !important",
  },
  logodiv: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
  },
  Flogo: {
    marginRight: "10px",
    cursor: "pointer",
  },
  firstrow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginBottom: "0px",
    },
  },
  field: {
    width: "100% !important",
    margin: "0px 20px !important",
    [theme.breakpoints.down("md")]: {
      margin: "0px 0px !important",
      marginBottom: "40px !important",
    },
  },
  field2: {
    width: "100% !important",
    margin: "0px 20px !important",
    [theme.breakpoints.down("md")]: {
      margin: "0px 0px !important",
      marginBottom: "40px !important",
    },
  },
  btndiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "110px",
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: '10px'
    }
  },
  btn: {
    backgroundColor: "#159346 !important",
    color: "white !important",
    textTransform: "none !important",
    fontFamily: "Poppins !important",
    fontSize: "20px !important",
    color: "#FDFDFD !important",
    fontWeight: "400 !important",
    borderRadius: "50px !important",
    padding: "10px 30px !important",
  },
  disclaimer: {
    fontFamily: "Poppins !important",
    fontSize: "20px !important",
    color: "#179446 !important",
    fontWeight: "400 !important",
  },
  dessubdiv: {
    width: "65%",
    textAlign: "center",
  },
  desdiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
    marginTop: "80px",
    padding: "40px 0px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: "#FFF",
  },

}));
const 
ContactUs = () => {
  const classes = useStyles();
  const [age, setAge] = React.useState("");

  const emailParams = useParams();
  const [click, setClick] = useState(false);
  const theme = createTheme();

  const form = useRef();
  const myHelper = {
    email: {
      required: "Email is Required",
      pattern: "Invalid Email Address",
    },
    name: {
      required: "Name is Required",
    },
    mobile: {
      required: "Mobile Number is Required",
      pattern:"Enter Valid Mobile Number"
    },
    country: {
      required: "Timezone is Required",
    },
    industry: {
      required: "Industry is Required",
    },
    requirement: {
      required: "Glimpse is Required",
    },
  };
  const { control, handleSubmit,getValues } = useForm();
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  const header = {
    'Accept': "application/json, text/plain, */*",
    'Content-Type': "application/json"
}
  const handleOnSubmit = (data) => {
    console.log("data",data)
    axios.post('https://adeptd.com/adeptd/api.php',

    {
      fullname:data.fullname,
      email:data.email,
      mobile:data.mobile,
      timezone:data.timezone,
      desc:data.desc,
      services: solution,
        agree_status : 1
    }, { header: header })
    .then(function (response) {
        Swal.fire({
            icon: 'success',
            text: response?.data?.message,
          })
          setClick(true)
          setValues({
            fullname:"",
            email:"",
            mobile:"",
            timezone:"",
            desc:"", 
          })
          setSolution([])
    })
    .catch(function (error) {
        Swal.fire({
            icon: 'error',
            title:"Oops!",
            text: error?.message,
          }) 
    });
    //setClick(true)
    // emailjs
    //   .sendForm(
    //     "service_mhmvm2c",
    //     "template_3wpjs96",
    //     form.current,
    //     "hPxRqQ2wfW6qn4TDh"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.status);
    //       if (result.status === 200) {
    //         setClick(true);
    //         clearValues();

    //       }
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );

  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const item = [
    " Web Design and Development",
    "Performance Marketing - Social Media & Search Ads",
    "Search Engine Optimization (SEO)",
    "Youtube SEO, Email Marketing",
    "LinkedIn Marketing, CRM Setup & Maintenance",
    "Designing",
    " Digital Growth Consulting",
    " Other help!"
]

  const initialSelected = ["Search Engine Optimization (SEO)"];
  const [solution, setSolution] = useState([]);
  const handleChange = (e) => {
    setSolution(e.target.value);
  };
  const [values, setValues] = useState({
    fullname: "",
    email: "",
    mobile: "",
    timezone: "",
    desc: ""
  });

  const handleDelete = (e, value) => {
    e.preventDefault();
    setSolution((current) => _without(current, value));
  };

  const handleTextChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });

    // const regex = /^[0-9\b]+$/;
    // if (values.mobile || regex.test(values.mobile)) {
    //   setValues(values.mobile);
    // }

  };
  const clearValues = () => {
    setValues({
      name: "",
      email: "",
      mobile: "",
      timezone: "",
      desc: "",
      solution:[]
    });
  };

  console.log("values",values)
  // useEffect(() => {

  //     setClick(true);

  // });
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const textAnimate = {
    offscreen: { x: -200, opacity: 0 },
    onscreen: {
      y: 0,
      scale: 1,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const textAnimate2 = {
    offscreen: { y: 50, opacity: 0 },
    onscreen: {
      y: 0,
      scale: 1,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const openInNewTab = (url) => {
    // 👇️ setting target to _blank with window.open
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <div className="main">
        <div className={classes.subdiv}>
          <div>
            <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
              {visible ? (
                <Typography className={classes.title}>
                  <TypeAnimation
                    repeat={Infinity}
                    sequence={["Contact Us", 1000]}
                    wrapper="div"
                    cursor={false}
                  />
                </Typography>
              ) : (
                <></>
              )}
            </ScrollTrigger>
          </div>
          <div className={classes.bottom}>
            <div className={classes.left}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <Typography className={classes.title1}>
                    We are glad you are here!
                  </Typography>
                </motion.p>
              </motion.div>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={textAnimate}>
                  <Typography className={classes.title2}>
                    Let's achieve excellence together.
                  </Typography>
                </motion.p>
              </motion.div>
              <div className={classes.addressDiv}>
                <motion.div
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: false, amount: 0.5 }}
                  transition={{ staggerChildren: 0.5 }}
                >
                  <motion.p variants={textAnimate}>
                    <div className={classes.address}>
                      <LocationOnRoundedIcon
                        style={{
                          width: "22px",
                          marginRight: "10px",
                          color: "#FDFDFD",
                          marginTop: "5px",
                        }}
                      />
                      <img
                        src={india}
                        style={{
                          width: "25px",
                          height: "15px",
                          marginTop: "10px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography className={classes.add}>
                        <span style={{ fontWeight: "700" }}>India :</span> G-20,
                        Emerald Tower,
                        <br /> Udaipur, Rajasthan, 313001
                      </Typography>
                    </div>
                  </motion.p>
                </motion.div>
                <motion.div
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: false, amount: 0.5 }}
                  transition={{ staggerChildren: 0.5 }}
                >
                  <motion.p variants={textAnimate}>
                    <div className={classes.address}>
                      <LocationOnRoundedIcon
                        style={{
                          width: "22px",
                          marginRight: "10px",
                          color: "#FDFDFD",
                          marginTop: "5px",
                        }}
                      />
                      <img
                        src={us}
                        style={{
                          width: "25px",
                          height: "15px",
                          marginTop: "10px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography className={classes.add}>
                        <span style={{ fontWeight: "700" }}>U.S :</span> 5325
                        Yorktown Blvd.
                        <br /> Corpus Christi, Texas 78413
                      </Typography>
                    </div>
                  </motion.p>
                </motion.div>
                <motion.div
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: false, amount: 0.5 }}
                  transition={{ staggerChildren: 0.5 }}
                >
                  <motion.p variants={textAnimate}>
                    <div className={classes.address}>
                      <EmailRoundedIcon
                        style={{
                          width: "22px",
                          marginRight: "10px",
                          color: "#FDFDFD",
                        }}
                      />
                      <Typography className={classes.add}>
                        <span style={{ fontWeight: "700" }}>
                          hello@adeptd.com
                        </span>
                      </Typography>
                    </div>
                  </motion.p>
                </motion.div>
                <motion.div
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: false, amount: 0.5 }}
                  transition={{ staggerChildren: 0.5 }}
                >
                  <motion.p variants={textAnimate}>
                    <div className={classes.logodiv}>
                      <img
                        src={Flogo4}
                        className={classes.Flogo}
                        alt="linkedin"
                        onClick={() =>
                          openInNewTab(
                            "https://www.linkedin.com/company/adeptd/"
                          )
                        }
                      />
                      <img
                        src={Flogo2}
                        className={classes.Flogo}
                        alt="insta"
                        onClick={() =>
                          openInNewTab(
                            "https://instagram.com/adeptd_media?igshid=YmMyMTA2M2Y="
                          )
                        }
                      />
                      <img
                        src={Flogo1}
                        className={classes.Flogo}
                        onClick={() =>
                          openInNewTab("https://www.facebook.com/adeptdus")
                        }
                        alt="fb"
                      />
                      <img
                        src={Flogo3}
                        className={classes.Flogo}
                        alt="clutch"
                        onClick={() =>
                          openInNewTab("https://clutch.co/profile/adeptd")
                        }
                      />
                    </div>
                  </motion.p>
                </motion.div>
              </div>
            </div>

            {!click ? (
              <form
                onSubmit={handleSubmit(handleOnSubmit)}
                className={classes.right}
              >
                <div>
                  <div className={classes.firstrow}>
                    <Controller
                      control={control}
                      value={values.fullname}
                      name="fullname"
                      onChange={handleTextChange}
                      rules={{
                        required: true,
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          label="Full Name"
                          variant="standard"
                          
                          error={error !== undefined}
                          helperText={error ? myHelper.name[error.type] : ""}
                          className={classes.field}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="email"
                      value={values.email}
                      onChange={handleTextChange}
                      defaultValue={emailParams.email}
                      rules={{
                        required: true,
                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          label="Email"
                      
                          variant="standard"
                          error={error !== undefined}
                          helperText={error ? myHelper.email[error.type] : ""}
                          className={classes.field}
                        />
                      )}
                    />
                  </div>
                  <div className={classes.firstrow}>
                    <Controller
                      control={control}
                      name="mobile"
                     
                      rules={{
                        required: true,
                        pattern:  /^[0-9\b]+$/,
                         
                      }}
                      value={values.mobile}
                      onChange={handleTextChange}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          label="Mobile Number"
                          {...field}
                          type="tel"
                          
                      
                          id="standard-basic"
                          variant="standard"
                          error={error !== undefined}
                          helperText={error ? myHelper.mobile[error.type] : ""}
                          className={classes.field}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="timezone"
                      value={values.timezone}
                      onChange={handleTextChange}
                     label="Timexone"
                      rules={{
                        required: true,
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <div style={{display:'inline-block',width:'100%'}}>
                          <InputLabel
                        id="demo-mutiple-chip-checkbox-label"
                        style={{
                          color: "white",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                        }}
                      >Select Timezone
                      </InputLabel>
                        <Select
                          label="Timezone"
                          {...field}
                          defaultValue="timezone"
                          displayEmpty
                          id="standard-basic"
                          variant="standard"
                          error={error !== undefined}
                          inputProps={{className:classes.field}}
                          
                          className={classes.field}
                        >
                          <MenuItem disabled value="" style={{color:'red'}}>
                                <em>Select Timezone</em>
                            </MenuItem>
                            <MenuItem value={"PST"}>PST</MenuItem>
                            <MenuItem value={"EST"}>EST</MenuItem>
                            <MenuItem value={"MST"}>MST</MenuItem>
                            <MenuItem value={"CST"}>CST</MenuItem>
                        </Select>
                        <FormHelperText style={{color:'#d32f2f',marginTop:'10px'}}>{error ? myHelper.country[error.type] : ""}</FormHelperText>
                        </div>
                      )}
                    />
                    
                  </div>
                  <div className={classes.firstrow}>
                    
                    <div className={classes.field}>
                      <InputLabel
                        id="demo-mutiple-chip-checkbox-label"
                        style={{
                          color: "white",
                          fontFamily: "Poppins",
                          fontWeight: "500",
                        }}
                      >Services Are You Looking For?
                      </InputLabel>
                      <Select
                        style={{ width: "100%" }}
                        id="standard-basic"
                        variant="standard"
                        multiple
                        name="services"
                        value={solution}
                        onChange={handleChange}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip
                                key={value}
                                label={value.substring(0, 2)}
                                clickable
                                sx={{
                                  "& .MuiChip-deleteIcon": {
                                    color: "#179446",
                                  },
                                }}
                                deleteIcon={
                                  <CancelIcon
                                    onMouseDown={(event) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                                classes={{ label: classes.chipLabel }}
                                className={classes.chip}
                                onDelete={(e) => handleDelete(e, value)}
                              />
                            ))}
                          </div>
                        )}
                      >
                        {item.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={solution.includes(name)} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className={classes.firstrow}>
                    <Controller
                      control={control}
                      name="desc"
                      onChange={handleTextChange}
                      defaultValue=""
                      rules={{
                        required: true,
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          id="standard-basic"
                          label="Description"
                          variant="standard"
                          className={classes.field2}
                          error={error !== undefined}
                          helperText={
                            error ? myHelper.requirement[error.type] : ""
                          }
                        />
                      )}
                    />
                  </div>
                  <div className={classes.btndiv}>
                    <LoadingButton
                      className={classes.btn}
                      loading={click}
                      type="submit"
                    >
                      Get In Touch
                    </LoadingButton>
                  </div>
                </div>
              </form>
            ) : (
              <ThankYou />
            )}
          </div>
        </div>
      </div>
      <div className={classes.desdiv}>
        <div className={classes.dessubdiv}>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
          >
            <motion.p variants={textAnimate2}>
              <Typography className={classes.disclaimer}>
                <span style={{ fontWeight: "700" }}>Disclaimer :</span> By
                completing our contact form, you confirm that you are aware and
                agree that we will be communicating with you by phone, email or
                electronically to offer and provide our products and services in
                respect of Adeptd needs. You must rest assured that your
                information is secure and safe with us.
              </Typography>
            </motion.p>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
