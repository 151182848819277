import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import { Typography } from "@mui/material";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "50px",
  },
  mainTitle: {
    fontSize: "20px !important",
  },
  Title: {
    fontFamily: "Poppins !important",
    fontSize: "44px !important",
    color: "#3A3A3A !important",
    fontWeight: "700 !important",
    textAlign: "center !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px!important",
      textAlign: "center",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px!important",
      textAlign: "center",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "44px!important",
      textAlign: "center",
    },
  },
  subTitle: {
    fontFamily: "Nothing you could do !important",
    fontSize: "44px !important",
    color: "#179446 !important",
    fontWeight: "700 !important",
  },
}));
const Heading = ({ firstTitle, secondTitle, thirdTitle }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const text = {
    offscreen: { scale: 0.5, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2.5 },
    },
  };
  return (
    <>
      <Container maxWidth="lg">
        <motion.div
          className={classes.main}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: false, amount: 0.5 }}
          transition={{ staggerChildren: 0.5 }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Typography className={classes.mainTitle}>{firstTitle}</Typography>
          </div>
          <div>
            <Typography className={classes.subTitle}>
              <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
                {visible ? (
                  <TypeAnimation
                    wrapper="span"
                    speed={45}
                    sequence={[secondTitle, 1000, secondTitle, 2000]}
                    cursor={false}
                    repeat={Infinity}
                  />
                ) : (
                  <Typography className={classes.subTitle}>
                    {secondTitle}
                  </Typography>
                )}
              </ScrollTrigger>
            </Typography>
          </div>

          <div>
            <motion.p variants={text}>
              <Typography className={classes.Title}>{thirdTitle}</Typography>
            </motion.p>
          </div>
        </motion.div>
      </Container>
    </>
  );
};

export default Heading;
