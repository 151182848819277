import React, { useState } from "react";
import CarosuleItem from "./CarosuleItem";
import { makeStyles } from "@mui/styles";
import { Container, Typography } from "@mui/material";
import carosule1 from "../../../images/1.png";
import carosule2 from "../../../images/2.png";
import { TypeAnimation } from "react-type-animation";
import ScrollTrigger from "react-scroll-trigger";
import CardSlider from "../../works/common/CardSlider";
import { AnimatePresence } from "framer-motion";
import EastIcon from "@mui/icons-material/East";
import { NavLink } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  greenTitle: {
    color: "#179446 !important",
    fontFamily: "Nothing You Could Do !important",
    fontWeight: "400 !important",
    fontSize: "40px !important",
    WebkitTextStroke: "1px #179446 !important",
    WebkitTextFillColor: "#179446 !important",
    lineHeight: "85px !important",
    textAlign: "center!important",
    // marginTop: "80px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "20px !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "36px !important",
    },
  },
  main: {
    width: "100%",
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  view: {
    color: "#179446 !important",
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    fontSize: "25px !important",
    textAlign: "right !important",
    transform: "translate(-20px, -65px)!important",
  },
}));
const OurWork = ({ path }) => {
  const data = [
    {
      img: carosule1,
      title: (
        <div>
          Name and details <br /> of project
        </div>
      ),
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      author: "M D Sullivan - USA",
      green: false,
    },
    {
      img: carosule2,
      title: (
        <div>
          Name and details <br /> of project
        </div>
      ),
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      author: "Joe Hopkins",
      green: true,
    },
  ];
  const classes = useStyle();
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  return (
    <div>
      <div className={classes.main}>
        <div>
          <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
            {visible ? (
              <Typography className={classes.greenTitle}>
                {" "}
                <TypeAnimation
                  wrapper="span"
                  speed={12}
                  sequence={["Our Work", 1000, "Our Work", 2000]}
                  cursor={false}
                  repeat={Infinity}
                />
              </Typography>
            ) : (
              <></>
            )}
          </ScrollTrigger>
        </div>
        {/* <div>
          <NavLink
            to={`/ourwork`}
            style={{ textDecoration: "none" }}
          >
            <Typography className={classes.view}>
              View More <EastIcon />
            </Typography>
          </NavLink>
        </div> */}
      </div>

      {/* <Carousel
        dynamicHeigh={true}
        autoPlay={true}
        interval={1000}
        infiniteLoop={true}
        verticalSwipe="true"
        axis="horizontal"
        className="carousel-work"
        showStatus={false}
        showArrows={false}
        showThumbs={false}
      >
        {data.map((data, key) => (
          <div key={key}>
            <CarosuleItem
              title={data.title}
              author={data.author}
              desc={data.desc}
              img={data.img}
              isDisable={data.green}
            />
          </div>
        ))}
      </Carousel> */}
      <CardSlider path={path} />
    </div>
  );
};

export default OurWork;
