import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import listimg from "../../../images/adept_list.svg";
import { AnimatePresence, motion } from "framer-motion";
import { Container, Typography } from "@mui/material";

const useStyle = makeStyles((theme) => ({
  mainRoot: {
    display: "flex",
    width: "100%",
    height: '700px',
    // marginTop: "80px",
    [theme.breakpoints.between(0, 600)]: {
      flexDirection: "column",
      overflowX: "hidden",
      height: 'auto',
    },
  },
  leftRoot: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    background: "rgba(58, 58, 58, 0.96)",
    opacity: 0.9,
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "10px",
    },
  },
  rightRoot: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    backgroundColor: "#252525",
    padding: "20px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "0px",
    },
  },
  title: {
    fontFamily: "Poppins !important",
    fontSize: "38px !important",
    color: "#FDFDFD !important",
    textAlign: "center !important",
    fontWeight: "600 !important",
    paddingTop: "70px !important",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      fontSize: "32px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "30px !important",
    },
  },
  rightTitle: {
    fontFamily: "Poppins !important",
    fontSize: "32px !important",
    color: "#179446 !important",
    fontWeight: "600 !important",
    paddingTop: "50px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "24px !important",
      padding: "0px 15px !important",
      textAlign: "center",
      marginRight: "20px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "28px !important",
      padding: "0px 15px !important",
      textAlign: "center",
      marginRight: "20px !important",
    },
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "18px !important",
    color: "#FFFFFF !important",
    textAlign: "center !important",
    fontWeight: "400 !important",
    paddingTop: "10px !important",

    [theme.breakpoints.only("xs")]: {
      width: "100%",
      fontSize: "12px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "12px !important",
    },
  },
  rightDesc: {
    fontFamily: "Poppins !important",
    fontSize: "18px !important",
    color: "#FFFFFF !important",
    fontWeight: "400 !important",
    paddingTop: "10px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "12px !important",
      padding: "0px 25px !important",
      textAlign: "center",
      marginRight: "20px !important",
      marginTop: "10px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "12px!important",
      padding: "0px 25px !important",
      textAlign: "center",
      marginRight: "20px !important",
      marginTop: "10px !important",
    },
  },
  tabTitle: {
    fontFamily: "Poppins !important",
    fontSize: "18px !important",
    color: "#FDFDFD !important",
    textAlign: "justify",
    wordWrap: "break-word !important",
    width: "100%",
    display: "flex",
    paddingRight: "30px",
    marginLeft: "20px !important",
    whiteSpace: "break-spaces !important",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      textAlign: "justify !important",
      oveflow: "hidden",
      fontSize: "14px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "12px !important",
    },
  },
  tabRoot: {
    background: "#179446",
    width: "80%",
    padding: "10px",
    marginTop: "30px",
    display: "flex",
    alignItems: "left",
    justifyContent: "left",
    oveflow: "hidden",
    borderRadius: "5px",

    [theme.breakpoints.only("xs")]: {
      width: "100%",
      oveflowX: "hidden",
      marginBottom: "10px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "90%",
    },
  },
  topTab: {
    marginTop: '80px',
    [theme.breakpoints.only("xs")]: {
      marginTop: '00px'
    }
  },
  topTabAbout: {
    marginTop: '60px'
  },
  tabRootDisable: {
    background: "transprent",
    width: "80%",
    padding: "10px 10px",
    marginTop: "30px",
    display: "flex",

    alignItems: "center",
    oveflow: "hidden",
    justifyContent: "left",
    borderRadius: "5px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "90%",
    },
  },
  contentRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  rightContentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.only("xs")]: {
      padding: "10px",
    },
  },
  rootList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginTop: "5px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "0px 35px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      padding: "0px 35px",
    },
  },
  listTitle: {
    fontFamily: "Poppins !important",
    fontSize: "14px !important",
    color: "#FFFFFF !important",
    marginLeft: "10px !important",
    wordWrap: "break-word !important",
    [theme.breakpoints.only("xs")]: {
      paddingRight: "50px !important",
      marginRight: "20px !important",
    },
    [theme.breakpoints.only("sm")]: {
      paddingRight: "50px !important",
      marginRight: "20px !important",
      fontSize: "12px !important",
    },
  },
  iconRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "100px",
    position: 'abosulte',


  },
  icon: {
    width: "70px",
    height: 'auto',
    margin: "10px",

    objectFit: 'contain',
    position: 'relative',
    bottom: 0,
    [theme.breakpoints.only("xs")]: {
      width: "50px",
    },
  },
  iconOther: {
    width: "40px",
    height: 'auto',
    margin: "10px",
    objectFit: 'contain',
    position: 'relative',
    bottom: 0,
    [theme.breakpoints.only("xs")]: {
      width: "50px",
    },
  },
  offerRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
  },
  offerRightContent: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    backgroundColor: "#252525",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "10px",
    },
  },
  offerLeftContent: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    overflowX: "hidden",
    backgroundColor: "rgba(58, 58, 58, 0.96)",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "10px",
    },
  },
  offerHeadingContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));
const Offer = ({ heading, desc, tabData, rightData, path }) => {
  const classes = useStyle();
  const [activeId, setActiveId] = useState(1);
  return (
    <div className={classes.mainRoot}>
      <div className={classes.leftRoot}>
        <div className={classes.contentRoot}>
          <Typography className={classes.title}>{heading}</Typography>
          <Typography className={classes.desc}>{desc}</Typography>
        </div>

        <div className={path === "about" ? classes.topTabAbout : classes.topTab}>
          {tabData.map((item, index) => (
            <div
              className={
                item.id === activeId ? classes.tabRoot : classes.tabRootDisable
              }
              onClick={() => setActiveId(item.id)}
              key={item.id}
            >
              <Typography className={classes.tabTitle}>{item.name}</Typography>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.rightRoot}>
        <div className={classes.rightContentRoot}>
          {rightData.map((item, index) =>
            item.id === activeId ? (
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  key={index}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <Typography className={classes.rightTitle}>
                    {item.title}
                  </Typography>
                  <Typography className={classes.rightDesc}>
                    {item.desc}
                  </Typography>
                  <div style={{ marginTop: "20px" }}>
                    {item.list.map((list, index) => (
                      <div className={classes.rootList} key={index}>
                        {
                          list.icon === false ?
                            ""
                            :
                            <img src={listimg} alt="listicon" width={10} />
                        }
                        <Typography className={classes.listTitle}>
                          {list.title}
                        </Typography>
                      </div>
                    ))}
                  </div>
                  <div className={classes.iconRoot}>
                    {item.iconList.map((icon, index) => (
                      <img
                        src={icon.icon}
                        alt="icon"
                        className={icon.wid ? classes.iconOther : classes.icon}

                      />
                    ))}
                  </div>
                </motion.div>
              </AnimatePresence>
            ) : (
              ""
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Offer;
