import { Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles'
import React, { useEffect, useRef, useState } from 'react'
import nikki from '../../images/ourwork/nikki_andrews.png'
import best_tax_pro from '../../images/ourwork/best_tax_pro.png'
import resolve from '../../images/ourwork/resolve.png'
import md_sullivan from '../../images/ourwork/md_sullivan.png'
import tide_water from '../../images/ourwork/tide_water.png'
import cta from '../../images/ourwork/cta.png'
import halls_irs from '../../images/ourwork/halls_irs.png'
import profit from '../../images/ourwork/profit.png'
import fair_winds from '../../images/ourwork/fair_winds.png'
import estate from '../../images/ourwork/estate.png'
import taxcure from '../../images/ourwork/tax_cure.png'
import tax_resolution from '../../images/ourwork/tax_resolution.png'
import moi from '../../images/ourwork/moi.png'
import ddw from '../../images/ourwork/ddw.png'
import pack from '../../images/ourwork/pack.png'
import tautology from '../../images/ourwork/tautology.png'
import aloha from '../../images/ourwork/aloha.png'
import rentory from '../../images/ourwork/rentory.png'
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import ImageItem from './ImageItem';
import { motion } from 'framer-motion'


const useStyles = makeStyles((theme) => ({
    mainRoot: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '80vh'
    },
    leftRoot: {
        width: '40%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    rightRoot: {
        width: '50%',
        display: 'flex',

        justifyContent: 'center',
        flexDirection: 'column',
    },
    rightContent: {
        overflow: 'overlay',
        overflowX: 'hidden'
    },
    title: {
        color: "#179446 !important",
        fontFamily: 'Nothing You Could Do !important',
        fontSize: '50px !important',
        fontWeight: '400 !important',
        textAlign: 'left !important'

    },
    desc: {
        color: "#3A3A3A !important",
        fontFamily: 'Poppins !important',
        fontSize: '24px !important',
        fontWeight: '400 !important',
        textAlign: 'left !important'
    },
    bannerImg: {
        width: '100%'
    },
    parallax: {
        width: '60% !important', position: 'relative !important',
        overflow: 'overlay !important',
        height: '80vh !important',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
    },

}))
const Work = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const classes = useStyles();
    const nikkiRef = useRef(1);
    const taxProRef = useRef(2);
    const [show, setShow] = useState(1)
    const imageData = [
        {
            img: nikki,
            title: "Nikki",
            desc: "Tax Professional service"
        },
        {
            img: best_tax_pro,
            title: "best_tax_pro"
        },
        {
            img: resolve,
            title: "resolve"
        },
        {
            img: md_sullivan,
            title: "md_sullivan"
        },
        {
            img: tide_water,
            title: "tide_water"
        },

        {
            img: cta,
            title: "cta",
        },
        {
            img: halls_irs,
            title: "Halls_irs"
        },

        {
            img: profit,
            title: "profit"
        },
        {
            img: fair_winds,
            title: "Fair Winds"
        }, {
            img: estate,
            title: "Estate"
        },
        {
            img: taxcure,
            title: "taxcure"
        },
        {
            img: tax_resolution,
            title: "Tax Resolution"
        },

        {
            img: moi,
            title: "MOI"
        },
        {
            img: ddw,
            title: "DDW"
        },
        {
            img: pack,
            title: "PACK"
        },
        {
            img: tautology,
            title: "tautology"
        },
        {
            img: aloha,
            title: "aloha"
        },
        {
            img: rentory,
            title: "rentory"
        }
    ]


    return (
        <Container maxWidth="lg" >
            {/* {
                textData.map((item, i) => (
                    <motion.div className={classes.box} initial={{ width: "0vw", x: "50vw" }}
                        animate={{ width: "300px", x: 0 }}
                        transition={{ duration: 1, origin: 1 }}>
                        <p>{item}</p>
                    </motion.div>
                ))
            } */}
            <div className={classes.mainRoot}>
                <div className={classes.leftRoot}>
                    <div style={{ width: '80%' }}>
                        <Typography className={classes.title}>Our Works</Typography>
                        <Typography className={classes.desc}>Lorem Ipsum has been the industry's Lorem Ipsum has been the industry's</Typography>
                    </div>
                </div>
                <Parallax pages={5.5} className={classes.parallax}>
                    <div className={classes.rightRoot}>
                        <div className={classes.rightContent}>
                            <ParallaxLayer offset={0} speed={1.5} >
                                {
                                    imageData.map((item, i) => (

                                        <ImageItem key={i} img={item.img} setShow={setShow} index={i} title={item.title} desc={item.desc} />

                                    ))
                                }
                            </ParallaxLayer>
                        </div>
                    </div>
                </Parallax>
            </div>
        </Container>
    )
}

export default Work