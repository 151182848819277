import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  createTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import bg from "../../../images/workBG.svg";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "50px",

  },
  child: {
    width: "92%",
    display: "flex",
    [theme.breakpoints.only("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "97%",
    },
  },
  left: {
    width: "50%",
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  right: {
    width: "50%",
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: "200px",
    },
  },
  logos: {
    width: '150px', position: 'absolute', right: '0'
  },
  greenHeading: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "48px !important",
    fontWeight: "700!important",
    color: "#179446 !important",
    [theme.breakpoints.only("md")]: {
      fontSize: "38px !important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "34px !important",
    },
  },
  centerHeading: {
    fontFamily: "Poppins !important",
    fontSize: "48px !important",
    fontWeight: "700!important",
    color: "#3A3A3A !important",
    [theme.breakpoints.only("md")]: {
      fontSize: "38px !important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "34px !important",
    },
  },
  desc: {
    fontFamily: "Poppins !important",
    fontSize: "28px !important",
    fontWeight: "600!important",
    color: "#3A3A3A !important",
    marginTop: "20px !important",
    [theme.breakpoints.only("md")]: {
      fontSize: "24px !important",
    },
  },
  subMain: {
    width: "90%",
    marginLeft: "30px",
    [theme.breakpoints.only("md")]: {
      width: "97%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "90%",
      marginLeft: "10px",
    },
  },
  card: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px!important",
    // border: "1px solid !important",
    borderImageSlice: "1 !important",
    marginTop: "30px !important",
  },
  //   cardBorder: {
  //     borderImageSource:
  //       "linear-gradient(102.12deg, #61B845 1.97%, rgba(97, 184, 69, 0) 103.33%) !important",
  //     borderRadius: "12px!important",
  //   },
  cardHeading: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    fontWeight: "600!important",
    color: "#3A3A3A !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "18px !important",
    },
  },
  carddesc: {
    fontFamily: "Poppins !important",
    fontSize: "20px !important",
    fontWeight: "500!important",
    color: "#3A3A3A !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px !important",
    },
  },
  img: {
    width: "550px",
    marginTop: "80px",
    position: 'relative',
    [theme.breakpoints.only("sm")]: {
      width: "567px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "350px",
    },
    [theme.breakpoints.only("md")]: {
      width: "414px",
    },
    [theme.breakpoints.only("lg")]: {
      width: "593px",
    },
  },
  imgJohnwood: {
    width: "500px",
    marginTop: "80px",
    padding: '0px 50px',
    position: 'relative',
    [theme.breakpoints.only("sm")]: {
      width: "567px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "350px",
    },
    [theme.breakpoints.only("md")]: {
      width: "414px",
    },
    [theme.breakpoints.only("lg")]: {
      width: "593px",
    },
  },
  cardbody: {
    width: "100%",
    marginTop: "-56px",
  },
  firstcard: {
    width: "300px !important",
    height: "auto",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px!important",
    left: "0 !important",
    transform: "translate(103%, 18%) !important",
    [theme.breakpoints.only("sm")]: {
      transform: "translate(20%, 18%) !important",
    },
    [theme.breakpoints.only("md")]: {
      transform: "translate(70%, 18%) !important",
    },
    [theme.breakpoints.only("xs")]: {
      transform: "translate(20%, 40%) !important",
    },
  },
  seccard: {
    width: "300px !important",
    height: "auto",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px!important",
    left: "0 !important",
    transform: "translate(185%, -5%) !important",
    [theme.breakpoints.only("sm")]: {
      transform: "translate(82%, 9%) !important",
    },
    [theme.breakpoints.only("md")]: {
      transform: "translate(155%, 9%) !important",
    },
    [theme.breakpoints.only("xs")]: {
      transform: "translate(5%, 75%) !important",
    },
  },
  thirdcard: {
    width: "300px !important",
    height: "auto",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px!important",
    left: "0 !important",
    transform: "translate(96%, -11%) !important",
    [theme.breakpoints.only("sm")]: {
      transform: "translate(19%, 3%) !important",
    },
    [theme.breakpoints.only("md")]: {
      transform: "translate(69%, -5%) !important",
    },
    [theme.breakpoints.only("xs")]: {
      transform: "translate(20%, 75%) !important",
    },
  },
  bgimg: {
    position: "absolute",
    top: "85px",
    right: "0",
    zIndex: "-22",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
}));
const Banner = ({
  greenheading,
  centerheading,
  desc,
  cardtitle,
  carddesc,
  firstcarddesc1,
  firstcarddesc2,
  firstcarddesc3,
  firstcardtitle,
  secondcardtitle,
  secondcarddesc,
  secondcarddesc2,
  thirdcarddesc,
  thirdcardtitle,
  image,
  icon,
  path,
  logo
}) => {
  const classes = useStyles();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const textAnimate = {
    offscreen: { x: -200, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const img = {
    offscreen: { scale: 0, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const textAnimate2 = isMobile
    ? {
      offscreen: { opacity: 0 },
      onscreen: {
        y: 0,
        x: 0,
        opacity: 1,
        transition: { type: "spring", bounce: 0.4, duration: 3 },
      },
    }
    : {
      offscreen: { x: 200, opacity: 0 },
      onscreen: {
        y: 0,
        x: 0,
        opacity: 1,
        transition: { type: "spring", bounce: 0.4, duration: 3 },
      },
    };
  const card = {
    offscreen: { x: -200, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const card2 = isMobile
    ? {
      offscreen: { x: -200, opacity: 0 },
      onscreen: {
        y: 0,
        x: 0,
        opacity: 1,
        transition: { type: "spring", bounce: 0.4, duration: 3 },
      },
    }
    : {
      offscreen: { x: 200, opacity: 0 },
      onscreen: {
        y: 0,
        x: 0,
        opacity: 1,
        transition: { type: "spring", bounce: 0.4, duration: 3 },
      },
    };
  return (
    <>
      <div className={classes.main}>
        <img src={bg} className={classes.bgimg} />
        <div className={classes.child}>
          <div className={classes.left}>
            <div className={classes.headingdiv}>
              <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
                {visible ? (
                  <Typography className={classes.greenHeading}>
                    <TypeAnimation
                      repeat={Infinity}
                      sequence={[`${greenheading}`, 1000]}
                      wrapper="div"
                      cursor={false}
                    />
                  </Typography>
                ) : (
                  <></>
                )}
              </ScrollTrigger>
              <div className={classes.subMain}>
                <Typography className={classes.centerHeading}>
                  <motion.div
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: false, amount: 0.5 }}
                    transition={{ staggerChildren: 0.5 }}
                  >
                    <motion.p variants={textAnimate}>{centerheading}</motion.p>
                  </motion.div>
                </Typography>
                <Typography className={classes.desc}>
                  <motion.div
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: false, amount: 0.5 }}
                    transition={{ staggerChildren: 0.5 }}
                  >
                    <motion.p variants={textAnimate2}>{desc}</motion.p>
                  </motion.div>
                </Typography>
                <motion.div
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: false, amount: 0.5 }}
                  transition={{ staggerChildren: 0.5 }}
                >
                  <motion.div variants={card}>
                    <Card className={`${classes.card} ${classes.cardBorder}`}>
                      <CardActionArea>
                        <CardContent>
                          <Typography className={classes.cardHeading}>
                            {cardtitle}
                          </Typography>
                          {/* <div style={{ marginTop: "10px", display: "flex" }}>
                            <img src={icon} />
                            <Typography className={classes.carddesc}>
                              {carddesc}
                            </Typography>
                          </div> */}
                          <ul style={{ marginTop: "10px" }}>
                            <li style={{ listStyleImage: `url(${icon})` }}>
                              <Typography className={classes.carddesc}>
                                {carddesc}
                              </Typography>
                            </li>
                          </ul>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </motion.div>
                </motion.div>
              </div>
            </div>
            <div className={classes.cardbody}>
              <div>
                <motion.div
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: false, amount: 0.5 }}
                  transition={{ staggerChildren: 0.5 }}
                >
                  <motion.div variants={card2}>
                    <Card className={classes.firstcard}>
                      <CardActionArea>
                        <CardContent>
                          <Typography className={classes.cardHeading}>
                            {firstcardtitle}
                          </Typography>
                          <ul style={{ marginTop: "10px" }}>
                            <li style={{ listStyleImage: `url(${icon})` }}>
                              <Typography className={classes.carddesc}>
                                {firstcarddesc1}
                              </Typography>
                            </li>
                            <li
                              style={
                                path === "ielts"
                                  ? { display: "none" }
                                  : { listStyleImage: `url(${icon})` }
                              }
                            >
                              <Typography className={classes.carddesc}>
                                {firstcarddesc2}
                              </Typography>
                            </li>
                            <li
                              style={
                                path === "ielts"
                                  ? { display: "none" }
                                  : { listStyleImage: `url(${icon})` }
                              }
                            >
                              <Typography className={classes.carddesc}>
                                {firstcarddesc3}
                              </Typography>
                            </li>
                          </ul>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </motion.div>
                </motion.div>
              </div>
              <div>
                <motion.div
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: false, amount: 0.5 }}
                  transition={{ staggerChildren: 0.5 }}
                >
                  <motion.div variants={card}>
                    <Card className={classes.seccard}>
                      <CardActionArea>
                        <CardContent>
                          <Typography className={classes.cardHeading}>
                            {thirdcardtitle}
                          </Typography>
                          <ul style={{ marginTop: "10px" }}>
                            <li style={{ listStyleImage: `url(${icon})` }}>
                              <Typography className={classes.carddesc}>
                                {thirdcarddesc}
                              </Typography>
                            </li>
                          </ul>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </motion.div>
                </motion.div>
              </div>
              <div>
                <motion.div
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: false, amount: 0.5 }}
                  transition={{ staggerChildren: 0.5 }}
                >
                  <motion.div variants={card2}>
                    <Card className={classes.thirdcard}>
                      <CardActionArea>
                        <CardContent>
                          <Typography className={classes.cardHeading}>
                            {secondcardtitle}
                          </Typography>
                          <ul style={{ marginTop: "10px" }}>
                            <li style={{ listStyleImage: `url(${icon})` }}>
                              <Typography className={classes.carddesc}>
                                {secondcarddesc}
                              </Typography>
                            </li>
                            <li
                              style={
                                path === "ielts"
                                  ? { display: "none" }
                                  : { listStyleImage: `url(${icon})` }
                              }
                            >
                              <Typography className={classes.carddesc}>
                                {secondcarddesc2}
                              </Typography>
                            </li>
                          </ul>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </div>
          <div className={classes.right}>
            <motion.div
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.div variants={img} >
                <div style={{ position: 'relative' }}>
                  <img src={image} className={path === "johnwood" ? classes.imgJohnwood : classes.img} />
                  <img src={logo} className={classes.logos} />
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
        {/* <div className={classes.cardbody}>
          <div>
            <Card className={classes.firstcard}>
              <CardActionArea>
                <CardContent>
                  <Typography className={classes.cardHeading}>
                    {firstcardtitle}
                  </Typography>
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ listStyleImage: `url(${icon})` }}>
                      <Typography className={classes.carddesc}>
                        {firstcarddesc1}
                      </Typography>
                    </li>
                    <li style={{ listStyleImage: `url(${icon})` }}>
                      <Typography className={classes.carddesc}>
                        {firstcarddesc2}
                      </Typography>
                    </li>
                    <li style={{ listStyleImage: `url(${icon})` }}>
                      <Typography className={classes.carddesc}>
                        {firstcarddesc3}
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div>
            <Card className={classes.seccard}>
              <CardActionArea>
                <CardContent>
                  <Typography className={classes.cardHeading}>
                    {secondcardtitle}
                  </Typography>
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ listStyleImage: `url(${icon})` }}>
                      <Typography className={classes.carddesc}>
                        {secondcarddesc}
                      </Typography>
                    </li>
                    <li style={{ listStyleImage: `url(${icon})` }}>
                      <Typography className={classes.carddesc}>
                        {secondcarddesc2}
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
          <div>
            <Card className={classes.thirdcard}>
              <CardActionArea>
                <CardContent>
                  <Typography className={classes.cardHeading}>
                    {thirdcardtitle}
                  </Typography>
                  <ul style={{ marginTop: "10px" }}>
                    <li style={{ listStyleImage: `url(${icon})` }}>
                      <Typography className={classes.carddesc}>
                        {thirdcarddesc}
                      </Typography>
                    </li>
                  </ul>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Banner;
