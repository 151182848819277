import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import img from "../../images/ctaimg.svg";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { motion, Variants } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    // background: "rgba(23, 148, 70, 0.07)",
    justifyContent: "center",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "60%",
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      width: "98%",
    },
  },
  heading: {
    fontFamily: "Poppins !important",
    fontSize: "32px !important",
    fontWeight: "500 !important",
    color: "#159346 !important",
    inlineSize: "650px !important",
    margin: "20px 0px !important",
    [theme.breakpoints.only("xs")]: {
      inlineSize: "auto !important",
    },
  },
  btn: {
    textTransform: "capitalize !important",
    backgroundColor: "#159346 !important",
    borderRadius: "50px !important",
    color: "white !important",
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    padding: "10px 50px !important",

    [theme.breakpoints.only("xs")]: {
      padding: "10px 20px !important",
      fontSize: "10px !important",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "10px 22px !important",
      fontSize: "12px !important",
    },
  },
  search: {
    border: "2px solid #159346",
    boxShadow: "inset 0px 6px 10px rgba(0, 0, 0, 0.09)",
    borderRadius: "59px",
    padding: "10px",
    width: "750px !important",
    height: "70px",
    color: "#3A3A3A !important",
    fontFamily: "Poppins !important",
    paddingLeft: "25px",
    backgroundColor: "white !important",
    margin: "20px 0px !important",
    [theme.breakpoints.only("xs")]: {
      width: "100% !important",
      height: "60px",
      fontSize: "12px !important",
    },
    [theme.breakpoints.only("sm")]: {
      width: "590px !important",
      height: "60px",
      fontSize: "12px !important",
    },
  },
}));
const LetsTalk = ({ heading }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const myHelper = {
    email: {
      required: "Email is Required",
      pattern: "Invalid Email Address",
    },
  };
  const { control, handleSubmit } = useForm();
  const handleonSubmit = () => {};

  const fieldAnimate = {
    offscreen: { y: 50, opacity: 0 },
    onscreen: {
      y: 0,
      scale: 1,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };
  const textanimate = {
    offscreen: { y: 50, opacity: 0 },
    onscreen: {
      y: 0,
      scale: 1,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 3 },
    },
  };

  return (
    <>
      <div className={classes.main}>
        {/* <div>
          <img src={img} style={{ transform: "rotate(180deg)" }} />
        </div> */}
        <div className={classes.center}>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
          >
            <motion.p variants={textanimate}>
              <Typography className={classes.heading}>{heading}</Typography>
            </motion.p>
          </motion.div>
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: false, amount: 0.5 }}
            transition={{ staggerChildren: 0.5 }}
          >
            <motion.p variants={fieldAnimate}>
              <TextField
                variant="standard"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  className: classes.search,
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment>
                      {email === "" ? (
                        <NavLink
                          type="submit"
                          style={{ textDecoration: "none" }}
                          to={`/contactus/`}
                        >
                          <Button className={classes.btn}>Lets Talk</Button>
                        </NavLink>
                      ) : (
                        <NavLink
                          type="submit"
                          style={{ textDecoration: "none" }}
                          to={`/contactus/${email}`}
                        >
                          <Button className={classes.btn}>Lets Talk</Button>
                        </NavLink>
                      )}
                    </InputAdornment>
                  ),
                }}
                placeholder="Enter You Mail Address"
                disableUnderline={true}
              />
            </motion.p>
          </motion.div>
        </div>
        {/* <div>
          <img src={img} />
        </div> */}
      </div>
    </>
  );
};

export default LetsTalk;
