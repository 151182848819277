import { ThemeProvider, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import Banner from "./common/Banner";
import Service from "./common/Service";
import Challenge from "./common/Challenge";
import Impact from "./common/Impact";
import image from "../../images/swatbannerimg.png";
import icon from "../../images/check_circle.svg";
import OurWork from "../common/work/OurWork";
import BackButton from "./common/BackButton";
import Feedback from "../home/Feedback";
import Dream from "../home/Dream";

const SwatAdvisor = () => {
  const theme = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <ThemeProvider theme={theme}>
        <BackButton />
      </ThemeProvider>
      <ThemeProvider theme={theme}>
        <Banner
          icon={icon}
          greenheading="Our Clients"
          centerheading="A California based Tax Planning and IRS Resolution Firm"
          desc="Performance Marketing"
          cardtitle="Objective"
          carddesc="Customer Calls  and Inquiry Form  Submits looking for  Tax resolution  Services"
          firstcarddesc1="Facebook"
          firstcardtitle="Marketing Channel"
          firstcarddesc2="Instagram"
          firstcarddesc3="Google Search Ads"
          secondcardtitle="Achievement"
          secondcarddesc="200+ Calls Received"
          secondcarddesc2="500+ Form submit inquiry"
          thirdcardtitle="Service Provided"
          thirdcarddesc="Paid Marketing Ads"
          image={image}
        />
        <Service path="swat" />
        <Impact maintitle="Challenges" path="swat" />
        <Challenge maintitle="Impact" path="swat" />
        <OurWork />
        <Dream />
        <Feedback />
        {/* <CardSlider /> */}
        {/* <WorkCard /> */}
      </ThemeProvider>
    </>
  );
};

export default SwatAdvisor;
