import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import menuIcon from "../images/menu_icon.svg";
import logo from "../images/logo.png";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import FreezeBanner from "./common/FreezeBanner";

const useStyle = makeStyles((theme) => ({
  logo: {
    width: "200px",
    height: "80px",
    display: "flex",
  },
  menuButton: {
    width: "100px",
    height: "80px",
    display: "flex",
  },
  appbar: {
    backgroundColor: "white !important",
    // boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15) !important",
  },
  mainDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    marginTop:'50px'
  },
  navbarmenu: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    height: "200px",
    justifyContent: "space-around",
  },
  menu: {
    width: "100%",
  },
  item: {
    fontFamily: "poppins !important",
    fontSize: "18px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
  },
}));
const Navbar = () => {
  const classes = useStyle();
  const newDate=new Date();
  const startDate="28-6-2023"
  const endDate="1-7-2023"
  const [toggle,setToggle]=useState(false)
  const todayDate=newDate.getDate()+"-"+parseInt(newDate.getMonth()+1)+"-"+newDate.getFullYear();
  console.log("today",todayDate)

  useEffect(()=>{
    if(todayDate >= startDate && todayDate <= endDate){
      setToggle(true)
    }
  },[toggle])
  //   const [open, setOpen] = useState(false);
  return (
    <>
    {<FreezeBanner/>
    }
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className={classes.appbar} position="static" elevation={0}>
          <Toolbar>
            <div className={classes.mainDiv}>
              <NavLink to={`/`}>
                <div>
                  <img className={classes.logo} src={logo} alt="icon" />
                </div>
              </NavLink>
              {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} /> */}
              <div>
                {/* <img
                  className={classes.menuButton}
                  src={menuIcon}
                  alt="icon"
                  //   onClick={() => setOpen(!open)}
                /> */}
              </div>
            </div>
          </Toolbar>
          {/* {open ? (
            <div className={`${classes.navbarmenu} menuitem`}>
              <div className={classes.menu}>
                <Typography className={classes.item}>Home</Typography>
              </div>
              <div className={classes.menu}>
                <Typography className={classes.item}>
                  CPA Law & Firms
                </Typography>
              </div>
              <div className={classes.menu}>
                <Typography className={classes.item}>Our Work</Typography>
              </div>
              <div className={classes.menu}>
                <Typography className={classes.item}>Contact Us</Typography>
              </div>
            </div>
          ) : (
            ""
          )} */}
        </AppBar>
      </Box>
    </>
  );
};

export default Navbar;
