import React from 'react';
import { makeStyles } from '@mui/styles';
import { createTheme, useMediaQuery } from '@mui/material';
import cluch from "../../images/growth/cluch.svg";
import designrush from "../../images/growth/designrush.svg";
import left_design from "../../images/growth/left_design.svg";
import right_design from "../../images/growth/right_design.svg";
import top_seo_companies from "../../images/growth/top_seo_companies.svg"; 
import Heading from "../common/Heading";

const useStyles = makeStyles((theme) => ({
  homeGrowthPartnerContainer: {
    display: "flex",
    maxWidth: 1080,
    margin: "auto",
    border: "0.1px solid #179446",
    padding: 58,
    borderRadius: 10,
    position: "relative",
    zIndex: 1,
    marginTop: 40,
    background: "#fff",
    width: "100%",
    boxShadow: "0px 2px 4px 0px #179446",
  },
  homeGrowthPartnerLogoContainer: {
    display: "flex",
    maxWidth: 850,
    alignItems: "center",
    margin: "auto",
    gap: 30,
    position: "relative",
    '&:after': {
      content: '""',
      backgroundRepeat: "no-repeat",
      height: 195,
      width: 167,
      position: "absolute",
      backgroundImage: `url(${right_design})`,
      right: 30,
      top: "50%",
      transform: "translate(195px, -50%)",
    },
    '&:before': {
      content: '""',
      backgroundRepeat: "no-repeat",
      height: 195,
      width: 167,
      position: "absolute",
      backgroundImage: `url(${left_design})`,
      left: 30,
      top: "50%",
      transform: "translate(-195px, -50%)",
    },
  },
  homeGrowthPartnerImageDivCol: {
    '&:nth-child(1) img': {
      width: 120,
    },
    '&:nth-child(2) img': {
      width: 170,
    },
    '&:nth-child(3) img': {
      width: 270,
    },
    '&:nth-child(4) img': {
      width: 100,
    },
  },
  '@media only screen and (min-width: 768px) and (max-width: 1300px)': {
    homeGrowthPartnerContainer: {
      maxWidth: 668,
      width: "100%",
      padding: 40,
    },
    homeGrowthPartnerLogoContainer: {
      gap: 40,
    },
    homeGrowthPartnerImageDivCol: {
      '&:nth-child(1) img': {
        width: 60,
      },
      '&:nth-child(2) img': {
        width: 85,
      },
      '&:nth-child(3) img': {
        width: 135,
      },
      '&:nth-child(4) img': {
        width: 50,
      },
    },
  },
  '@media only screen and (max-width: 767px)': {
    homeGrowthPartnerLogoContainer: {
      flexDirection: "column",
      gap: 30,
    },
    homeGrowthPartnerContainer: {
      padding: 20,
      width: "calc(100% - 80px)",
      maxWidth: 360,
      margin: "20px auto",
    },
    homeGrowthPartnerImageDivCol: {
      '& img': {
        maxWidth: "100%",
      },
    },
  },
}));

const Growth = () => {
  const classes = useStyles();
  // const theme = createTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  // useEffect(() => {
  //   // Your dependency-related logic here
  // }, [/* dependencies such as items, itemsMobile */]);

  return (
    <> 
    <Heading
          firstTitle=""
          secondTitle="We are"
          thirdTitle="Growth Partners!"
        />
      <div className={classes.homeGrowthPartnerContainer} style={{marginBottom:'80px'}}>
        <div className={classes.homeGrowthPartnerLogoContainer}>
          <div className={classes.homeGrowthPartnerImageDivCol}>
            <a href="https://www.designrush.com/agency/search-engine-optimization/in" rel="noreferrer noopener" target="_blank">
              <img src={top_seo_companies} alt="Adeptd on designrush top seo" />
            </a>
          </div>
          <div className={classes.homeGrowthPartnerImageDivCol}>
            <a href="https://www.designrush.com/agency/profile/adeptd" rel="noreferrer noopener" target="_blank">
              <img src={designrush} alt="Adeptd on designrush" />
            </a>
          </div>
          <div className={classes.homeGrowthPartnerImageDivCol}>
            <a href="https://clutch.co/profile/adeptd" rel="noreferrer noopener" target="_blank">
              <img src={cluch} alt="Adeptd on clutch" />
            </a>
          </div>
          <div className={classes.homeGrowthPartnerImageDivCol}>
            <a href="https://www.semrush.com/agencies/adeptd/" rel="noreferrer noopener" target="_blank">
              <img src="https://static.semrush.com/agency-directory/shared/badge.svg" alt="Adeptd on SEMrush" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Growth;
