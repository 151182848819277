import React, { useEffect } from "react";
import Banner from "../components/home/Banner";
import Clients from "../components/common/Clients.js";
import OurWork from "../components/common/work/OurWork";
import Testimonial from "../components/common/testimonial/Testimonial";
import Offer from "../components/common/offer/Offer";
import Sectors from "../components/home/Sectors";
import WeGrow from "../components/home/WeGrow";
import Feedback from "../components/home/Feedback";
import SlickSlider from "../components/home/SlickSlider";
import Growth from "../components/home/Growth";
import ContactUs from "../components/common/ContactUs";
import OurUSP from "../components/about/OurUSP";
import Dream from "../components/home/Dream";
import Process from "../components/common/process/Process";
import { ThemeProvider, useTheme } from "@mui/material";
import { rightData, tabData } from "../data/Offer";
import Demo from "../components/works/common/Demo";
import TopButton from "../components/common/TopButton";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  return (
    <>
      <ThemeProvider theme={theme}>
        <Banner />
        { /*<OurWork  />*/}
        <Testimonial />
        <Clients />
        <Offer
          heading="Our Offerings"
          desc="A Diaspora of Digital Solutions Your brand is seeking"
          rightData={rightData}
          tabData={tabData}
        />
        {/* <Demo /> */}
        <WeGrow />
        <Growth />
        <Process />
        <Sectors />
        <Dream />
        <Feedback />
      </ThemeProvider>
    </>
  );
};

export default Home;
