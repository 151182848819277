import React, { useEffect, useRef, useState } from "react";
import Heading from "../Heading";
import videoImg from "../../../images/video_img_blur.jpg";
import { makeStyles, useTheme } from "@mui/styles";
import { Typography, useMediaQuery } from "@mui/material";
import PlayButton from "@mui/icons-material/PlayCircleFilledWhite";
import VideoModal from "./VideoModal";
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
  Variants,
} from "framer-motion";
import ReactPlayer from "react-player/lazy";

const useStyle = makeStyles((theme) => ({
  banner: {
    background:
      " linear-gradient(90.63deg, #FFFFFF 48.95%, rgba(255, 255, 255, 0.06) 77.52%)",
    backgroundImage: `url(${videoImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "right",
  },
  bannerHide: {
    background:
      " linear-gradient(90.63deg, #FFFFFF 48.95%, rgba(255, 255, 255, 0.06) 77.52%)",

    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "right",
  },
  overlay: {
    background:
      " linear-gradient(90.63deg, #FFFFFF 43.95%, rgba(255, 255, 255, 0.06) 77.52%)",
    display: "flex",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
  },
  videoPlayer: {
    width: "100%",
    borderRadius: "15px",
  },
  grayBox: {
    width: "60px",
    height: "70px",
    left: "0",
    bottom: "40px",
    background: "#D9D9D9",
    marginRight: "10px",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mainContentDiv: {
    display: "flex",
    flexDirection: "column",
    padding: "100px",
    marginLeft: "70px",
    [theme.breakpoints.only("sm")]: {
      marginLeft: "0px",
    },
  },
  desc: {
    display: "flex",
    flexGrow: 1,
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
    color: "#3A3A3A !important",
    inlineSize: "400px",
    textAlign: "center",
    [theme.breakpoints.only("sm")]: {
      inlineSize: "300px",
      fontSize: "12px !important",
      flexGrow: 0,
    },
  },
  footerTextFirst: {
    fontFamily: "Poppins !important",
    fontWeight: "600 !important",
    color: "#179446 !important",
    marginTop: "20px !important",
    [theme.breakpoints.only("sm")]: {
      fontSize: "12px !important",
    },
  },
  footerTextSecond: {
    fontFamily: "Poppins !important",
    fontWeight: "700 !important",
    color: "#3A3A3A !important",
    marginBottom: "20px !important",
    [theme.breakpoints.only("sm")]: {
      fontSize: "12px !important",
    },
  },
  leftContent: {
    display: "flex",
    position: "relative",
    width: "50%",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  rightContent: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  rightContentFull: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },

  bannerMobile: {
    background:
      " linear-gradient(90.63deg, #FFFFFF 48.95%, rgba(255, 255, 255, 0.06) 77.52%)",
    display: "flex",
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    flexDirection: "column",
    backgroundPosition: "center",
  },
  overlayMobile: {
    background:
      " linear-gradient(90.63deg, #FFFFFF 43.95%, rgba(255, 255, 255, 0.06) 77.52%)",
    display: "flex",
    width: "100%",
    [theme.breakpoints.only("sm")]: {
      flexDirection: "column",
    },
  },
  mainContentDivMobile: {
    display: "flex",
    flexDirection: "column",
  },
  descMobile: {
    display: "flex",
    flexGrow: 1,
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
    color: "#3A3A3A !important",
    textAlign: "center",
    marginTop: "20px !important",
  },
  footerTextFirstMobile: {
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    color: "#179446 !important",
    marginTop: "50px !important",
  },
  footerTextSecondMobile: {
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    color: "#3A3A3A !important",
    marginBottom: "20px !important",
  },
  leftContentMobile: {
    display: "flex",
    position: "relative",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  rightContentMobile: {
    width: "100%",
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    height: "50vh",
    backgroundPosition: "center",
    backgroundImage: `url(${videoImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  playButton: {
    width: "100%",
    height: "100%",
    top: "50%",
    left: "50%",
    position: "absolute",
    display: "flex",
  },
  mainContainer: {
    overflow: "hidden",
    marginBottom: "1rem",
    marginTop: "5.5rem",
    [theme.breakpoints.only("xs")]: {
      marginBottom: "2rem",
      marginTop: "2rem",
    },
  },
}));
const Testimonial = ({ path }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const classes = useStyle();
  const openModal = () => {
    setIsOpen(true);
  };
  const variants = {
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  };
  const setFullVideo = () => {
    setShow(true);
    setPlaying(true);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between(0, 600));
  const text2 = {
    offscreen: { x: -50, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2.5 },
    },
  };
  const banner = {
    offscreen: { opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2.5 },
    },
  };
  const [show, setShow] = useState(false);
  const [playing, setPlaying] = useState(false);
  const handlePlay = () => {
    setPlaying(true);
  };

  return (
    <motion.div
      className={classes.mainContainer}
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: false, amount: 0.5 }}
      transition={{ staggerChildren: 0.5 }}
    >
      {/* <motion.p variants={text}> */}
      <Heading firstTitle="" secondTitle="Testimonial" thirdTitle="" />

      {/* </motion.p> */}
      {!isMobile ? (
        <motion.p variants={banner}>
          <div className={!show ? classes.banner : classes.bannerHide}>
            <div className={classes.overlay}>
              {
                <div className={classes.leftContent}>
                  <div className={classes.grayBox} />
                  <motion.p variants={text2}>
                    <div className={classes.mainContentDiv}>
                      <div style={{ marginBottom: "30px" }}>
                        <script
                          type="text/javascript"
                          src="https://widget.clutch.co/static/js/widget.js"
                        ></script>
                        <div
                          class="clutch-widget"
                          data-url="https://widget.clutch.co"
                          data-widget-type="14"
                          data-height="50"
                          data-nofollow="true"
                          data-expandifr="true"
                          data-scale="100"
                          data-clutchcompany-id="1902127"
                        ></div>
                      </div>
                      <Typography className={classes.desc}>
                        This is for "Shabbir" and his company "Adeptd" rocks! I
                        had a great experience working with a team who has done
                        a great job restructuring my online marketing and making
                        my efforts visible to the public. They are a bunch of
                        people who are passionate about what they do and they're
                        well-versed in the digital marketing space. I enjoyed
                        working with them. I am impressed with their knowledge
                        and work ethic.
                      </Typography>
                      <div style={{ position: "absolute", bottom: 0 }}>
                        <Typography className={classes.footerTextFirst}>
                          M D Sullivan
                        </Typography>
                        <Typography className={classes.footerTextSecond}>
                          - Former IRS Agent, Fox and ABC News Contributor,
                          <br />
                          Founder - Sullivan 4 IRS Matters
                        </Typography>
                      </div>
                    </div>
                  </motion.p>
                </div>
              }
              <div
                className={
                  !show ? classes.rightContent : classes.rightContentFull
                }
              >
                {!show ? (
                  <PlayButton
                    color="success"
                    fontSize="large"
                    playing="true"
                    onClick={setFullVideo}
                  />
                ) : (
                  <motion.div
                    className="video-player"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 6, type: "spring" }}
                  >
                    <ReactPlayer
                      playing={playing}
                      onPlay={handlePlay}
                      url="https://www.youtube.com/watch?v=GJKe8Etk9Kw&ab_channel=HelpFromAFormerIRSAgent"
                      width="100%"
                      height="100%"
                    />
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </motion.p>
      ) : (
        <div className={!show ? classes.bannerMobile : classes.bannerHide}>
          <div className={classes.leftContentMobile}>
            <div className={classes.mainContentDivMobile}>
              <Typography className={classes.descMobile}>
                This is for "Shabbir" and his company "Adeptd" rocks! I had a
                great experience working with a team who has done a great job
                restructuring my online marketing and making my efforts visible
                to the public. They are a bunch of people who are passionate
                about what they do and they're well-versed in the digital
                marketing space. I enjoyed working with them. I am impressed
                with their knowledge and work ethic.
              </Typography>
              <div style={{ textAlign: "center" }}>
                <Typography className={classes.footerTextFirstMobile}>
                  M D Sullivan -{" "}
                </Typography>
                <Typography className={classes.footerTextSecondMobile}>
                  Former IRS Agent, Fox and ABC News Contributor,
                  <br /> Founder - Sullivan 4 IRS Matters
                </Typography>
              </div>
            </div>
          </div>
          <div
            className={
              !show
                ? classes.rightContentMobile
                : classes.rightContentFullMobile
            }
          >
            {!show ? (
              <PlayButton
                color="success"
                fontSize="large"
                playing="true"
                onClick={setFullVideo}
              />
            ) : (
              <motion.div
                className="video-player"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 6, type: "spring" }}
              >
                <ReactPlayer
                  playing={playing}
                  onPlay={handlePlay}
                  url="https://www.youtube.com/watch?v=GJKe8Etk9Kw&ab_channel=HelpFromAFormerIRSAgent"
                  width="100%"
                  height="100%"
                />
              </motion.div>
            )}
          </div>
        </div>
      )}
      {/* <VideoModal
        open={isOpen}
        id="GJKe8Etk9Kw"
        close={() => setIsOpen(false)}
        isMobile={isMobile}
      /> */}
    </motion.div>
  );
};

export default Testimonial;

{
  /* !show ?
                    <PlayButton
                      color="success"
                      fontSize="large"
                      onClick={setFullVideo}
                    /> :
                    <motion.div
                      variants={variants}
                      initial="hidden"
                      animate="visible"
                      data-isOpen={show}
                      style={{ width: '100%' }}>
                      <ReactPlayer playIcon={<button>Hello</button>} url="https://www.youtube.com/watch?v=GJKe8Etk9Kw&ab_channel=HelpFromAFormerIRSAgent" width="100%" />
                    </motion.div> */
}
