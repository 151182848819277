import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
  mainRoot: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    padding: "10px",

    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.only("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "row",
      width: "100%",
    },
  },
  leftContent: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
      marginRight: "auto",
    },
  },
  rightContent: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    marginLeft: "10px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
      marginLeft: "auto",
    },
  },

  greenTitleHeading: {
    color: "#3A3A3A !important",
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    [theme.breakpoints.only("sm")]: {
      fontSize: "18px !important",
      textAlign: "center !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "18px !important",
    },
  },
  mainTitle: {
    color: "#3A3A3A !important",
    fontFamily: "Poppins !important",
    fontWeight: "700 !important",
    fontSize: "52px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "36px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "36px !important",
      textAlign: "center !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "36px !important",
    },
  },
  subHeading: {
    color: "#3A3A3A !important",
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    fontSize: "20px !important",
    textAlign: "left !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "18px !important",
      marginTop: "10px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "18px !important",
      textAlign: "center !important",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "20px !important",
    },
  },
  button: {
    background: "#159346",
    color: "#ffffff",
    fontFamily: "Poppins!important",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    marginTop: "10px",
    borderRadius: "50px",
    width: "180px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "14px !important",
      width: "150px",
      height: "30px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "18px !important",
    },
  },
  disButton: {
    background: "#D9D9D9",
    color: "#3A3A3A",
    fontFamily: "Poppins!important",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    marginTop: "10px",
    borderRadius: "50px",
    width: "180px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "14px !important",
      width: "150px",
      height: "30px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "18px !important",
    },
  },
  img: {
    width: "100%",
    [theme.breakpoints.only("sm")]: {
      width: "50% !important",
    },
    [theme.breakpoints.only("md")]: {
      width: "100% !important",
    },
  },
}));

const CarosuleItem = ({ author, title, desc, isDisable, img }) => {
  const classes = useStyle();
  return (
    <div>
      <Container maxWidth="lg">
        <div className={classes.mainRoot}>
          <div className={classes.leftContent}>
            <Typography className={classes.greenTitleHeading}>
              {author}
            </Typography>
            <Typography className={classes.mainTitle}>{title}</Typography>
            <Typography className={classes.subHeading}>{desc}</Typography>
            {isDisable ? (
              <div className={classes.disButton}>View Case Study</div>
            ) : (
              <div className={classes.button}>View Case Study</div>
            )}
          </div>
          <div className={classes.rightContent}>
            <img src={img} alt="tab" className={classes.img} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CarosuleItem;
