import { Button, Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  btn: {
    position: "fixed !important",
    bottom: "50px !important",
    right: "40px !important",
    textTransform: "none !important",
    backgroundColor: "#179446 !important",
    color: "white !important",
  },
}));
const BackButton = () => {
  const classes = useStyles();
  return (
    <>
      <NavLink to={`/`}>
        <Fab className={`${classes.btn} backBtn`}>
          <ArrowBackRoundedIcon />
        </Fab>
      </NavLink>
    </>
  );
};

export default BackButton;
