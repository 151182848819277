import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Checkbox,
  Container,
  createTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FormSlider from "../common/FormSlider";
import ScrollTrigger from "react-scroll-trigger";
import { TypeAnimation } from "react-type-animation";
import { motion, Variants } from "framer-motion";
import LetsTalk from "../whyus/LetsTalk";

const useStyle = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "80px",
  },
  titleBody: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  greenTitleBody: {
    width: "58%",
    [theme.breakpoints.only("sm")]: {
      width: "98%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  centerTitleBody: {
    width: "100%",
    textAlign: "center",
    marginTop: "-60px",
    [theme.breakpoints.only("xs")]: {
      marginTop: "-30px",
    },
  },
  centerTitle: {
    fontFamily: "Poppins !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    [theme.breakpoints.only("md")]: {
      fontSize: "50px!important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "44px!important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "32px!important",
    },
  },
  greenTitle: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    [theme.breakpoints.only("md")]: {
      fontSize: "44px!important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "40px!important",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "34px!important",
    },
  },
  formDiv: {
    width: "90%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "40px",
    [theme.breakpoints.only("sm")]: {
      width: "90%",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.only("md")]: {
      width: "70%",
      flexDirection: "column",
    },
    [theme.breakpoints.only("xs")]: {
      // width: "70%",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  div: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  check: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  checktitle: {
    fontFamily: "Poppins !important",
    fontSize: "22px !important",
    fontWeight: "400 !important",
    color: "#3a3a3a !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "16px!important",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "20px!important",
    },
  },
  bottomform: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "40px",
  },
  headingdivbottom: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bottomheading: {
    fontFamily: "Poppins!important",
    fontSize: "24px!important",
    fontWeight: "700!important",
  },
  bottomdesc: {
    fontFamily: "Poppins!important",
    fontSize: "16px!important",
    fontWeight: "400!important",
  },
  sliderdiv: {
    marginTop: "40px",
  },
  btndiv: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "40px",
  },
  btn: {
    fontFamily: "Poppins!important",
    fontSize: "20px!important",
    fontWeight: "400!important",
    color: "#3a3a3a!important",
    textTransform: "none !important",
    backgroundColor: "#D9D9D9 !important",
    borderRadius: "100px !important",
    padding: "8px 30px !important",
  },
  aeroBody: {
    display: "flex",
    marginTop: "-35px",
    marginBottom: "0px",
    width: "33%",
    [theme.breakpoints.only("xs")]: {
      marginTop: "-10px",
      marginLeft: "20px",
      width: "0",
    },
  },
  firstAero: {
    fontFamily: "Nothing You Could Do !important",
    fontSize: "54px !important",
    fontWeight: "700 !important",
    color: "#179446 !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: "34px !important",
    },
  },
}));

const Dream = () => {
  const classes = useStyle();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [visible, setVisible] = useState(false);
  const visibleEnter = () => {
    setVisible(true);
  };
  const visibleExit = () => {
    setVisible(false);
  };
  const text = {
    offscreen: { scale: 0.5, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2 },
    },
  };
  const check1 = {
    offscreen: { x: -50, opacity: 0 },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, duration: 2 },
    },
  };
  const check2 = isMobile
    ? {
        offscreen: { x: -50, opacity: 0 },
        onscreen: {
          y: 0,
          x: 0,
          opacity: 1,
          transition: { type: "spring", bounce: 0.4, duration: 2 },
        },
      }
    : {
        offscreen: { x: 50, opacity: 0 },
        onscreen: {
          y: 0,
          x: 0,
          opacity: 1,
          transition: { type: "spring", bounce: 0.4, duration: 2 },
        },
      };
  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.main}>
          <div className={classes.titleBody}>
            <div className={classes.greenTitleBody}>
              <ScrollTrigger onEnter={visibleEnter} onExit={visibleExit}>
                {visible ? (
                  <Typography className={classes.greenTitle}>
                    <TypeAnimation
                      wrapper="span"
                      speed={12}
                      sequence={["first step", 1000, "first step", 2000]}
                      cursor={false}
                      repeat={Infinity}
                    />
                  </Typography>
                ) : (
                  <></>
                )}
              </ScrollTrigger>
            </div>
            <div className={classes.aeroBody}>
              <Typography className={`${classes.firstAero} floatingstraight`}>
                ^
              </Typography>
            </div>
            <div className={classes.centerTitleBody}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={text}>
                  <Typography className={classes.centerTitle}>
                    Take your &nbsp; towards excelling your business growth
                  </Typography>
                </motion.p>
              </motion.div>
            </div>
          </div>
          <LetsTalk />
          {/* <div className={classes.div}>
            <div className={classes.formDiv}>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={check1}>
                  <div className={classes.leftcheck}>
                    <div className={classes.check}>
                      <Checkbox />
                      <Typography className={classes.checktitle}>
                        Search Engine Optimization
                      </Typography>
                    </div>
                    <div className={classes.check}>
                      <Checkbox />
                      <Typography className={classes.checktitle}>
                        Web & Mobile Development
                      </Typography>
                    </div>
                    <div className={classes.check}>
                      <Checkbox />
                      <Typography className={classes.checktitle}>
                        Branding and Creative Designing
                      </Typography>
                    </div>
                    <div className={classes.check}>
                      <Checkbox />
                      <Typography className={classes.checktitle}>
                        Others
                      </Typography>
                    </div>
                  </div>
                </motion.p>
              </motion.div>
              <motion.div
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: false, amount: 0.5 }}
                transition={{ staggerChildren: 0.5 }}
              >
                <motion.p variants={check2}>
                  <div className={classes.leftcheck}>
                    <div className={classes.check}>
                      <Checkbox />
                      <Typography className={classes.checktitle}>
                        Performance Marketing - Digital Advertising
                      </Typography>
                    </div>
                    <div className={classes.check}>
                      <Checkbox />
                      <Typography className={classes.checktitle}>
                        NFT Marketplace or Web 3.0 Development
                      </Typography>
                    </div>
                    <div className={classes.check}>
                      <Checkbox />
                      <Typography className={classes.checktitle}>
                        Omni-Channel Digital Services
                      </Typography>
                    </div>
                  </div>
                </motion.p>
              </motion.div>
            </div>
          </div> */}
          {/* <div className={classes.bottomform}>
            <div className={classes.headingdivbottom}>
              <Typography className={classes.bottomheading}>
                How big the company*
              </Typography>
              <Typography className={classes.bottomdesc}>
                (select employee lenth)
              </Typography>
            </div>
          </div>
          <div className={classes.sliderdiv}>
            <FormSlider />
          </div>
          <div className={classes.btndiv}>
            <Button className={classes.btn}>Next</Button>
          </div> */}
        </div>
      </Container>
    </>
  );
};

export default Dream;
